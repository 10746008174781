import * as React from 'react'

import { Grid, InputAdornment, Tooltip } from '@mui/material'
import { InfoOutlined as InfoOutlinedIcon } from '@mui/icons-material'
import { Field } from 'formik'
import { TextField, CheckboxWithLabel } from 'formik-mui'

import { IngressClass, IngressClassParameters } from 'lib/types/Ingress'
import DescriptionSelect from 'components/DescriptionSelect'

const defaultParams = {
  target: '',
  id: '',
  fmt: '',
  revision: '',
  configtype: '',
  mode: 'dial'
}

interface C37IngressParamsProps {
  formDisabled: boolean
  params?: IngressClassParameters<IngressClass.c37>
  onParamsChange: (newParams: IngressClassParameters<IngressClass.c37>) => void
}
/**
 * This page is the extra parameters specific to c37 ingresses.
 * They include:
 *  - target: Target IP address
 *  - id: C37 ID
 *  - debug: ?
 *  - fmt: Whether to use default or PCD format
 *  - revision: User-provided revision number
 *  - configtype: Which C37 config type to request
 *  - mode: C37 mode
 */
const C37IngressParams = ({ formDisabled, params, onParamsChange }: C37IngressParamsProps) => {
  const onConfigtypeEdit = (event) => onParamsChange({ ...params, configtype: event.target.value })
  const onFmtEdit = (event) => onParamsChange({ ...params, fmt: event.target.value })
  const onModeEdit = (event) =>
    onParamsChange({ ...params, mode: event.target.checked ? 'dial,tls' : 'dial' })

  return (
    <Grid container columnSpacing={2}>
      <Grid item xs={12} md={6}>
        <Field
          required
          component={TextField}
          label="Target"
          name="parameters.target"
          role="textbox"
          margin="dense"
          disabled={formDisabled}
          type="text"
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title="The target IP address" placement="left">
                  <InfoOutlinedIcon />
                </Tooltip>
              </InputAdornment>
            )
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field
          required
          component={TextField}
          label="ID"
          name="parameters.id"
          role="textbox"
          margin="dense"
          disabled={formDisabled}
          type="text"
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip
                  title="Unique device ID associated with each sensor or PDC."
                  placement="left">
                  <InfoOutlinedIcon />
                </Tooltip>
              </InputAdornment>
            )
          }}
        />
      </Grid>

      <Grid item xs={12} md={6} marginTop={2}>
        <DescriptionSelect
          value={params?.fmt ?? ''}
          label="Format"
          name="parameters.fmt"
          id="fmt"
          disabled={formDisabled}
          SelectProps={{
            id: 'fmt',
            displayEmpty: true
          }}
          inputProps={{
            'data-testid': 'fmt'
          }}
          InputLabelProps={{ shrink: true }}
          onChange={onFmtEdit}
          defaultLabel="Default"
          entries={[
            {
              title: 'Default',
              value: ''
            },
            {
              title: 'PDC',
              value: 'PDC'
            }
          ]}
        />
      </Grid>
      <Grid item xs={12} md={6} marginTop={2}>
        <Field
          component={TextField}
          label="Revision"
          name="parameters.revision"
          role="textbox"
          margin="dense"
          disabled={formDisabled}
          type="text"
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title="The stream identification revision" placement="left">
                  <InfoOutlinedIcon />
                </Tooltip>
              </InputAdornment>
            )
          }}
        />
      </Grid>

      <Grid item xs={12} md={6} marginTop={2}>
        <DescriptionSelect
          value={params?.configtype ?? ''}
          label="Configuration type"
          name="parameters.configtype"
          id="configtype"
          disabled={formDisabled}
          SelectProps={{
            id: 'configtype'
          }}
          inputProps={{
            'data-testid': 'configtype'
          }}
          onChange={onConfigtypeEdit}
          entries={[
            {
              title: <>&nbsp;</>,
              value: ''
            },
            {
              title: '1',
              value: 1
            },
            {
              title: '2',
              value: 2
            }
          ]}
        />
      </Grid>

      <Grid item xs={12} marginTop={2}>
        <Field
          component={CheckboxWithLabel}
          type={'checkbox'}
          name="parameters.mode"
          checked={params?.mode === 'dial,tls'}
          onChange={onModeEdit}
          Label={{ label: 'Enable TLS' }}
        />
      </Grid>
    </Grid>
  )
}

export default C37IngressParams
export { defaultParams }
