import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Button, Card, CardActions, CardHeader, Grid, useMediaQuery, useTheme } from '@mui/material'

const NotFound: React.FC = () => {
  const { t } = useTranslation()

  const theme = useTheme()
  const fullscreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Grid container direction="column" alignItems="stretch" mx="auto" maxWidth="500px">
      <Card square={fullscreen} sx={{ mt: 2 }}>
        <CardHeader title={t('PAGES.NOT_FOUND.TITLE')} />
        <CardActions sx={{ justifyContent: 'end' }}>
          {/* `to` can take stack numbers but there are no TS defs for it */}
          <Button component={RouterLink} to="/" color="neutral">
            {t('PAGES.NOT_FOUND.BUTTON_HOME')}
          </Button>
          <Button component={RouterLink} to={-1 as any} variant="contained" color="primary">
            {t('PAGES.NOT_FOUND.BUTTON_BACK')}
          </Button>
        </CardActions>
      </Card>
    </Grid>
  )
}

export default NotFound
