import { isProductionEnvironment } from './env'

export const retrieveCookie = (key: string): string | null => {
  const cookies = document.cookie.split(';').map((c) => c.split('='))
  const cookie = cookies.find(([k, _]) => k.trim() === key)?.[1]
  return cookie && decodeURI(cookie)
}

export const storeCookie = (
  key: string,
  value: any,
  maxAge: number = 864e10,
  domain: string | undefined = undefined
) => {
  const encodedKey = encodeURI(key)
  const encodedValue = encodeURI(value ?? '')
  const secure = isProductionEnvironment() ? 'Secure;' : ''
  const domainString = domain ? `Domain=${domain};` : ''

  const cookieString = `${encodedKey}=${encodedValue}; path=/; max-age=${maxAge}; ${domainString}SameSite=Lax; ${secure}`
  document.cookie = cookieString
}

export const clearCookie = (key: string) => {
  /**
   * Some cookies may be cross-domain cookies. We have no way to see which ones are,
   * so we try and delete all levels of the cookie.
   *
   * See https://stackoverflow.com/a/2959110/2197509
   */

  const domain = window.location.host.split(':')[0].split('.')
  storeCookie(key, '', -1)
  for (let i = 1; i < domain.length; i++) {
    storeCookie(key, '', 0, `.${domain.slice(i, domain.length).join('.')}`)
  }
}
