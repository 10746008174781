import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  Chip,
  createFilterOptions,
  ListItem,
  ListItemIcon,
  TextField,
  SxProps
} from '@mui/material'
import { Check as CheckIcon } from '@mui/icons-material'

import { stringToMaterialColorSx } from '../utils/color'

interface AutocompleteMultiSelectChipInputProps {
  value: string[]
  entries: string[]
  onChange: (value: string[]) => void
  label: string
  placeholder: string
  hideValues?: string[]
  allowCustom?: boolean
  autoFocus?: boolean
  disabled?: boolean
  sx?: SxProps
}

/**
 * An MUI dropdown text input, with a list of values already provided
 * but also with the ability to (if enabled through `allowCustom`) add more
 * values.
 *
 * The selected values will be "checked" in the dropdown and show up in
 * the input as chips, with the colors set automatically.
 */
const AutocompleteMultiSelectChipInput = ({
  value,
  entries,
  onChange,
  label,
  placeholder,
  hideValues = [],
  allowCustom = false,
  autoFocus = false,
  disabled = false,
  sx = {},
  ...otherProps
}: AutocompleteMultiSelectChipInputProps) => {
  const { t } = useTranslation()

  const [customOptions, setCustomOptions] = useState<string[]>([])
  const [inputValue, setInputValue] = useState('')

  const filter = createFilterOptions<string>()

  const changeValue = (
    event: React.MouseEvent | React.KeyboardEvent,
    newValue: string[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<string>
  ) => {
    if (!allowCustom && !allOptions.includes(details?.option)) return

    if (value.includes(details?.option)) {
      // User deselected an item
      if (reason === 'selectOption') {
        // Remove selected item from value list
        newValue = newValue.filter((e) => {
          return e !== details?.option
        })
      } else if ((event as React.KeyboardEvent).key === 'Enter' && inputValue === details?.option) {
        // User pressed enter on something already selected; do nothing
        return
      }
    }
    // check to see if we need to add custom option
    if (
      allowCustom &&
      details?.option &&
      !entries.includes(details?.option) &&
      !customOptions.includes(details?.option)
    ) {
      setCustomOptions([...customOptions, details?.option])
    }

    const callbackValue = newValue ?? []
    onChange(callbackValue)
  }

  const changeInputValue = (_event, newInputValue: string) => {
    setInputValue(newInputValue)
  }

  // Define the behavior when a user clicks outside of
  // a text field (onBlur)
  const blurValue = () => {
    // if the field does not allow custom options
    if (!allowCustom) {
      // if the value is not in all options list, return
      if (!allOptions.includes(inputValue)) return
      // if in all options list but not in value list, add it
      else if (allOptions.includes(inputValue) && !value.includes(inputValue)) {
        // send the value to the list
        const callbackValue = [...value, inputValue] ?? []
        onChange(callbackValue)
      }
      // else if the field does allow custom options
    } else {
      // and the value is valid
      if (inputValue) {
        // if not already in all options list, add it
        if (!allOptions.includes(inputValue)) {
          setCustomOptions([...customOptions, inputValue])
          // and send the value to the list
          const callbackValue = [...value, inputValue] ?? []
          onChange(callbackValue)
          // if in all options list but not in the value list, add it
        } else if (allOptions.includes(inputValue) && !value.includes(inputValue)) {
          // and send the value to the list
          const callbackValue = [...value, inputValue] ?? []
          onChange(callbackValue)
        }
      }
    }

    // reset the input value to an empty string
    setInputValue('')
  }

  const allOptions = Array.from(new Set([...entries, ...customOptions]))

  return (
    <Autocomplete<string, true, false, true>
      multiple={true}
      selectOnFocus={true}
      handleHomeEndKeys={true}
      freeSolo={true}
      onChange={changeValue}
      onInputChange={changeInputValue}
      onBlur={blurValue}
      disabled={disabled}
      value={value}
      inputValue={inputValue}
      renderInput={(params) => (
        <TextField {...params} label={label} placeholder={placeholder} autoFocus={autoFocus} />
      )}
      options={allOptions}
      renderTags={(_prefixes: readonly string[], getTagProps) =>
        _prefixes
          .filter((prefix) => !hideValues.includes(prefix))
          .map((_prefix, index: number) => (
            <Chip
              {...getTagProps({ index })}
              label={_prefix || '(empty value)'}
              sx={{
                backgroundColor: stringToMaterialColorSx(_prefix),
                color: 'white',
                lineHeight: 'normal',
                '&.Mui-focusVisible': {
                  backgroundColor: '#406D82',
                  color: 'white'
                },
                '& .MuiSvgIcon-root': {
                  color: 'white',
                  opacity: 0.7
                },
                '& .MuiSvgIcon-root:hover': {
                  color: 'white',
                  opacity: 1
                },
                '&.Mui-focusVisible .MuiSvgIcon-root': {
                  color: 'white',
                  opacity: 1
                }
              }}
            />
          ))
      }
      filterOptions={(_options: string[], params) => {
        const ret = filter(_options, params).filter((e) => !hideValues.includes(e))
        if (allowCustom && inputValue && !allOptions.includes(inputValue)) {
          ret.push(inputValue)
        }
        return ret
      }}
      renderOption={(props, option) => (
        <ListItem {...props} color={value.includes(option) ? 'primary' : 'inherit'}>
          <ListItemIcon>{value.includes(option) && <CheckIcon />}</ListItemIcon>
          {allOptions.includes(option)
            ? option || '(empty)'
            : `${t('COMPONENTS.AUTOCOMPLETE_MULTI_SELECT_INPUT.ADD_NEW_VALUE')} ${option}`}
        </ListItem>
      )}
      sx={{ my: 2, ...sx }}
      componentsProps={{
        paper: {
          elevation: 6
        }
      }}
      {...otherProps}
    />
  )
}

export default AutocompleteMultiSelectChipInput
