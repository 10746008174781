import React, { useCallback, useContext } from 'react'
import { Outlet } from 'react-router-dom'

import { ApolloError, ApolloQueryResult, NetworkStatus, useQuery } from '@apollo/client'
import { Maybe } from 'graphql/jsutils/Maybe'

import { Ingress } from 'lib/types/Ingress'
import { GetAllIngressesResponse, GET_ALL_INGRESSES } from 'lib/graphQlQueries'

export interface IngressProviderApi {
  getIngress: (name: string) => Ingress | void
  getAllIngresses: () => Ingress[]
  getAllIngressesLoading: () => boolean
  getAllIngressesRefetching: () => boolean
  getAllIngressesError: () => Maybe<ApolloError>
  refetchAllIngresses: () => Promise<ApolloQueryResult<GetAllIngressesResponse>>
}

export const IngressContext = React.createContext<IngressProviderApi>({
  getIngress: (_: string) => null,
  getAllIngresses: () => [],
  getAllIngressesLoading: () => false,
  getAllIngressesRefetching: () => true,
  getAllIngressesError: () => null,
  refetchAllIngresses: () => null
})

interface IngressProviderProps {
  children?: React.ReactNode
}

export const IngressProvider: React.FC<IngressProviderProps> = ({ children }) => {
  const allIngressesResponse = useQuery<GetAllIngressesResponse>(GET_ALL_INGRESSES, {
    notifyOnNetworkStatusChange: true
  })

  const getAllIngresses = useCallback(
    () => allIngressesResponse?.data?.GetAllIngresses?.ingresses ?? [],
    [allIngressesResponse]
  )
  const getAllIngressesLoading = useCallback(
    () => allIngressesResponse.loading,
    [allIngressesResponse]
  )
  const getAllIngressesRefetching = useCallback(
    () => allIngressesResponse?.networkStatus === NetworkStatus.refetch,
    [allIngressesResponse]
  )
  const getAllIngressesError = useCallback(
    () => allIngressesResponse?.error || allIngressesResponse?.data?.GetAllIngresses?.error,
    [allIngressesResponse]
  )
  const getIngress = useCallback(
    (name: string) => getAllIngresses().filter((ingress) => ingress.name === name)?.[0],
    [getAllIngresses]
  )
  const refetchAllIngresses = useCallback(
    () => allIngressesResponse?.refetch?.(),
    [allIngressesResponse]
  )

  return (
    <IngressContext.Provider
      value={{
        getIngress,
        getAllIngresses,
        getAllIngressesLoading,
        getAllIngressesRefetching,
        getAllIngressesError,
        refetchAllIngresses
      }}>
      {children ?? <Outlet />}
    </IngressContext.Provider>
  )
}

export const useIngresses = () => useContext(IngressContext)
