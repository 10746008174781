import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { SxProps } from '@mui/system'
import { Alert, Box, Button, IconButton, Typography } from '@mui/material'

import { findError, friendlyError, isAuthError } from 'utils/error'
import ReLoginDialog from 'pages/Login/ReLoginDialog'
import { Close as CloseIcon } from '@mui/icons-material'
import { AutoCollapse } from './AutoTransition'
import { isSsoEnabled } from 'lib/env'

const Bold: React.FC<React.PropsWithChildren<{}>> = (props) => (
  <Typography {...props} fontWeight={700} component="span" />
)

export interface ErrorMessageProps {
  error?: any
  sx?: SxProps
  onRetry?: () => Promise<void>
  canClose?: boolean
}

/**
 * An outlined box that, if present, parses and displays an error message.
 */
export const ErrorMessage: React.FC<ErrorMessageProps> = ({
  error,
  sx = {},
  onRetry,
  canClose = false
}) => {
  const { t } = useTranslation()

  const [loginDialogOpen, setLoginDialogOpen] = useState(false)
  const [open, setOpen] = useState(true)

  useEffect(() => setOpen(true), [error])

  const handleReLogin = useCallback(() => {
    setLoginDialogOpen(false)
    onRetry?.()
  }, [onRetry])

  const handleReload = useCallback(() => window.location.reload(), [])

  const normalizedError = findError(error) as any

  let message: React.ReactNode

  if (typeof friendlyError(normalizedError) === 'string') {
    // error is a string
    message = (
      <Typography variant="body1" color="error" sx={sx}>
        {friendlyError(normalizedError) as string}
      </Typography>
    )
  } else if (normalizedError?.name === 'Error') {
    if (normalizedError.message || normalizedError.msg) {
      // error is a generic "Error" error and has message
      message = (
        <Typography variant="body1" color="error" sx={sx}>
          {`${t('COMPONENTS.ERROR_MESSAGE.ERROR_HAS_MESSAGE')}: ${
            normalizedError.message || normalizedError.msg
          }`}
        </Typography>
      )
    } else {
      // error is a generic "Error" error but has no useful information
      message = (
        <Typography variant="body1" color="error" sx={sx}>
          {t('COMPONENTS.ERROR_MESSAGE.ERROR_UNKNOWN')}
        </Typography>
      )
    }
  } else if (normalizedError?.message || normalizedError?.msg) {
    if (normalizedError.name) {
      // error has both `message` and `name`
      message = (
        <Typography variant="body1" color="error" sx={sx}>
          {'A '}
          <Bold>{normalizedError?.name || 'unidentified'}</Bold>
          {` error has occurred: ${normalizedError.message || normalizedError.msg}`}
        </Typography>
      )
    } else {
      // error has `message` but no `name`
      message = (
        <Typography variant="body1" color="error" sx={sx}>
          {`${t('COMPONENTS.ERROR_MESSAGE.ERROR_HAS_MESSAGE')}: ${
            normalizedError.message || normalizedError.msg
          }`}
        </Typography>
      )
    }
  } else if (normalizedError?.name) {
    // error has `name` but no `message`
    message = (
      <Typography variant="body1" color="error" sx={sx}>
        <Bold>{t('COMPONENTS.ERROR_MESSAGE.ERROR')}</Bold>
        {`: ${normalizedError.name}`}
      </Typography>
    )
  } else {
    // fallback, error has no known fields
    message = (
      <Typography variant="body1" color="error">
        {t('COMPONENTS.ERROR_MESSAGE.ERROR_UNKNOWN')}
      </Typography>
    )
  }
  return (
    <AutoCollapse>
      {error && open && (
        <Alert
          severity="error"
          action={
            canClose && (
              <IconButton onClick={() => setOpen(false)}>
                <CloseIcon />
              </IconButton>
            )
          }
          sx={{
            mb: 2,
            '& .MuiAlert-message': {
              '& .MuiTypography-root': {
                m: 0 // typography margin seems to get inherited from Alert
              },
              width: '100%'
            },
            ...sx
          }}>
          {message}
          <Box display="flex" alignItems="center">
            {isAuthError(normalizedError) &&
              (isSsoEnabled() ? (
                // Reload to force external SSO login
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ mt: 2, mx: 'auto' }}
                  onClick={handleReload}>
                  {t('COMPONENTS.ERROR_MESSAGE.BUTTON_RELOAD_PAGE')}
                </Button>
              ) : (
                // Log in with dialog
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ mt: 2, mx: 'auto' }}
                  onClick={() => setLoginDialogOpen(true)}>
                  {t('COMPONENTS.ERROR_MESSAGE.BUTTON_LOG_IN')}
                </Button>
              ))}
            {onRetry && !isAuthError(normalizedError) && (
              <Button variant="contained" color="secondary" onClick={handleReload}>
                {t('COMPONENTS.ERROR_MESSAGE.BUTTON_RETRY')}
              </Button>
            )}
          </Box>
          <ReLoginDialog open={loginDialogOpen} onLogin={handleReLogin} />
        </Alert>
      )}
    </AutoCollapse>
  )
}

export default ErrorMessage
