import React from 'react'
import * as Yup from 'yup'
import { Typography } from '@mui/material'
import { User } from 'lib/types/User'

import { IngressClass, Ingress } from 'lib/types/Ingress'
import { MESSAGES } from 'messages'

// GROUP CREATE

export const groupCreateValidation = Yup.object().shape({
  name: Yup.string()
    .required(MESSAGES.VALIDATION.GROUP.REQUIRED)
    .test(
      'valid-group-name',
      MESSAGES.VALIDATION.GROUP.INVALID,
      (name) => name && /^[A-Za-z0-9]+$/.test(name)
    ),
  allPrefixes: Yup.boolean(),
  prefixes: Yup.array().of(Yup.string()),
  capabilities: Yup.array().of(Yup.string())
})

// GROUP EDIT

export const groupEditValidation = Yup.object().shape({
  allPrefixes: Yup.boolean(),
  prefixes: Yup.array().of(Yup.string()),
  capabilities: Yup.array().of(Yup.string())
})

// INGRESS CREATE

export const createIngressValidation = function (ingresses: Ingress<IngressClass>[]) {
  return Yup.object().shape({
    name: Yup.string()
      .required(MESSAGES.VALIDATION.INGRESS.REQUIRED)
      .test(
        'valid-ingress-name',
        () => (
          <>
            Ingress names may only contain the characters
            <Typography variant="monospace">a-z</Typography>,
            <Typography variant="monospace">A-Z</Typography>,
            <Typography variant="monospace">0-9</Typography>, and
            <Typography variant="monospace">_</Typography>
          </>
        ),
        (name) => !!name && /^[A-Za-z0-9_]+$/.test(name)
      )
      .test(
        'unique-ingress-name',
        MESSAGES.VALIDATION.INGRESS.NOT_UNIQUE,
        (name) => !ingresses.find((ingress) => ingress.name === name)
      ),

    classType: Yup.string().required(MESSAGES.VALIDATION.INGRESS.CLASS_REQUIRED),
    prefix: Yup.string().required(MESSAGES.VALIDATION.INGRESS.PREFIX_REQUIRED),
    comment: Yup.string(),

    parameters: Yup.object()
      .nullable()
      // lg class
      .when('classType', {
        is: IngressClass.lg,
        then: Yup.object().shape({
          nstreams: Yup.lazy((value) => {
            if (value === '') {
              return Yup.string().required(MESSAGES.VALIDATION.INGRESS.STREAMS_REQUIRED)
            }

            return Yup.number()
              .required(MESSAGES.VALIDATION.INGRESS.STREAMS_REQUIRED)
              .integer(MESSAGES.VALIDATION.INGRESS.STREAMS_REQUIRED)
              .positive(MESSAGES.VALIDATION.INGRESS.STREAMS_REQUIRED)
          }),
          frequency: Yup.lazy((value) => {
            if (value === '') {
              return Yup.string().required(MESSAGES.VALIDATION.INGRESS.FREQUENCY_REQUIRED)
            }

            return Yup.number()
              .required(MESSAGES.VALIDATION.INGRESS.FREQUENCY_REQUIRED)
              .integer(MESSAGES.VALIDATION.INGRESS.FREQUENCY_REQUIRED)
              .positive(MESSAGES.VALIDATION.INGRESS.FREQUENCY_REQUIRED)
          }),
          revision: Yup.string(),
          randomized: Yup.boolean()
        })
      })

      // c37 class
      .when('classType', {
        is: IngressClass.c37,
        then: Yup.object().shape({
          target: Yup.string().required(MESSAGES.VALIDATION.INGRESS.TARGET_REQUIRED),
          id: Yup.string().required(MESSAGES.VALIDATION.INGRESS.ID_REQUIRED),
          fmt: Yup.string(),
          revision: Yup.string(),
          configtype: Yup.string(),
          mode: Yup.string()
        })
      })

      // sttp class
      .when('classType', {
        is: IngressClass.sttp,
        then: Yup.object().shape({
          target: Yup.string().required(MESSAGES.VALIDATION.INGRESS.TARGET_REQUIRED),
          query: Yup.string().required(MESSAGES.VALIDATION.INGRESS.QUERY_REQUIRED)
        })
      })

      // gep class
      .when('classType', {
        is: IngressClass.gep,
        then: Yup.object().shape({
          target: Yup.string().required(MESSAGES.VALIDATION.INGRESS.TARGET_REQUIRED),
          query: Yup.string().required(MESSAGES.VALIDATION.INGRESS.QUERY_REQUIRED),
          tsround: Yup.lazy((value) => {
            if (value === '') {
              return Yup.string()
            }

            return Yup.number()
              .integer(MESSAGES.VALIDATION.INGRESS.TIMESTAMP_INVALID)
              .positive(MESSAGES.VALIDATION.INGRESS.TIMESTAMP_INVALID)
          }),
          revision: Yup.string(),
          flags: Yup.string()
        })
      })
  })
}

// INGRESS EDIT

export const editIngressValidation = Yup.object().shape({
  classType: Yup.string(), // not editable in the edit form, but used for classType validation
  prefix: Yup.string().required(MESSAGES.VALIDATION.INGRESS.PREFIX_REQUIRED),
  comment: Yup.string(),

  parameters: Yup.object()
    .nullable()

    // lg class
    .when('classType', {
      is: IngressClass.lg,
      then: Yup.object().shape({
        nstreams: Yup.lazy((value) => {
          if (value === '') {
            return Yup.string().required(MESSAGES.VALIDATION.INGRESS.STREAMS_REQUIRED)
          }

          return Yup.number()
            .required(MESSAGES.VALIDATION.INGRESS.STREAMS_REQUIRED)
            .integer(MESSAGES.VALIDATION.INGRESS.STREAMS_REQUIRED)
            .positive(MESSAGES.VALIDATION.INGRESS.STREAMS_REQUIRED)
        }),
        frequency: Yup.lazy((value) => {
          if (value === '') {
            return Yup.string().required(MESSAGES.VALIDATION.INGRESS.FREQUENCY_REQUIRED)
          }

          return Yup.number()
            .required(MESSAGES.VALIDATION.INGRESS.FREQUENCY_REQUIRED)
            .integer(MESSAGES.VALIDATION.INGRESS.FREQUENCY_REQUIRED)
            .positive(MESSAGES.VALIDATION.INGRESS.FREQUENCY_REQUIRED)
        }),
        revision: Yup.string(),
        randomized: Yup.boolean()
      })
    })

    // c37 class
    .when('classType', {
      is: IngressClass.c37,
      then: Yup.object().shape({
        target: Yup.string().required(MESSAGES.VALIDATION.INGRESS.TARGET_REQUIRED),
        id: Yup.string().required(MESSAGES.VALIDATION.INGRESS.ID_REQUIRED),
        fmt: Yup.string(),
        revision: Yup.string(),
        configtype: Yup.string(),
        mode: Yup.string()
      })
    })

    // sttp class
    .when('classType', {
      is: IngressClass.sttp,
      then: Yup.object().shape({
        target: Yup.string().required(MESSAGES.VALIDATION.INGRESS.TARGET_REQUIRED),
        query: Yup.string().required(MESSAGES.VALIDATION.INGRESS.QUERY_REQUIRED)
      })
    })

    // gep class
    .when('classType', {
      is: IngressClass.gep,
      then: Yup.object().shape({
        target: Yup.string().required(MESSAGES.VALIDATION.INGRESS.TARGET_REQUIRED),
        query: Yup.string().required(MESSAGES.VALIDATION.INGRESS.QUERY_REQUIRED),
        tsround: Yup.lazy((value) => {
          if (value === '') {
            return Yup.string()
          }

          return Yup.number()
            .integer(MESSAGES.VALIDATION.INGRESS.TIMESTAMP_INVALID)
            .positive(MESSAGES.VALIDATION.INGRESS.TIMESTAMP_INVALID)
        }),
        revision: Yup.string(),
        flags: Yup.string()
      })
    })
})

// LOGIN

export const loginValidation = Yup.object().shape({
  username: Yup.string().required(MESSAGES.VALIDATION.USER.REQUIRED),
  password: Yup.string().required(MESSAGES.VALIDATION.PASSWORD.REQUIRED)
})

// PASSWORD RESET

export const resetPasswordValidation = Yup.object().shape({
  password: Yup.string()
    .required(MESSAGES.VALIDATION.PASSWORD.INVALID_REQUIRED_CHARS)
    .test(
      'password-length',
      MESSAGES.VALIDATION.PASSWORD.INVALID_LENGTH,
      (password) => password && password.length >= 12
    )
    .test(
      'required-chars',
      MESSAGES.VALIDATION.PASSWORD.INVALID_REQUIRED_CHARS,
      (password) => /[a-z]/.test(password) && /[A-Z]/.test(password) && /[0-9]/.test(password)
    )
    .test(
      'valid-chars',
      () => (
        <>
          Password may only contain characters
          <Typography variant="monospace">a-z</Typography>,{' '}
          <Typography variant="monospace">A-Z</Typography>,{' '}
          <Typography variant="monospace">0-9</Typography>, and the symbols
          <Typography variant="monospace">!@#$%^&*()</Typography>
        </>
      ),
      (password) => /^[A-Za-z0-9!@#$%^&*()]+$/.test(password)
    ),

  verifyPassword: Yup.string().when('password', {
    is: (password: string) => !!password,
    then: Yup.string()
      .required(MESSAGES.VALIDATION.VERIFY_PASSWORD.REQUIRED)
      .test(
        'passwords-match',
        MESSAGES.VALIDATION.VERIFY_PASSWORD.PASSWORDS_DONT_MATCH,
        function (verifyPassword) {
          return this.parent.password === verifyPassword
        }
      )
  })
})

// USER CREATE

export const createUserValidation = function (isSsoEnabled: boolean, users: User[]) {
  const usernameValidation = (): Yup.StringSchema => {
    if (isSsoEnabled) {
      // SSO usernames can be emails
      return Yup.string().test(
        'valid-sso-username',
        () => (
          <>
            Username may only contain the characters{' '}
            <Typography variant="monospace">a-z</Typography>,{' '}
            <Typography variant="monospace">0-9</Typography>, and the symbols{' '}
            <Typography variant="monospace">_+.</Typography>
          </>
        ),
        (username) => username && /^[a-z0-9_@.+]+$/.test(username)
      )
    } else {
      // Non-SSO usernames follow the regex in in `sgs/modules/state/acl/acl.go/ValidateUsername`
      return Yup.string().test(
        'valid-non-sso-username',
        () => (
          <>
            Username may only contain the characters
            <Typography variant="monospace">a-z</Typography>,{' '}
            <Typography variant="monospace">0-9</Typography>, and
            <Typography variant="monospace">_</Typography>
          </>
        ),
        (username) => username && /^[a-z0-9_]+$/.test(username)
      )
    }
  }

  return Yup.object().shape({
    username: Yup.string()
      .required(MESSAGES.VALIDATION.USER.REQUIRED)
      .test(
        'unique-username',
        MESSAGES.VALIDATION.USER.NOT_UNIQUE,
        (username) => !users.find((u) => u.username === username)
      )
      .concat(usernameValidation()),

    email: Yup.string()
      .required(MESSAGES.VALIDATION.EMAIL.REQUIRED)
      .email(MESSAGES.VALIDATION.EMAIL.INVALID)
      .test(
        'unique-email',
        MESSAGES.VALIDATION.EMAIL.NOT_UNIQUE,
        (email) => !!email && !users.find((u) => u.email === email)
      ),

    password: Yup.string().when('autoGeneratePassword', {
      is: (autoGeneratePassword: boolean) => autoGeneratePassword === false,
      then: Yup.string()
        .required(MESSAGES.VALIDATION.PASSWORD.INVALID_REQUIRED_CHARS)
        .test(
          'password-length',
          MESSAGES.VALIDATION.PASSWORD.INVALID_LENGTH,
          (password) => password && password.length >= 12
        )
        .test(
          'required-chars',
          MESSAGES.VALIDATION.PASSWORD.INVALID_REQUIRED_CHARS,
          (password) => /[a-z]/.test(password) && /[A-Z]/.test(password) && /[0-9]/.test(password)
        )
        .test(
          'valid-chars',
          () => (
            <>
              Password may only contain characters
              <Typography variant="monospace">a-z</Typography>,{' '}
              <Typography variant="monospace">A-Z</Typography>,{' '}
              <Typography variant="monospace">0-9</Typography>, and the symbols
              <Typography variant="monospace">!@#$%^&*()</Typography>
            </>
          ),
          (password) => /^[A-Za-z0-9!@#$%^&*()]+$/.test(password)
        )
    }),

    verifyPassword: Yup.string().when('autoGeneratePassword', {
      is: (autoGeneratePassword: boolean) => autoGeneratePassword === false,
      then: Yup.string().when('password', {
        is: (password: string) => !!password,
        then: Yup.string()
          .required(MESSAGES.VALIDATION.VERIFY_PASSWORD.REQUIRED)
          .test(
            'passwords-match',
            MESSAGES.VALIDATION.VERIFY_PASSWORD.PASSWORDS_DONT_MATCH,
            function (verifyPassword) {
              return this.parent.password === verifyPassword
            }
          )
      })
    }),

    autoGeneratePassword: Yup.boolean(),
    fullname: Yup.string(),
    comment: Yup.string(),
    userGroups: Yup.array().of(Yup.string()),
    sendNewUserWelcomeEmail: Yup.boolean(),
    emailPasswordToUser: Yup.boolean(),
    emailApiKeyToUser: Yup.boolean()
  })
}

// USER EDIT

export const editUserValidation = function (users: User[], user: User) {
  return Yup.object().shape({
    email: Yup.string()
      .required(MESSAGES.VALIDATION.EMAIL.REQUIRED)
      .email(MESSAGES.VALIDATION.EMAIL.INVALID)
      .test(
        'unique-email-except-the-current',
        MESSAGES.VALIDATION.EMAIL.NOT_UNIQUE,
        (email) => !!email && !users.find((u) => u.email === email && u.username !== user.username)
      ),
    fullname: Yup.string(),
    comment: Yup.string(),
    userGroups: Yup.array().of(Yup.string())
  })
}
