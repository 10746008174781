import React from 'react'

import { FormHelperTextProps, Grid, InputAdornment, Tooltip } from '@mui/material'
import { Field } from 'formik'
import { TextField, CheckboxWithLabel } from 'formik-mui'

import { AutoCollapse } from 'components/AutoTransition'
import { InfoOutlined as InfoOutlinedIcon } from '@mui/icons-material'

interface LgIngressParamsProps {
  formDisabled: boolean
  errors: any
}

const defaultParams = { nstreams: '', frequency: '', revision: '', randomized: false }

/**
 * This page is the extra parameters specific to lg ingresses.
 * They include:
 *  - nstreams: The number of streams for the lg ingress to generate
 *  - frequency: The frequency of data points to be generated on each stream
 *  - randomize: Whether stream IDs and UUIDs should be randomly generated or not
 */
const LgIngressParams = ({ formDisabled, errors }: LgIngressParamsProps) => {
  return (
    <Grid container columnSpacing={2}>
      <Grid item xs={12} md={6}>
        <Field
          component={TextField}
          required
          label="Number of streams"
          name="parameters.nstreams"
          role="textbox"
          margin="dense"
          disabled={formDisabled}
          type="number"
          fullWidth
          helperText={<AutoCollapse>{errors?.parameters?.nstreams}</AutoCollapse>}
          FormHelperTextProps={{ error: true, component: 'div' } as FormHelperTextProps}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field
          component={TextField}
          required
          label="Stream frequency"
          name="parameters.frequency"
          role="textbox"
          margin="dense"
          disabled={formDisabled}
          type="number"
          fullWidth
          helperText={<AutoCollapse>{errors?.parameters?.frequency}</AutoCollapse>}
          FormHelperTextProps={{ error: true, component: 'div' } as FormHelperTextProps}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title="Samples per second of data to generate." placement="left">
                  <InfoOutlinedIcon />
                </Tooltip>
              </InputAdornment>
            )
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Field
          component={TextField}
          label="Revision ID"
          name="parameters.revision"
          role="textbox"
          margin="dense"
          disabled={formDisabled}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          component={CheckboxWithLabel}
          name="parameters.randomized"
          type="checkbox"
          disabled={formDisabled}
          Label={{ label: 'Randomize stream IDs' }}
        />
      </Grid>
    </Grid>
  )
}

export default LgIngressParams
export { defaultParams }
