export enum GroupCapability {
  READ = 'READ',
  DELETE = 'DELETE',
  OBLITERATE = 'OBLITERATE',
  ADMIN = 'ADMIN',
  PLOTTER = 'PLOTTER',
  API = 'API',
  INSERT = 'INSERT'
}

export type Group = {
  name: string
  capabilities?: GroupCapability[]
  prefixes?: string[]
}
