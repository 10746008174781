import React, { useCallback, useState } from 'react'

import { Collapse, FormHelperTextProps, Grid, Link } from '@mui/material'
import { useSnackbar } from 'notistack'
import { Formik, Field } from 'formik'
import { TextField } from 'formik-mui'

import { useMutation } from '@apollo/client'
import { ADD_INGRESS, AddIngressRequest, AddIngressResponse } from 'lib/graphQlQueries'

import { useIngresses } from 'lib/providers/IngressProvider'
import { Ingress, IngressClass, IngressClassParameters } from 'lib/types/Ingress'
import { findAndRethrowError } from 'utils/error'

import { AutoCollapse } from 'components/AutoTransition'
import SubpageDialog from 'components/SubpageDialog'
import ErrorMessage from 'components/ErrorMessage'
import DescriptionSelect from 'components/DescriptionSelect'
import LgIngressParams, { defaultParams as defaultLGParams } from './IngressClasses/lg'
import C37IngressParams, { defaultParams as defaultC37Params } from './IngressClasses/c37'
import SttpIngressParams, { defaultParams as defaultSTTPParams } from './IngressClasses/sttp'
import GepIngressParams, { defaultParams as defaultGEPParams } from './IngressClasses/gep'
import { OpenInNew as OpenInNewIcon } from '@mui/icons-material'

import { createIngressValidation } from 'utils/validationSchemas'

interface IngressCreateProps {
  testMode?: boolean
}

interface FormValues {
  name: string
  classType: IngressClass | string
  prefix: string
  comment: string
  parameters: IngressClassParameters
}

const IngressCreate: React.FC<IngressCreateProps> = ({ testMode = false }) => {
  const {
    getAllIngresses,
    refetchAllIngresses,
    getAllIngressesLoading,
    getAllIngressesRefetching,
    getAllIngressesError
  } = useIngresses()
  const ingresses = getAllIngresses()
  const ingressesLoading = getAllIngressesLoading()
  const ingressesRefetching = getAllIngressesRefetching()

  const { enqueueSnackbar } = useSnackbar()

  const [isOpen, setIsOpen] = useState(true)

  const [
    addIngressMutation,
    { loading: addIngressMutationLoading, error: addIngressMutationError }
  ] = useMutation<AddIngressResponse, AddIngressRequest>(ADD_INGRESS, {
    notifyOnNetworkStatusChange: true
  })

  const [saveError, setSaveError] = useState<Error>()

  // Disable the form elements while a request is happening
  const formDisabled = addIngressMutationLoading || ingressesRefetching
  const isFormValid = useCallback(
    (values: FormValues, errors: object | null): boolean => {
      const { name, prefix, classType, parameters } = values

      return (
        !formDisabled &&
        !!name &&
        !!prefix &&
        !Object.keys(errors).length &&
        !!(
          // lg-specific validation
          (
            (classType === IngressClass.lg &&
              (parameters as IngressClassParameters<IngressClass.lg>)?.nstreams > 0 &&
              (parameters as IngressClassParameters<IngressClass.lg>)?.frequency > 0) ||
            // c37-specific validation
            (classType === IngressClass.c37 &&
              (parameters as IngressClassParameters<IngressClass.c37>)?.target &&
              (parameters as IngressClassParameters<IngressClass.c37>)?.id) ||
            // sttp-specific validation
            (classType === IngressClass.sttp &&
              (parameters as IngressClassParameters<IngressClass.sttp>)?.target &&
              (parameters as IngressClassParameters<IngressClass.sttp>)?.query) ||
            // gep-specific validation
            (classType === IngressClass.gep &&
              (parameters as IngressClassParameters<IngressClass.gep>)?.target &&
              (parameters as IngressClassParameters<IngressClass.gep>)?.query)
          )
        )
      )
    },
    [formDisabled]
  )

  const onClose = useCallback(
    (_event: React.SyntheticEvent, reason?: string, values?: FormValues, errors?: object) => {
      // Don't accidentally close form by clicking background when data has been added
      if (!(reason === 'backdropClick' && isFormValid(values, errors))) {
        // also don't close while waiting for submit response
        if (!formDisabled) {
          setIsOpen(false)
        }
      }
    },
    [formDisabled, isFormValid]
  )

  const removeEmptyParams = (parameters) => {
    const noEmptyParams = Object.entries(parameters).filter(([key, value]) =>
      value === '' ? false : true
    )
    return Object.fromEntries(noEmptyParams)
  }

  // TODO check if needs to be with callback
  const createIngress = useCallback(
    async (values: FormValues) => {
      // reset displayed error, if any
      setSaveError(null)

      const { name, prefix, classType, comment, parameters } = values

      try {
        // Create the new ingress
        const { data: addIngressMutationResponse, errors } = await addIngressMutation({
          variables: {
            name,
            class: classType,
            enabled: true,
            collectionPrefix: prefix,
            comment,
            parameters: removeEmptyParams(parameters)
          } as Ingress
        })
        findAndRethrowError(addIngressMutationResponse)
        findAndRethrowError(addIngressMutationError)

        if (errors?.[0]) {
          throw errors[0]
        }

        // update data from server
        await refetchAllIngresses()

        // No errors, close window and show success snackbar
        enqueueSnackbar(`Ingress ${name} created`, {
          variant: 'success'
        })
        setIsOpen(false)
      } catch (e) {
        setSaveError(e)
      }
    },
    [addIngressMutation, addIngressMutationError, enqueueSnackbar, refetchAllIngresses]
  )

  const error = saveError || addIngressMutationError || getAllIngressesError()

  return (
    <Formik<FormValues>
      initialValues={{
        name: '',
        classType: '',
        prefix: '',
        comment: '',
        parameters: null
      }}
      validationSchema={createIngressValidation(ingresses)}
      onSubmit={async (values) => await createIngress(values)}>
      {({ values, setFieldValue, errors, handleSubmit }) => {
        const { classType, parameters } = values
        return (
          <SubpageDialog
            title="Create new ingress"
            nextPath="/admin/ingress"
            loading={ingressesLoading}
            open={isOpen}
            onClose={(event: React.SyntheticEvent, reason?: string) => {
              onClose(event, reason, values, errors)
            }}
            actions={[
              {
                label: 'Cancel',
                onClick: (event: React.SyntheticEvent, reason?: string) => {
                  onClose(event, reason, values, errors)
                },
                color: 'inherit',
                disabled: formDisabled
              },
              {
                label: 'Create',
                onClick: () => {
                  handleSubmit()
                },
                variant: 'contained',
                loading: formDisabled || ingressesRefetching,
                disabled: !isFormValid(values, errors)
              }
            ]}>
            <Grid container columnSpacing={2}>
              <Grid item xs={12}>
                <ErrorMessage canClose error={error} />
              </Grid>
              <Grid item xs={12} md={6} marginBottom={2}>
                <Field
                  component={TextField}
                  autoFocus
                  required
                  label="Ingress name"
                  name="name"
                  role="textbox"
                  margin="dense"
                  disabled={formDisabled}
                  helperText={<AutoCollapse>{errors.name}</AutoCollapse>}
                  FormHelperTextProps={{ error: true, component: 'div' } as FormHelperTextProps}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6} marginBottom={2}>
                <DescriptionSelect
                  fullWidth
                  required
                  label="Ingress class"
                  name="classType"
                  id="ingress-class"
                  value={values.classType}
                  disabled={formDisabled}
                  SelectProps={{
                    id: 'ingress-class'
                  }}
                  inputProps={{
                    'data-testid': 'ingress-class'
                  }}
                  onChange={(event) => {
                    const classType = event.target.value

                    setFieldValue('classType', classType)

                    switch (classType) {
                      case IngressClass.lg:
                        setFieldValue('parameters', defaultLGParams)
                        break
                      case IngressClass.c37:
                        setFieldValue('parameters', defaultC37Params)
                        break
                      case IngressClass.sttp:
                        setFieldValue('parameters', defaultSTTPParams)
                        break
                      case IngressClass.gep:
                        setFieldValue('parameters', defaultGEPParams)
                        break
                    }
                  }}
                  entries={[
                    {
                      value: IngressClass.lg,
                      title: 'LG (Load Generator)',
                      description:
                        'Load generator ingress. Generates mock data points for testing purposes.'
                    },
                    {
                      value: IngressClass.c37,
                      title: 'C37',
                      description:
                        'An IEEE C37.118 Standard for Synchrophasor Measurements for Power Systems data source.'
                    },
                    {
                      value: IngressClass.sttp,
                      title: 'STTP',
                      description:
                        'An IEEE P2664 Streaming Telemetry Transport Protocol data source.'
                    },
                    {
                      value: IngressClass.gep,
                      title: 'GEP',
                      description: (
                        <>
                          The Gateway Exchange Protocol, as specified by the Grid Protection
                          Alliance. See{' '}
                          <Link
                            href="https://gridprotectionalliance.org/docs/products/gsf/gep-overview.pdf"
                            target="_blank"
                            rel="noreferrer"
                            color="primary">
                            this overview
                            <OpenInNewIcon sx={{ fontSize: 'inherit', verticalAlign: 'middle' }} />
                          </Link>{' '}
                          for more information.
                        </>
                      )
                    }
                  ]}
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  component={TextField}
                  required
                  label="Collection prefix"
                  name="prefix"
                  role="textbox"
                  margin="dense"
                  disabled={formDisabled}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} marginBottom={2}>
                <Field
                  component={TextField}
                  label="Comment"
                  name="comment"
                  role="textbox"
                  margin="dense"
                  disabled={formDisabled}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Collapse in={classType === IngressClass.lg} unmountOnExit>
                  <LgIngressParams formDisabled={formDisabled} errors={errors} />
                </Collapse>
                <Collapse in={classType === IngressClass.c37} unmountOnExit>
                  <C37IngressParams
                    formDisabled={formDisabled}
                    params={parameters as IngressClassParameters<IngressClass.c37>}
                    onParamsChange={(params) => {
                      setFieldValue('parameters', params)
                    }}
                  />
                </Collapse>
                <Collapse in={classType === IngressClass.sttp} unmountOnExit>
                  <SttpIngressParams formDisabled={formDisabled} />
                </Collapse>
                <Collapse in={classType === IngressClass.gep} unmountOnExit>
                  <GepIngressParams
                    formDisabled={formDisabled}
                    params={parameters as IngressClassParameters<IngressClass.gep>}
                    onParamsChange={(params) => {
                      setFieldValue('parameters', params)
                    }}
                    errors={errors}
                  />
                </Collapse>
              </Grid>
            </Grid>
          </SubpageDialog>
        )
      }}
    </Formik>
  )
}

export default IngressCreate
