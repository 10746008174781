import { ApolloError } from '@apollo/client'
import { ServerParseError } from '@apollo/client/link/http'
import { ServerError } from '@apollo/client/link/utils'
import { GraphQLError } from 'graphql'

/**
 * See https://github.com/PingThingsIO/smartgridstore/blob/v5/tools/btrdb-server/bte/errors.go
 * for a list of backend error codes.
 */

/**
 * A list of error message overrides to display for the given error codes.
 */
const FriendlyErrorCodeMessageMap = {
  TokenExpiredError: 'Your session has expired. Please log in again to continue.',
  JsonWebTokenError: 'Your session token is invalid. Please log in again to continue.'
}

/**
 * A list of error message overrides to display when error message substrings are found.
 */
const FriendlyErrorMessageMessageMap = {
  'invalid credentials': 'The username or password provided was incorrect. Please try again.',
  'user is missing "ADMIN" capability': 'You need administrator privileges to access this page.'
}

export const findError = (
  data?: object
): Error | ServerParseError | ServerError | GraphQLError | ApolloError | void => {
  if (!data) return
  let error = data as any
  for (const key of Object.keys(data)) {
    if (key !== 'extensions' && data[key]?.error) {
      // TODO some messages need to be JSON-parsed
      error = data[key].error
    }
  }
  if (error?.networkError?.result?.errors?.[0]?.extensions?.code) {
    return {
      code: error.networkError.result.errors[0].extensions.code,
      msg: error.networkError.result.errors[0].message
    } as unknown as Error
  } else if (error?.errors?.[0]?.extensions?.code) {
    return {
      code: error.errors[0].extensions.code,
      msg: error.errors[0].message
    } as unknown as Error
  } else if (error?.networkError) {
    return error.networkError as Error | ServerParseError | ServerError
  } else if (error?.errors?.length > 0) {
    return error.graphQLErrors[0] as GraphQLError
  } else if (error?.clientErrors?.length > 0) {
    return error.clientErrors[0] as Error
  } else if (error?.name && error?.message) {
    return error as Error
  } else if (error?.code && error?.msg) {
    // Sometimes the code gets repeated in msg like in
    // "(438: invalid ingress name (only letters, numbers, and underscores are valid))"
    // in which case we only want the message part
    if (/\(\d+: (.+)\)/.test(error.msg)) {
      return { code: error.code, msg: error.msg.match(/\(\d+: (.+)\)/)[1] } as unknown as Error
    }
    return new Error(`${error.code}: ${error?.msg}`)
  }
}

export const findAndRethrowError = (data?: object) => {
  const error = findError(data)
  if (error) {
    throw error
  }
}

export const isAuthError = (error: object): boolean => {
  if ((error as { msg: string })?.msg?.includes('jwt expired')) return true
  if ((error as { msg: string })?.msg?.includes('invalid token')) return true
  if ((error as { msg: string })?.msg?.includes('invalid signature')) return true

  return false
}

/**
 * Checks to see if the error is known and has a friendly message string.
 * Otherwise, returns the original error
 */
export const friendlyError = (
  error: Error | ServerParseError | ServerError | GraphQLError | void
): string | Error | ServerParseError | ServerError | GraphQLError | void => {
  // error code-based message overrides
  for (const key of Object.keys(FriendlyErrorCodeMessageMap)) {
    if ((error as any)?.code === key) {
      return FriendlyErrorCodeMessageMap[key]
    }
  }

  // error message substring-based message overrides
  for (const key of Object.keys(FriendlyErrorMessageMessageMap)) {
    if ((error as any)?.msg?.includes(key) || (error as any)?.message?.includes(key)) {
      return FriendlyErrorMessageMessageMap[key]
    }
  }

  return error
}
