import { ThemeOptions } from '@mui/material/styles/createTheme'
import { getContrastRatio } from '@mui/system'

type ColorScheme = {
  displayName: string
  light: {
    primary:
      | string
      | {
          /** Main color for the theme */
          main: string
          /** Alternate color used for AppBar and some other solid fills */
          alt?: string
          /** Override for colored text, usually on dark themes without enough contrast */
          text?: string
        }
  }
  dark: {
    primary:
      | string
      | {
          /** Main color for the theme */
          main: string
          /** Alternate color used for AppBar and some other solid fills */
          alt?: string
          /** Override for colored text, usually on dark themes without enough contrast */
          text?: string
        }
  }
}

/**
 * Colors that only change between light and dark modes and not the color scheme
 */
const colorSchemeBase = {
  light: {
    primary: {
      default: '#333333',
      text: '#333333'
    },
    background: {
      paper: '#FFFFFF',
      default: '#E7E8E9',
      neutral: '#DFDFDF'
    },
    neutral: '#444444',
    typography: {
      monospaceBackground: 'rgba(0 0 0 / 10%)'
    },
    scrollbar: {
      background: 'rgba(0, 0, 0, 0.2)',
      thumb: '#878787'
    },
    overrides: {
      dialog: {
        paperBorder: undefined
      }
    }
  },
  dark: {
    primary: {
      default: '#CCCCCC',
      text: '#CCCCCC'
    },
    background: {
      paper: '#161616',
      default: '#000000',
      neutral: '#202020'
    },
    neutral: '#d8d8d8',
    typography: {
      monospaceBackground: 'rgba(0 0 0 / 25%)'
    },
    scrollbar: {
      background: 'rgba(0, 0, 0, 0.3)',
      thumb: '#878787'
    },
    overrides: {
      dialog: {
        paperBorder: '1px solid rgba(255, 255, 255, 0.12)'
      }
    }
  }
}

/** Color schemes available in the dropdown */
export const colorSchemes: Record<string, ColorScheme> = {
  PingThingsBlue: {
    displayName: 'PingThings Blue',
    light: {
      primary: {
        main: '#406D81',
        text: '#1b5168'
      }
    },
    dark: {
      primary: {
        main: '#406D82',
        text: '#a3e1ff'
      }
    }
  },
  PingThingsTeal: {
    displayName: 'PingThings Teal',
    light: {
      primary: {
        main: '#41958b',
        text: '#1d6e65'
      }
    },
    dark: {
      primary: {
        main: '#289cb3',
        alt: '#1E6978',
        text: '#8ce1e5'
      }
    }
  },
  PingThingsOrange: {
    displayName: 'PingThings Orange',
    light: {
      primary: {
        main: '#CF8241',
        // alt: '#a95b19',
        text: '#d76200'
      }
    },
    dark: {
      primary: {
        main: '#ad6c35',
        alt: '#a95b19',
        text: '#ff955c'
      }
    }
  }
}

// Update TypeScript defs for custom props in theme
declare module '@mui/material/styles' {
  interface Theme {
    status: {
      neutral: React.CSSProperties['color']
    }
  }
  interface ThemeOptions {
    status?: {
      neutral: React.CSSProperties['color']
    }
  }

  interface Palette {
    neutral: Palette['primary']
    primaryText: React.CSSProperties['color']
  }
  interface PaletteOptions {
    neutral: PaletteOptions['primary']
  }

  interface TypographyVariants {
    monospace: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    monospace?: React.CSSProperties
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    monospace: true
  }
}

// Add colors to components that use them...
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true
    primaryText: true
  }
}
declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    neutral: true
    primaryText: true
  }
}

export const getContrastText = (background, contrastThreshold) =>
  getContrastRatio(background, '#FFFFFF') >= contrastThreshold ? 'white' : 'black'

export const generateTheme = (
  primaryThemeName?: string,
  accentThemeName?: string,
  darkMode = false
) => {
  const primaryScheme = colorSchemes[primaryThemeName] ?? colorSchemes.PingThings_Blue
  const accentScheme = colorSchemes[accentThemeName] ?? colorSchemes.PingThings_Blue

  const baseTheme = darkMode ? colorSchemeBase.dark : colorSchemeBase.light
  const primaryTheme = darkMode ? primaryScheme.dark : primaryScheme.light
  const accentTheme = darkMode ? accentScheme.dark : accentScheme.light

  const primaryMain = (primaryTheme.primary as { main: string })?.main || primaryTheme.primary
  const primaryAlt = (primaryTheme.primary as { alt: string })?.alt || primaryTheme.primary
  const primaryText = (primaryTheme.primary as { text: string })?.text || primaryTheme.primary
  const accentMain = (accentTheme.primary as { main: string })?.main || accentTheme.primary

  const contrastThreshold = darkMode ? 3.5 : 2.5

  return {
    palette: {
      mode: darkMode ? 'dark' : 'light',
      contrastThreshold,
      primary: {
        main: primaryMain,
        default: baseTheme.primary.default
      },
      primaryText: {
        main: primaryText
      },
      secondary: {
        main: accentMain
      },
      warning: {
        main: '#ed6c02' // always use light mode orange
      },
      background: {
        paper: baseTheme.background.paper,
        default: baseTheme.background.default,
        neutral: baseTheme.background.neutral,
        primary: primaryAlt
      },
      text: {
        // default: getContrastText(baseTheme.background.paper, contrastThreshold),
        primaryColor: primaryText, // "primary" is the black/white text instead of primary color
        // secondary: darkMode ? '#c5c5c5' : '#406D82',
        neutral: baseTheme.neutral,
        main: getContrastText(baseTheme.background.paper, contrastThreshold)
      },
      neutral: {
        main: baseTheme.neutral
      }
    },
    typography: {
      h4: {
        fontSize: '34px',
        letterSpacing: '0.25px'
      },
      h6: {
        fontSize: '20px',
        fontWeight: 500,
        lineHeight: '32px',
        letterSpacing: '0.15px'
      },
      subtitle1: {
        fontSize: '16px',
        fontWeight: 500
      },
      monospace: {
        fontFamily: '"Roboto Mono", "Consolas", "Courier New", "monospace"',
        fontWeight: 500,
        backgroundColor: baseTheme.typography.monospaceBackground,
        // color: getContrastText(baseTheme.background.paper, contrastThreshold),
        color: undefined,
        padding: '2px 4px',
        borderRadius: '6px'
      }
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            '& ::-webkit-scrollbar': {
              backgroundColor: baseTheme.scrollbar.background,
              width: '8px'
            },
            '& ::-webkit-scrollbar-thumb': {
              backgroundColor: baseTheme.scrollbar.thumb,
              borderRadius: '4px'
            }
          }
        }
      },
      MuiAppBar: {
        styleOverrides: {
          colorPrimary: {
            backgroundColor: primaryAlt
          }
        }
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            border: baseTheme.overrides.dialog.paperBorder
          }
        }
      },
      MuiCollapse: {
        styleOverrides: {
          root: {
            flexShrink: '0'
          }
        }
      },
      MuiListItemText: {
        styleOverrides: {
          root: {
            color: getContrastText(baseTheme.background.paper, contrastThreshold)
          }
        }
      },
      MuiListSubheader: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent'
          }
        }
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backdropFilter: 'blur(2px)'
          },
          invisible: {
            backdropFilter: 'none'
          }
        }
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            colorPrimary: {
              color: primaryText
            }
          }
        }
      },
      MuiLink: {
        styleOverrides: {
          root: {
            color: primaryText
          }
        }
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            '&.Mui-focused > fieldset.MuiOutlinedInput-notchedOutline': {
              borderColor: primaryText
            }
          }
        }
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            '&.Mui-focused': {
              color: primaryText
            }
          }
        }
      }
    },
    shape: {
      borderRadius: 8
    }
  } as Partial<ThemeOptions> as unknown as ThemeOptions
}
