import React, { useEffect, useState } from 'react'
import { Navigate, useLocation, useParams } from 'react-router-dom'

import { Typography, Collapse } from '@mui/material'
import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'

import { useIngresses } from 'lib/providers/IngressProvider'
import { Ingress } from 'lib/types/Ingress'
import { UPDATE_INGRESS, UpdateIngressRequest, UpdateIngressResponse } from 'lib/graphQlQueries'
import { findAndRethrowError } from 'utils/error'

import SubpageDialog from 'components/SubpageDialog'
import ErrorMessage from 'components/ErrorMessage'

const EnableDisableIngress = () => {
  const { name } = useParams()
  const { pathname } = useLocation()

  const { enqueueSnackbar } = useSnackbar()

  const [error, setError] = useState<Error>()

  const [isOpen, setIsOpen] = useState(true)

  const enablingIngress = pathname.includes('/enable/')

  const {
    getIngress,
    refetchAllIngresses,
    getAllIngressesLoading,
    getAllIngressesRefetching,
    getAllIngressesError
  } = useIngresses()

  const ingress = getIngress(name) as Ingress
  const ingressesLoading = getAllIngressesLoading()
  const ingressesError = getAllIngressesError()
  useEffect(() => setError(ingressesError), [ingressesError])

  const [UpdateIngress, { loading: UpdateIngressLoading, error: UpdateIngressError }] = useMutation<
    UpdateIngressResponse,
    UpdateIngressRequest
  >(UPDATE_INGRESS, {
    variables: {
      ...ingress,
      enabled: enablingIngress
    }
  })

  // Disable the form elements while a request is happening
  const formDisabled = ingressesLoading || UpdateIngressLoading || getAllIngressesRefetching()

  const onClose = () => {
    setIsOpen(false)
  }

  const confirmEnableDisable = async (_event) => {
    // reset displayed error, if any
    setError(null)

    try {
      const { data } = await UpdateIngress()
      findAndRethrowError(data)
      findAndRethrowError(UpdateIngressError)

      await refetchAllIngresses()

      // No errors, close window and show success snackbar
      enqueueSnackbar(`Ingress ${name} ${enablingIngress ? 'enabled' : 'disabled'}`, {
        variant: 'success'
      })
      setIsOpen(false)
    } catch (err) {
      setError(err)
    }
  }

  if (!name) {
    return <Navigate to="/admin/ingress" />
  } else
    return (
      <SubpageDialog
        title={
          <>
            {`${enablingIngress ? 'Enable' : 'Disable'} ingress `}
            <Typography variant="h6" color="text.primaryColor" component="span">
              {name}
            </Typography>
          </>
        }
        loading={ingressesLoading}
        open={isOpen}
        onClose={onClose}
        nextPath={'/admin/ingress'}
        actions={[
          {
            label: 'Cancel',
            onClick: onClose,
            color: 'inherit',
            disabled: formDisabled,
            autoFocus: true
          },
          {
            label: enablingIngress ? 'Enable' : 'Disable',
            onClick: confirmEnableDisable,
            variant: 'contained',
            color: enablingIngress ? 'primary' : 'error', // disabling is red, enabling is primary
            disabled: formDisabled,
            loading: formDisabled
          }
        ]}>
        <Collapse in={!!error}>
          <ErrorMessage error={error} />
        </Collapse>
        <Typography gutterBottom>
          <>{`Are you sure you wish to ${enablingIngress ? 'enable' : 'disable'} the ingress `}</>
          <Typography variant="body1" color="text.primaryColor" component="span">
            {name}
          </Typography>
          <>?</>
        </Typography>
        <Typography gutterBottom>
          Ingresses will not connect to sensors or store data while disabled.
        </Typography>
      </SubpageDialog>
    )
}

export default EnableDisableIngress
