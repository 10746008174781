import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'

import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { Formik, Field } from 'formik'
import { TextField } from 'formik-mui'

import { FormHelperTextProps, Grid, Typography } from '@mui/material'

import AutocompleteMultiSelectChipInput from 'components/AutocompleteMultiSelectChipInput'

import { findAndRethrowError } from 'utils/error'
import { containSameContents } from 'utils/array'
import { useUsers } from 'lib/providers/UsersProvider'
import { User } from 'lib/types/User'
import ErrorMessage from 'components/ErrorMessage'
import SubpageDialog from 'components/SubpageDialog'
import { useGroups } from 'lib/providers/GroupsProvider'
import {
  AddUserToGroupRequest,
  AddUserToGroupResponse,
  ADD_USER_TO_GROUP,
  RemoveUserFromGroupRequest,
  RemoveUserFromGroupResponse,
  REMOVE_USER_FROM_GROUP,
  UpdateUserRequest,
  UpdateUserResponse,
  UPDATE_USER
} from 'lib/graphQlQueries'
import { AutoCollapse } from 'components/AutoTransition'
import MiniColoredChipList from 'components/MiniColoredChipList'

import { editUserValidation } from 'utils/validationSchemas'

interface FormValues {
  email: string
  fullname: string
  comment: string
  userGroups: string[]
}

const UserEdit = () => {
  const { t } = useTranslation()

  const params = useParams()
  const { name } = params

  const { enqueueSnackbar } = useSnackbar()

  // Displayed error message
  const [error, setError] = useState<Error>()

  const {
    getUser,
    getAllUsers,
    refetchAllUsers,
    getUsersLoading,
    getUsersRefetching,
    getError: getUsersError
  } = useUsers()
  const {
    getAllGroups,
    getGroupsLoading,
    getError: getGroupsError,
    getEffectivePermissions
  } = useGroups()
  const usersLoading = getUsersLoading()
  const groupsLoading = getGroupsLoading()

  const users = getAllUsers() ?? []
  const groups = getAllGroups() ?? []
  const allGroupNames = groups
    ?.map((group) => group.name)
    ?.sort((a, b) => a.localeCompare(b))
    .filter((g) => g !== 'public')
  const user = getUser(name) ?? ({} as User)

  const usersError = getUsersError()
  const groupsError = getGroupsError()
  useEffect(() => setError(usersError ?? groupsError), [usersError, groupsError])

  const [userGroups, setUserGroups] =
    useState(user.groups?.map((g) => g.name)?.filter((g) => g !== 'public')) ?? []

  const [isOpen, setIsOpen] = useState(true)

  // original state of the user
  const groupsHaveChanged = !containSameContents(
    userGroups?.filter((g) => g !== 'public'),
    user.groups?.map((g) => g.name)?.filter((g) => g !== 'public')
  )

  const effectivePermissions = getEffectivePermissions(['public', ...(userGroups ?? [])])

  const [updateUser, { loading: updateUserMutationLoading, error: updateUserMutationError }] =
    useMutation<UpdateUserResponse, UpdateUserRequest>(UPDATE_USER)
  const [
    addUserToGroup,
    { loading: addUserToGroupMutationLoading, error: addUserToGroupMutationError }
  ] = useMutation<AddUserToGroupResponse, AddUserToGroupRequest>(ADD_USER_TO_GROUP)
  const [
    removeUserFromGroup,
    { loading: removeUserFromGroupMutationLoading, error: removeUserFromGroupMutationError }
  ] = useMutation<RemoveUserFromGroupResponse, RemoveUserFromGroupRequest>(REMOVE_USER_FROM_GROUP)

  // Disable the form elements while a request is happening
  const formDisabled =
    addUserToGroupMutationLoading ||
    removeUserFromGroupMutationLoading ||
    updateUserMutationLoading ||
    getUsersRefetching()

  const onClose = (_event: React.SyntheticEvent, reason?: string) => {
    if (
      reason !== 'backdropClick' ||
      // Don't accidentally close form by clicking background when the user
      // has made changes
      !(
        groupsHaveChanged ||
        // also don't close while waiting for submit response
        formDisabled
      )
    ) {
      setIsOpen(false)
    }
  }

  /**
   * Save the user.
   *
   * Sets the user prefixes and capabilities and then
   * re-fetches the list of users once those are finished.
   *
   * The form elements will be disabled while any graphQL
   * queries mutations are running.
   */
  const save = async (values: FormValues) => {
    // reset displayed error, if any
    setError(null)

    const { email, userGroups, comment, fullname } = values

    try {
      // update user fields
      if (
        fullname !== (user.fullname ?? '') ||
        email !== (user.email ?? '') ||
        comment !== (user.comment ?? '')
      ) {
        const { data, errors } = await updateUser({
          variables: {
            username: user.username,
            fullname,
            email,
            comment
          }
        })

        findAndRethrowError(updateUserMutationError)
        findAndRethrowError(data)
        findAndRethrowError(errors?.[0])
      }

      // update groups
      if (groupsHaveChanged) {
        const groupsToAdd = userGroups?.filter(
          (name) =>
            !user.groups
              ?.map((g) => g.name)
              .filter((g) => g !== 'public')
              .includes(name)
        )
        const groupsToRemove = user.groups
          ?.map((g) => g.name)
          ?.filter((g) => g !== 'public')
          .filter((name) => !userGroups.includes(name))

        for (const groupToAdd of groupsToAdd) {
          const { data, errors } = await addUserToGroup({
            variables: {
              username: user.username,
              group: groupToAdd
            }
          })

          findAndRethrowError(addUserToGroupMutationError)
          findAndRethrowError(data)
          findAndRethrowError(errors?.[0])
        }

        for (const groupToRemove of groupsToRemove) {
          const { data, errors } = await removeUserFromGroup({
            variables: {
              username: user.username,
              group: groupToRemove
            }
          })

          findAndRethrowError(removeUserFromGroupMutationError)
          findAndRethrowError(data)
          findAndRethrowError(errors?.[0])
        }
      }

      // update data from server
      await refetchAllUsers()

      // No errors, close window and show success snackbar
      enqueueSnackbar(t('PAGES.USERS.EDIT.SNACKBAR_SUCCESS', { name }), {
        variant: 'success'
      })
      setIsOpen(false)
    } catch (e) {
      setError(e)
    }
  }

  return (
    <Formik<FormValues>
      enableReinitialize
      initialValues={{
        email: user.email ?? '',
        fullname: user.fullname ?? '',
        comment: user.comment ?? '',
        userGroups: user?.groups?.map((g) => g.name)?.filter((g) => g !== 'public') ?? []
      }}
      validationSchema={editUserValidation(users, user)}
      onSubmit={async (values) => await save(values)}>
      {({ values, setFieldValue, errors, handleSubmit }) => {
        const { email, comment, fullname, userGroups } = values

        return (
          <SubpageDialog
            title={
              <Trans i18nKey="PAGES.USERS.EDIT.TITLE" values={{ name }}>
                ''
                <Typography variant="h6" color="text.primaryColor" component="span">
                  ''
                </Typography>
              </Trans>
            }
            nextPath="/admin/users"
            open={isOpen}
            loading={usersLoading || groupsLoading}
            onClose={onClose}
            actions={[
              {
                label: t('PAGES.USERS.CREATE.BUTTON_CANCEL'),
                onClick: onClose,
                disabled: formDisabled,
                color: 'inherit'
              },
              {
                label: t('PAGES.USERS.EDIT.BUTTON_SAVE'),
                onClick: () => {
                  handleSubmit()
                },
                variant: 'contained',
                disabled:
                  usersLoading ||
                  formDisabled ||
                  email === '' ||
                  !!errors.email ||
                  (!groupsHaveChanged &&
                    fullname === user.fullname &&
                    email === user.email &&
                    comment === user.comment),
                loading: formDisabled
              }
            ]}>
            <AutoCollapse>
              {!usersLoading && !groupsLoading && (
                <Grid container columnSpacing={2}>
                  <Grid item xs={12}>
                    <ErrorMessage canClose error={error} />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Field
                      component={TextField}
                      label={t('PAGES.USERS.CREATE.FIELD_FULLNAME_LABEL')}
                      name="fullname"
                      role="textbox"
                      margin="dense"
                      disabled={formDisabled}
                      sx={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      component={TextField}
                      required
                      label={t('PAGES.USERS.CREATE.FIELD_EMAIL_LABEL')}
                      name="email"
                      role="textbox"
                      autoComplete="email"
                      margin="dense"
                      disabled={formDisabled}
                      helperText={<AutoCollapse>{errors.email}</AutoCollapse>}
                      FormHelperTextProps={{ error: true, component: 'div' } as FormHelperTextProps}
                      sx={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label={t('PAGES.USERS.CREATE.FIELD_COMMENT_LABEL')}
                      name="comment"
                      role="textbox"
                      autoComplete="comment"
                      margin="dense"
                      disabled={formDisabled}
                      sx={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <AutocompleteMultiSelectChipInput
                      value={userGroups}
                      onChange={(groups) => {
                        setFieldValue('userGroups', groups)
                        setUserGroups(groups)
                      }}
                      entries={allGroupNames}
                      label={t('PAGES.USERS.CREATE.FIELD_GROUPS_LABEL')}
                      placeholder={t('PAGES.USERS.CREATE.FIELD_GROUPS_PLACEHOLDER')}
                      disabled={formDisabled}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="caption">
                      {t('PAGES.USERS.CREATE.EFFECTIVE_PERMISSIONS_LABEL')}
                    </Typography>
                    <MiniColoredChipList items={effectivePermissions} sx={{ mx: 1, mb: 1 }} />
                  </Grid>
                </Grid>
              )}
            </AutoCollapse>
          </SubpageDialog>
        )
      }}
    </Formik>
  )
}

export default UserEdit
