import React from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'

import { ListItemButton, ListItemIcon, ListItemText, SvgIcon } from '@mui/material'

interface NavButtonProps {
  label: string
  path: string
  /** Optional regex to test selection. If omitted, checks exact match on path */
  match?: RegExp
  icon: typeof SvgIcon
  subnav?: boolean
  disabled?: boolean
}

const NavButton: React.FC<NavButtonProps> = ({
  label,
  path,
  match,
  icon: Icon,
  disabled = false
}) => {
  const { pathname } = useLocation()
  const currentPath = match ? match.test(pathname) : path === pathname

  return (
    <ListItemButton
      selected={currentPath}
      key={label}
      component={RouterLink}
      to={path}
      disabled={disabled}
      sx={(theme) => ({
        flex: 0,
        borderLeft: `4px solid ${currentPath ? theme.palette.primary.main : 'transparent'}`,
        '& .MuiListItemIcon-root': {
          minWidth: '42px'
        }
      })}>
      <ListItemIcon>
        <Icon color={currentPath ? 'primary' : 'inherit'} />
      </ListItemIcon>
      <ListItemText color={currentPath ? 'primary' : 'inherit'} primary={label} />
    </ListItemButton>
  )
}

export default NavButton
