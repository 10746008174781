import { useEffect } from 'react'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom'

import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

import { getFrontendGitCommit, getFrontendVersion, getSentryDsn } from './env'

/**
 * Set up sentry using `REACT_APP_SENTRY_DSN` env variable.
 *
 * See https://docs.sentry.io/platforms/javascript/guides/react/
 */
export function setupSentry() {
  const sentryDsn = getSentryDsn()
  // Try getting version from env
  const frontendReleaseVersion = getFrontendGitCommit() ?? getFrontendVersion()

  Sentry.init({
    dsn: sentryDsn as string,
    release: frontendReleaseVersion as string,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        )
      })
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  })
}
