import React, { useMemo } from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'

import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Add as AddIcon,
  Person as PersonIcon
} from '@mui/icons-material'
import { Fab, Grid, Grow, Paper, Typography, useMediaQuery, useTheme } from '@mui/material'

import MiniColoredChipList from 'components/MiniColoredChipList'
import FilterableList, { SortMenuEntry } from 'components/FilterableList'
import AvatarListItemEntry from 'components/AvatarListItemEntry'

import { type User } from 'lib/types/User'
import { useUsers } from 'lib/providers/UsersProvider'
import { isSsoEnabled } from 'lib/env'

const userFilters: Record<string, (item: User, filter: string) => boolean> = {
  name: (user: User, s: string) =>
    user?.username?.toLocaleLowerCase?.().includes(s.toLocaleLowerCase()),
  fullname: (user: User, s: string) =>
    user?.fullname?.toLocaleLowerCase?.().includes(s.toLocaleLowerCase()),
  email: (user: User, s: string) =>
    user?.email?.toLocaleLowerCase().includes(s.toLocaleLowerCase()),
  comment: (user: User, s: string) =>
    user?.comment?.toLocaleLowerCase?.().includes(s.toLocaleLowerCase()),
  group: (user: User, s: string) =>
    !!user?.groups?.find((group) =>
      group?.name?.toLocaleLowerCase?.().includes(s.toLocaleLowerCase())
    )
}

/**
 * Users page.
 *
 * Displays a list of users as returned by `GetAllUserObjects`.
 *
 * @constructor
 */
const UsersList = () => {
  const { t } = useTranslation()

  const { pathname } = useLocation()

  const { getAllUsers, getError, getUsersLoading, getUsersRefetching } = useUsers()

  // Users list returned from GraphQL call
  const users = getAllUsers() ?? []
  // Any GraphQL errors
  const error = getError()
  const loading = getUsersLoading()
  const refetching = getUsersRefetching()

  const theme = useTheme()
  const fullscreen = useMediaQuery(theme.breakpoints.down('xs'))

  const sorts: SortMenuEntry<User>[] = useMemo(
    () => [
      {
        label: t('PAGES.USERS.LIST.SORT_NAME'),
        sort: (a: User, b: User) => a.username?.localeCompare(b.username)
      }
    ],
    [t]
  )

  return (
    <>
      <Grid
        container
        direction="column"
        alignItems="stretch"
        sx={{
          mx: 'auto',
          maxWidth: '725px',
          height: '100%',
          width: '100%'
        }}>
        <Paper
          elevation={2}
          sx={{
            m: 2,
            height: '100%',
            width: '95%'
          }}
          square={fullscreen}>
          <FilterableList
            itemHeight={70}
            sorts={sorts}
            filter={userFilters}
            filterTooltip={
              <>
                <Typography variant="body2" gutterBottom>
                  {t('PAGES.USERS.LIST.SEARCH_TOOLTIP_1')}
                </Typography>
                <Typography variant="body2">
                  <Trans i18nKey="PAGES.USERS.LIST.SEARCH_TOOLTIP_2">
                    ''
                    <Typography variant="monospace">''</Typography>
                  </Trans>
                </Typography>
              </>
            }
            title={t('PAGES.USERS.LIST.TITLE')}
            loading={loading}
            refetching={refetching}
            error={error}
            data={users}
            renderItem={(user) => (
              <AvatarListItemEntry
                key={user.username}
                primary={user.username}
                primaryHint={user.fullname}
                secondary={
                  <MiniColoredChipList
                    items={user.groups
                      ?.filter((group) => group.name !== 'public')
                      .map((group) => group.name)}
                    label={t('PAGES.USERS.DETAIL.HEADER_GROUPS')}
                    labelIfEmpty={t('PAGES.USERS.LIST.NO_GROUPS')}
                    short={true}
                  />
                }
                avatarText={user.fullname || user.username}
                href={`/admin/users/view/${user.username}`}
                menuEntries={[
                  [
                    {
                      icon: <PersonIcon color="primary" />,
                      label: t('PAGES.USERS.LIST.BUTTON_DETAILS'),
                      href: `/admin/users/view/${user.username}`
                    }
                  ],
                  [
                    {
                      icon: <EditIcon color="secondary" />,
                      label: t('PAGES.USERS.LIST.BUTTON_EDIT'),
                      href: `/admin/users/edit/${user.username}`
                    },
                    {
                      label: t('PAGES.USERS.LIST.BUTTON_RESET_API_KEY'),
                      href: `/admin/users/apikey/${user.username}`
                    },
                    // Users don't have passwords if SSO is enabled, so don't offer to reset them:
                    ...(isSsoEnabled()
                      ? []
                      : [
                          {
                            label: t('PAGES.USERS.LIST.BUTTON_RESET_PASSWORD'),
                            href: `/admin/users/password/${user.username}`
                          }
                        ])
                  ],
                  [
                    {
                      icon: <DeleteIcon color="warning" />,
                      label: t('PAGES.USERS.LIST.BUTTON_DELETE'),
                      href: `/admin/users/delete/${user.username}`
                    }
                  ]
                ]}
              />
            )}
          />
        </Paper>
      </Grid>

      <Grow in={pathname === '/admin/users'}>
        <Fab
          variant="extended"
          color="secondary"
          sx={{ position: 'absolute', bottom: '16px', right: '16px' }}
          component={Link}
          to={'/admin/users/create'}>
          <AddIcon sx={{ mr: 1 }} />
          {t('PAGES.USERS.LIST.BUTTON_CREATE')}
        </Fab>
      </Grow>

      <Outlet />
    </>
  )
}

export default UsersList
