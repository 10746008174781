import React, { useCallback } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardHeader,
  Grid,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material'

import About from './About'
import Appearance from './Appearance'
import ResetPassword from './ResetPassword'
import ResetApiKey from './ResetApiKey'

import LicensesList from 'shared/components/LicensesList'

import { isSsoEnabled } from 'lib/env'

import licenses from '../../../licenses.json'

const Settings = () => {
  const theme = useTheme()
  const fullscreen = useMediaQuery(theme.breakpoints.down('sm'))
  const params = useParams()
  const section = params?.section
  const navigate = useNavigate()

  const goToCategory = useCallback(
    (category: string) =>
      navigate(section === category ? '/admin/settings' : `/admin/settings/${category}`),
    [navigate, section]
  )
  const setAppearanceExpanded = useCallback((_) => goToCategory('appearance'), [goToCategory])
  const setPasswordExpanded = useCallback((_) => goToCategory('password'), [goToCategory])
  const setApikeyExpanded = useCallback((_) => goToCategory('apikey'), [goToCategory])
  const setAboutExpanded = useCallback((_) => goToCategory('about'), [goToCategory])
  const setThirdpartyExpanded = useCallback((_) => goToCategory('thirdparty'), [goToCategory])

  return (
    <Grid container direction="column" alignItems="stretch" sx={{ mx: 'auto', maxWidth: '725px' }}>
      <Card sx={{ m: { sm: 2 }, mb: { xs: 0, sm: 6 }, pb: { xs: 6, sm: 0 } }} square={fullscreen}>
        <CardHeader title="Settings" />
        <Accordion square expanded={section === 'appearance'}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={setAppearanceExpanded}>
            <Typography variant="h6">Appearance</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Appearance />
          </AccordionDetails>
        </Accordion>

        {!isSsoEnabled() && (
          <Accordion square expanded={section === 'password'}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={setPasswordExpanded}>
              <Typography variant="h6">Change password</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ResetPassword />
            </AccordionDetails>
          </Accordion>
        )}

        <Accordion square expanded={section === 'apikey'}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={setApikeyExpanded}>
            <Typography variant="h6">Reset API Key</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ResetApiKey />
          </AccordionDetails>
        </Accordion>

        <Accordion square expanded={section === 'about'}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={setAboutExpanded}>
            <Typography variant="h6">About</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <About />
          </AccordionDetails>
        </Accordion>

        <Accordion square expanded={section === 'thirdparty'}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={setThirdpartyExpanded}>
            <Typography variant="h6">Third-party code</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <LicensesList licenses={licenses} />
          </AccordionDetails>
        </Accordion>
      </Card>
    </Grid>
  )
}

export default Settings
