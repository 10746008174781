import React, { useMemo } from 'react'

import { ListItemText, MenuItem, StandardTextFieldProps, Typography } from '@mui/material'
import { Field } from 'formik'
import { TextField } from 'formik-mui'

import { isTestEnvironment } from 'lib/env'

interface DescriptionSelectProps extends StandardTextFieldProps {
  entries: {
    title: string | React.ReactNode
    value: any
    description?: string | React.ReactNode
  }[]
  defaultLabel?: string
}

/**
 * A Select that allows helper text, and switching to native view while in test mode.
 */
const DescriptionSelect: React.FC<DescriptionSelectProps> = ({
  entries = [],
  defaultLabel,
  SelectProps,
  ...otherProps
}) => {
  // Use native select for the test framework
  const native = isTestEnvironment()

  // Check if any of the entries have descriptions so we can bold the title
  const hasDescriptions = useMemo(
    () => entries.reduce((p, c) => p || !!c.description, false),
    [entries]
  )

  return (
    <Field
      component={TextField}
      select
      fullWidth
      margin="dense"
      SelectProps={{
        ...SelectProps,
        native,
        renderValue: (v) => entries.find((e) => e.value === v)?.title ?? defaultLabel ?? ''
      }}
      {...otherProps}>
      {entries.map(({ title, value, description = null }) =>
        native ? (
          <option value={value} key={value}>
            {title}
          </option>
        ) : (
          <MenuItem
            value={value}
            key={value}
            sx={{
              flexDirection: 'column',
              my: 1,
              maxWidth: '400px'
            }}>
            <ListItemText
              primaryTypographyProps={{ style: { fontWeight: hasDescriptions ? '500' : null } }}
              primary={title}
              style={{ alignSelf: 'start' }}
            />
            {description && (
              <Typography
                variant="body2"
                sx={{ whiteSpace: 'normal', alignSelf: 'start' }}
                color="text.neutral">
                {description}
              </Typography>
            )}
          </MenuItem>
        )
      )}
    </Field>
  )
}

export default DescriptionSelect
