import React, { useEffect, useState } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'

import {
  Typography,
  Collapse,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Alert,
  AlertTitle
} from '@mui/material'
import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'

import { findAndRethrowError } from 'utils/error'
import {
  SendEmailRequest,
  SendEmailResponse,
  SEND_EMAIL,
  SET_USER_PASSWORD,
  SetUserPasswordResponse,
  SetUserPasswordRequest
} from 'lib/graphQlQueries'
import { useUsers } from 'lib/providers/UsersProvider'
import ErrorMessage from 'components/ErrorMessage'
import SubpageDialog from 'components/SubpageDialog'
import { resetCredentialEmailBody } from 'lib/emails/resetCredentialEmail'
import generatePassword from 'utils/generatePassword'

const ResetUserPassword = () => {
  const { t } = useTranslation()

  const { name } = useParams()

  const { enqueueSnackbar } = useSnackbar()

  const [error, setError] = useState<Error>()

  const [isOpen, setIsOpen] = useState(true)

  const [emailUser, setEmailUser] = useState(true)

  const {
    refetchAllUsers,
    getUsersLoading,
    getUsersRefetching,
    getUser,
    getError: getUsersError
  } = useUsers()

  const usersLoading = getUsersLoading()
  const usersError = getUsersError()
  const user = getUser(name)
  useEffect(() => setError(usersError), [usersError])

  const userEmailCheckboxDisabled = user && !user.email
  // Uncheck email checkbox if user is loaded but has no email
  useEffect(
    () => setEmailUser(emailUser && !userEmailCheckboxDisabled),
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Only depend on user, not the checkbox itself
    [userEmailCheckboxDisabled]
  )

  const [setUserPassword, { loading: setUserPasswordLoading, error: setUserPasswordError }] =
    useMutation<SetUserPasswordResponse, SetUserPasswordRequest>(SET_USER_PASSWORD)

  const [sendEmail, { loading: sendEmailLoading, error: sendEmailError }] = useMutation<
    SendEmailResponse,
    SendEmailRequest
  >(SEND_EMAIL)

  // Disable the form elements while a request is happening
  const formDisabled =
    usersLoading || setUserPasswordLoading || sendEmailLoading || getUsersRefetching()

  const onClose = () => {
    setIsOpen(false)
  }

  const handleEmailUserToggle = (event) => setEmailUser(event.target.checked)

  const confirmResetPassword = async (_event) => {
    // reset displayed error, if any
    setError(null)

    try {
      const password = generatePassword()
      const { data: setUserPasswordData, errors } = await setUserPassword({
        variables: {
          username: name,
          password
        }
      })
      findAndRethrowError(setUserPasswordData)
      findAndRethrowError(setUserPasswordError)
      if (errors?.[0]) {
        throw errors[0]
      }

      if (emailUser) {
        const { data: sendEmailData, errors } = await sendEmail({
          variables: {
            username: name,
            sender: 'support@pingthings.io',
            recipient: user.email, // TODO: email is not a required field on User
            subject: 'Your PredictiveGrid credentials have changed',
            emailBody: resetCredentialEmailBody(name, { password })
          }
        })
        findAndRethrowError(sendEmailData)
        findAndRethrowError(sendEmailError)
        if (errors?.[0]) {
          throw errors[0]
        }
      }

      await refetchAllUsers()

      // No errors, close window and show success snackbar
      enqueueSnackbar(t('PAGES.USERS.RESET_PASSWORD.SNACKBAR_SUCCESS', { name }), {
        variant: 'success'
      })
      setIsOpen(false)
    } catch (err) {
      setError(err)
    }
  }

  if (!name) {
    return <Navigate to="/admin/users" />
  } else
    return (
      <SubpageDialog
        title={
          <Trans i18nKey="PAGES.USERS.RESET_PASSWORD.TITLE" values={{ name }}>
            ''
            <Typography variant="h6" color="text.primaryColor" component="span">
              ''
            </Typography>
          </Trans>
        }
        loading={usersLoading}
        open={isOpen}
        onClose={onClose}
        nextPath={'/admin/users'}
        actions={[
          {
            label: t('PAGES.USERS.RESET_PASSWORD.BUTTON_CANCEL'),
            onClick: onClose,
            color: 'inherit',
            disabled: formDisabled,
            autoFocus: true
          },
          {
            label: t('PAGES.USERS.RESET_PASSWORD.BUTTON_RESET'),
            onClick: confirmResetPassword,
            variant: 'contained',
            color: 'error',
            disabled: formDisabled,
            loading: formDisabled && !usersLoading
          }
        ]}>
        <Collapse in={!!error}>
          <ErrorMessage error={error} />
        </Collapse>
        <Typography gutterBottom>
          <Trans i18nKey="PAGES.USERS.RESET_PASSWORD.BODY_1" values={{ name }}>
            ''
            <Typography variant="body1" color="text.primaryColor" component="span" key="type">
              ''
            </Typography>
          </Trans>
        </Typography>
        <Typography gutterBottom>{t('PAGES.USERS.RESET_PASSWORD.BODY_2')}</Typography>{' '}
        <FormControlLabel
          control={
            <Checkbox disabled={!user?.email} checked={emailUser} onClick={handleEmailUserToggle} />
          }
          label={t('PAGES.USERS.RESET_PASSWORD.EMAIL_USER')}
        />
        <Collapse in={user && !user?.email}>
          <FormHelperText sx={{ pb: 1 }}>{t('PAGES.USERS.RESET_PASSWORD.NO_EMAIL')}</FormHelperText>
        </Collapse>
        <Collapse in={user && !emailUser}>
          <Alert severity="warning">
            <AlertTitle>{t('PAGES.USERS.RESET_PASSWORD.NO_EMAIL_WARNING_TITLE')}</AlertTitle>
            {t('PAGES.USERS.RESET_PASSWORD.NO_EMAIL_WARNING_BODY')}
          </Alert>
        </Collapse>
      </SubpageDialog>
    )
}

export default ResetUserPassword
