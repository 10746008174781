import React from 'react'
import { createRoot } from 'react-dom/client'
import 'i18n/i18n'
import CssBaseline from '@mui/material/CssBaseline'
import AppRoutes from './routes'
import { AuthProvider } from 'lib/providers/AuthProvider'
import ApolloClientProvider from 'lib/providers/ApolloClientProvider'
import PTThemeProvider from 'lib/providers/PTThemeProvider'
import { SnackbarProvider } from 'notistack'
import './index.css'
import { setupGoogleAnalytics } from 'lib/analytics'
import { setupSentry } from 'lib/sentry'

declare global {
  interface Window {
    environment: Record<string, string>
  }
}

setupSentry()

setupGoogleAnalytics()

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  // <React.StrictMode>
  // TODO see issue https://github.com/apollographql/apollo-client/issues/9632
  // (This affects React 17 error handling too)
  <>
    <PTThemeProvider>
      <CssBaseline />
      <AuthProvider>
        <ApolloClientProvider>
          <SnackbarProvider
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            autoHideDuration={3000}>
            <AppRoutes />
          </SnackbarProvider>
        </ApolloClientProvider>
      </AuthProvider>
    </PTThemeProvider>
  </>
  // </React.StrictMode>
)
