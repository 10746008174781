import React, { useEffect, useState } from 'react'
import { Navigate, useParams } from 'react-router-dom'

import { Typography, Collapse } from '@mui/material'
import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'

import { findAndRethrowError } from 'utils/error'
import ErrorMessage from 'components/ErrorMessage'
import { REMOVE_INGRESS, RemoveIngressRequest, RemoveIngressResponse } from 'lib/graphQlQueries'
import { useIngresses } from 'lib/providers/IngressProvider'
import SubpageDialog from 'components/SubpageDialog'

const IngressDelete = () => {
  const { name } = useParams()

  const { enqueueSnackbar } = useSnackbar()

  const [error, setError] = useState<Error>()

  const [isOpen, setIsOpen] = useState(true)

  const {
    refetchAllIngresses,
    getAllIngressesLoading,
    getAllIngressesRefetching,
    getAllIngressesError
  } = useIngresses()

  const ingressesLoading = getAllIngressesLoading()
  const ingressesError = getAllIngressesError()
  useEffect(() => setError(ingressesError), [ingressesError])

  const [RemoveIngress, { loading: RemoveIngressLoading, error: RemoveIngressError }] = useMutation<
    RemoveIngressResponse,
    RemoveIngressRequest
  >(REMOVE_INGRESS, {
    variables: {
      name
    }
  })

  // Disable the form elements while a request is happening
  const formDisabled = ingressesLoading || RemoveIngressLoading || getAllIngressesRefetching()

  const onClose = () => {
    setIsOpen(false)
  }

  const confirmDelete = async (_event) => {
    // reset displayed error, if any
    setError(null)

    try {
      const { data } = await RemoveIngress()
      findAndRethrowError(data)
      findAndRethrowError(RemoveIngressError)

      await refetchAllIngresses()

      // No errors, close window and show success snackbar
      enqueueSnackbar(`Ingress ${name} deleted`, {
        variant: 'success'
      })
      setIsOpen(false)
    } catch (err) {
      setError(err)
    }
  }

  if (!name) {
    return <Navigate to="/admin/ingress" />
  } else
    return (
      <SubpageDialog
        title={
          <>
            {'Delete ingress '}
            <Typography variant="h6" color="text.primaryColor" component="span">
              {name}
            </Typography>
          </>
        }
        loading={ingressesLoading}
        open={isOpen}
        onClose={onClose}
        nextPath={'/admin/ingress'}
        actions={[
          {
            label: 'Cancel',
            onClick: onClose,
            color: 'inherit',
            disabled: formDisabled,
            autoFocus: true
          },
          {
            label: 'Delete',
            onClick: confirmDelete,
            variant: 'contained',
            color: 'error',
            disabled: formDisabled,
            loading: formDisabled
          }
        ]}>
        <Collapse in={!!error}>
          <ErrorMessage error={error} />
        </Collapse>
        <Typography gutterBottom>This ingress will be permanently deleted.</Typography>
        <Typography gutterBottom>
          <>{'Are you sure you wish to delete the ingress '}</>
          <Typography variant="body1" color="text.primaryColor" component="span">
            {name}
          </Typography>
          <>?</>
        </Typography>
      </SubpageDialog>
    )
}

export default IngressDelete
