/**
 * Check if two iterable objects (such as arrays) contain the same objects
 * regardless of order
 */
export function containSameContents<T extends Iterable<any>>(a: T, b: T) {
  // Validate that both exist
  if (!a || !b) {
    return false
  }
  // Validate that both are iterable
  if (a[Symbol.iterator] === undefined || b[Symbol.iterator] === undefined) {
    return false
  }
  const _a = [...a].sort()
  const _b = [...b].sort()
  if (_a.length !== _b.length) {
    return false
  }
  return _a.toString() === _b.toString()
}
