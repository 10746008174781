import React from 'react'

import { Fab, Grid, Grow, Paper, Typography, useMediaQuery, useTheme } from '@mui/material'
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Add as AddIcon,
  Stream as StreamIcon
} from '@mui/icons-material'

import FilterableList, { SortMenuEntry } from 'components/FilterableList'
import AvatarListItemEntry from 'components/AvatarListItemEntry'

import { Ingress } from 'lib/types/Ingress'
import { useIngresses } from 'lib/providers/IngressProvider'
import { Link, Outlet, useLocation } from 'react-router-dom'

const sorts: SortMenuEntry<Ingress>[] = [
  {
    label: 'Sort by name',
    sort: (a: Ingress, b: Ingress) => a.name.localeCompare(b.name)
  },
  {
    label: 'Sort by ingress class',
    sort: (a: Ingress, b: Ingress) => a.class.localeCompare(b.class) || a.name.localeCompare(b.name)
  },
  {
    label: 'Sort by comment',
    sort: (a: Ingress, b: Ingress) =>
      a.comment.localeCompare(b.comment) || a.name.localeCompare(b.name)
  },
  {
    label: 'Sort by enabled',
    sort: (a: Ingress, b: Ingress) => +a.enabled - +b.enabled || a.name.localeCompare(b.name)
  }
]

const ingressFilters: Record<string, (item: Ingress, filter: string) => boolean> = {
  name: (ingress: Ingress, s: string) =>
    ingress.name.toLocaleLowerCase().includes(s.toLocaleLowerCase()),
  class: (ingress: Ingress, s: string) =>
    ingress.class.toLocaleLowerCase().includes(s.toLocaleLowerCase()),
  prefix: (ingress: Ingress, s: string) =>
    ingress.collectionPrefix.toLocaleLowerCase().includes(s.toLocaleLowerCase()),
  enabled: (ingress: Ingress, s: string) =>
    (s.toLocaleLowerCase() === 'true' || s.toLocaleLowerCase() === 'false') &&
    ingress.enabled === (s.toLocaleLowerCase() === 'true'),
  comment: (ingress: Ingress, s: string) =>
    ingress.comment.toLocaleLowerCase().includes(s.toLocaleLowerCase())
}
/**
 * Ingresses page.
 *
 * Displays a list of ingresses as returned by `GetAllIngresses`.
 *
 * @constructor
 */
const ListIngresses = () => {
  const { pathname } = useLocation()

  const theme = useTheme()
  const fullscreen = useMediaQuery(theme.breakpoints.down('sm'))

  const {
    getAllIngresses,
    getAllIngressesLoading,
    getAllIngressesError,
    getAllIngressesRefetching
  } = useIngresses()

  const ingresses = getAllIngresses()
  const loading = getAllIngressesLoading()
  const error = getAllIngressesError()
  const refetching = getAllIngressesRefetching()

  return (
    <>
      <Grid
        container
        direction="column"
        alignItems="stretch"
        sx={{
          mx: 'auto',
          maxWidth: '725px',
          height: '100%',
          width: '100%'
        }}>
        <Paper
          elevation={2}
          sx={{
            m: 2,
            height: '100%',
            width: '95%'
          }}
          square={fullscreen}>
          <FilterableList
            itemHeight={70}
            sorts={sorts}
            filter={ingressFilters}
            filterTooltip={
              <>
                <Typography variant="body2" gutterBottom>
                  Search by name, class, prefix, enabled, or comment.
                </Typography>
                <Typography variant="body2">
                  You may use <Typography variant="monospace">name:</Typography>,{' '}
                  <Typography variant="monospace">class:</Typography>,{' '}
                  <Typography variant="monospace">prefix:</Typography>,{' '}
                  <Typography variant="monospace">enabled:</Typography>, or{' '}
                  <Typography variant="monospace">comment:</Typography> to search those fields
                  specifically.
                </Typography>
              </>
            }
            title="Ingresses"
            loading={loading}
            refetching={refetching}
            error={error}
            data={ingresses}
            renderItem={(ingress) => (
              <AvatarListItemEntry
                key={ingress.name}
                primary={`${ingress.name}`}
                primaryHint={[ingress.class, !ingress.enabled && 'disabled']
                  .filter((e) => e)
                  .join(', ')}
                primaryTypographyProps={
                  !ingress.enabled
                    ? {
                        color: 'text.neutral',
                        sx: {
                          fontStyle: 'italic'
                        }
                      }
                    : null
                }
                secondary={ingress.comment}
                avatarText={ingress.name}
                href={`/admin/ingress/view/${ingress.name}`}
                menuEntries={[
                  [
                    {
                      icon: <StreamIcon color="primary" />,
                      label: 'View details',
                      href: `/admin/ingress/view/${ingress.name}`
                    }
                  ],
                  [
                    {
                      icon: <EditIcon color="secondary" />,
                      label: 'Edit ingress',
                      href: `/admin/ingress/edit/${ingress.name}`
                    },
                    ingress.enabled
                      ? {
                          label: 'Disable ingress',
                          href: `/admin/ingress/disable/${ingress.name}`
                        }
                      : {
                          label: 'Enable ingress',
                          href: `/admin/ingress/enable/${ingress.name}`
                        }
                  ],
                  [
                    {
                      icon: <DeleteIcon color="warning" />,
                      label: 'Delete ingress',
                      href: `/admin/ingress/delete/${ingress.name}`
                    }
                  ]
                ]}
              />
            )}
          />
        </Paper>
      </Grid>

      <Grow in={pathname === '/admin/ingress'}>
        <Fab
          variant="extended"
          color="secondary"
          sx={{ position: 'absolute', bottom: '16px', right: '16px' }}
          component={Link}
          to={'/admin/ingress/create'}>
          <AddIcon sx={{ mr: 1 }} />
          Create
        </Fab>
      </Grow>

      <Outlet />
    </>
  )
}

export default ListIngresses
