import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'

import { ApolloClient } from '@apollo/client'
import { Maybe } from 'graphql/jsutils/Maybe'

import jwtDecode from 'jwt-decode'

import { findError } from 'utils/error'
import { clearCookie, retrieveCookie, storeCookie } from 'lib/cookies'
import {
  GetBuiltinUserRequest,
  GetBuiltinUserResponse,
  GET_BUILTIN_USER,
  LOGIN,
  LoginRequest,
  LoginResponse
} from 'lib/graphQlQueries'
import { User } from 'lib/types/User'
import { GroupCapability } from 'lib/types/Group'
import { isSsoEnabled } from 'lib/env'
import { getSsoToken } from 'lib/sso'

interface AuthProviderApi {
  getToken: () => string
  getLoggedInUsername: () => Maybe<string>
  getLoggedIn: () => boolean
  getPermissions: () => GroupCapability[] | void
  login: (username: string, password: string) => Promise<boolean>
  logout: () => void
  setAuthToken: (token: string) => void
  setApolloClient: (client: ApolloClient<any>) => void
}

const AuthContext = React.createContext<AuthProviderApi>({
  getToken: () => null,
  getLoggedInUsername: () => undefined,
  getLoggedIn: () => false,
  getPermissions: () => undefined,
  login: (username: string, password: string) => Promise.resolve(false),
  logout: () => undefined,
  setAuthToken: (token: string) => undefined,
  setApolloClient: (client: ApolloClient<any>) => undefined
})

interface AuthProviderProps {
  children?: React.ReactElement
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  // load auth token from cookie on first load
  const [authToken, setAuthToken] = useState<string>(
    () => retrieveCookie('token') || retrieveCookie('auth_token')
  )
  const [loggedInUsername, setLoggedInUsername] = useState<string>(() => retrieveCookie('username'))
  const [client, setApolloClient] = useState<ApolloClient<any>>()
  const [user, setUser] = useState({} as User)

  type PtAuthToken = {
    data: string
    iat: number
    exp: number
    /* attr only present in SSO tokens */
    attr?: {
      pt_username: [string]
      pt_apikey: [string]
      displayName?: [string]
      uid?: [string]
    }
  }

  const getToken = useCallback(() => (isSsoEnabled() ? getSsoToken() : authToken), [authToken])

  const getLoggedInUsername = useCallback(() => loggedInUsername, [loggedInUsername])

  const getLoggedIn = useCallback(
    () => (isSsoEnabled() ? Boolean(getSsoToken()) : Boolean(authToken)),
    [authToken]
  )

  // keep cookie in sync with auth token
  useEffect(() => {
    if (!authToken) return

    let decodedToken: PtAuthToken
    try {
      decodedToken = jwtDecode(authToken)
    } catch (err) {
      console.error('Error decoding token', err)
      return
    }
    const maxAge = decodedToken.exp - decodedToken.iat
    if (!isSsoEnabled()) {
      storeCookie('auth_token', authToken, maxAge)
    } else {
      // username comes from token in SSO
      const username = decodedToken?.attr?.pt_username?.[0]
      storeCookie('username', username)
      setLoggedInUsername(username)
    }
    storeCookie('username', loggedInUsername, maxAge)
  }, [authToken, loggedInUsername])

  const logout = useCallback(() => {
    // https://www.apollographql.com/docs/react/caching/advanced-topics/#resetting-the-cache
    client.clearStore()
    setAuthToken(null)
    setLoggedInUsername(null)
    clearCookie('auth_token')
    clearCookie('username')
    clearCookie('token')
  }, [client])

  // Login functionality (client has to come from a callback from the
  // downstream ApolloClientProvider)
  const login = useCallback(
    async (username: string, password: string) => {
      if (!client) return
      const { data, errors } = await client.mutate<LoginResponse, LoginRequest>({
        mutation: LOGIN,
        variables: {
          username,
          password
        }
      })

      const token = (data as unknown as { Login: { token: string } })?.Login?.token
      const user = (data as unknown as { Login: { username: string } })?.Login?.username

      console.error(`AuthProvider: token ${token} user ${user}`)

      if (token) {
        setAuthToken(token)
        setLoggedInUsername(user)
        return true
      } else {
        client.clearStore()
        throw findError(data) ?? findError(errors?.[0]) ?? new Error('Error logging in')
      }
    },
    [client]
  )

  // Get own user object when we can
  const getBuiltinUser = useCallback(
    async (username: string) => {
      if (!client || !authToken || !username) return
      const { data } = await client.query<GetBuiltinUserResponse, GetBuiltinUserRequest>({
        query: GET_BUILTIN_USER,
        variables: {
          username
        }
      })
      return data?.GetBuiltinUser?.user
    },
    [client] // eslint-disable-line react-hooks/exhaustive-deps -- We depend on client and
    // not authToken because client depends on authToken and we want to let it update first
  )

  useEffect(() => {
    ;(async () => {
      const user = await getBuiltinUser(loggedInUsername)
      setUser(user)
    })()
  }, [client, authToken, loggedInUsername, getBuiltinUser])

  const getPermissions = useCallback(() => {
    return (
      user &&
      (user?.groups
        ?.map((group) => group?.capabilities) // get capabilities from all groups user is part of
        ?.flat() // merge all capabilities into one list
        ?.filter((e, i, a) => a.indexOf(e) === i) // keep first instance of each value
        ?.sort() ??
        [])
    )
  }, [user])

  return (
    <AuthContext.Provider
      value={{
        getToken,
        getLoggedInUsername,
        getLoggedIn,
        getPermissions,
        login,
        logout,
        setAuthToken,
        setApolloClient
      }}>
      {children ?? <Outlet />}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)
