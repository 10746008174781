export default {
  COMPONENTS: {
    AUTOCOMPLETE_MULTI_SELECT_INPUT: {
      ADD_NEW_VALUE: 'Add new value'
    },
    ERROR_MESSAGE: {
      ERROR: 'Error',
      ERROR_HAS_MESSAGE: 'An error has occurred',
      ERROR_UNKNOWN: 'An unidentified error has occurred.',
      BUTTON_RELOAD_PAGE: 'Reload page',
      BUTTON_LOG_IN: 'Log in',
      BUTTON_RETRY: 'Retry'
    },
    TOOLBAR_SEARCH_INPUT: {
      FILTER: 'Filter',
      CLEAR: 'Clear filter'
    },
    NAVBAR: {
      BUTTON_LOG_IN: 'Log in',
      BUTTON_LOG_OUT: 'Log out'
    },
    SIDE_NAV: {
      USER_MANAGEMENT: 'User Management',
      USERS: 'Users',
      GROUPS: 'Groups',
      STREAM_MANAGEMENT: 'Stream Management',
      INGRESSES: 'Ingresses',
      SETTINGS: 'Settings'
    }
  },
  PAGES: {
    USERS: {
      CREATE: {
        TITLE: 'Create new user',
        BUTTON_CANCEL: 'Cancel',
        BUTTON_CREATE: 'Create',
        FIELD_USERNAME_LABEL: 'Username',
        FIELD_EMAIL_LABEL: 'Email',
        FIELD_AUTOGENERATE_PASSWORD_LABEL: 'Auto-generate password',
        FIELD_PASSWORD: 'Password',
        FIELD_VERIFY_PASSWORD: 'Verify password',
        FIELD_FULLNAME_LABEL: 'Full Name',
        FIELD_COMMENT_LABEL: 'Comment',
        FIELD_GROUPS_LABEL: 'Groups',
        FIELD_GROUPS_PLACEHOLDER: 'Add user to group',
        FIELD_SEND_EMAIL: 'Send new user welcome email',
        FIELD_EMAIL_PASSWORD: 'Include password',
        FIELD_EMAIL_APIKEY: 'Include API key',
        EFFECTIVE_PERMISSIONS_LABEL: 'Effective permissions',
        SNACKBAR_SUCCESS: 'User {{name}} created',
        RESET_PASSWORD_WARNING_TITLE: 'Warning',
        RESET_PASSWORD_WARNING_BODY:
          'The user will be unable to log in until a known password is set or emailed to them.'
      },
      DELETE: {
        TITLE: 'Delete user <1>{{name}}</1>',
        BUTTON_CANCEL: 'Cancel',
        BUTTON_DELETE: 'Delete',
        BODY_1: 'This uesr will be permanently deleted.',
        BODY_2: 'Are you sure you wish to delete the user <1>{{name}}</1>?',
        SNACKBAR_SUCCESS: 'User {{name}} deleted'
      },
      DETAIL: {
        TITLE: 'User <1>{{name}}</1>',
        NOT_FOUND: 'the user was not found',
        BUTTON_EDIT: 'Edit',
        BUTTON_CLOSE: 'Close',
        BUTTON_DELETE: 'Delete',
        BUTTON_RESET_PASSWORD: 'Reset Password',
        BUTTON_RESET_APIKEY: 'Reset API Key',
        BUTTON_SHOW_API_KEY: 'Show API Key',
        HEADER_DETAILS: 'Details',
        HEADER_EFFECTIVE_PERMISSIONS: 'Effective permissions',
        HEADER_GROUPS: 'Groups',
        LABEL_FULLNAME: 'Full name',
        LABEL_EMAIL: 'Email',
        LABEL_COMMENT: 'Comment',
        LABEL_APIKEY: 'API Key',
        LABEL_CREATION_DATE: 'Account creation date',
        LABEL_ACCESS_ADTE: 'Last access date',
        NO_VALUE: '(unset)',
        CAPABILITIES_EMPTY_MESSAGE: 'No capabilities'
      },
      EDIT: {
        // Edit page reuses many strings from Create
        TITLE: 'Edit user: <1>{{name}}</1>',
        BUTTON_SAVE: 'Save',
        SNACKBAR_SUCCESS: 'User {{name}} saved'
      },
      LIST: {
        // List page reuses many strings from detail
        TITLE: 'Users',
        SEARCH_TOOLTIP_1: 'Search by user name, fullname, email, comment, or group membership.',
        SEARCH_TOOLTIP_2:
          'You may use <1>name:</1>, <1>fullname:</1>, <1>email:</1>, <1>comment:</1>, ' +
          'or <1>group:</1> to search those fields specifically.',
        SORT_NAME: 'Sort by name',
        NO_GROUPS: 'No groups',
        BUTTON_DETAILS: 'View details',
        BUTTON_EDIT: 'Edit user',
        BUTTON_RESET_API_KEY: 'Reset API key',
        BUTTON_RESET_PASSWORD: 'Reset password',
        BUTTON_DELETE: 'Delete user',
        BUTTON_CREATE: 'Create'
      },
      RESET_API_KEY: {
        TITLE: "Reset <1>{{name}}</1>'s API Key",
        BUTTON_CANCEL: 'Cancel',
        BUTTON_RESET: 'Reset',
        BODY_1: "Are you sure you want to reset <1>{{name}}</1>'s API key?",
        BODY_2:
          'Any existing applications using the existing API key will be unable to ' +
          'authenticate until their credentials are updated.',
        SNACKBAR_SUCCESS: "User {{name}}'s API key successfully reset",
        EMAIL_USER: 'Email user new API key',
        NO_EMAIL: 'API key cannot be sent because there is no email associated with the user.'
      },
      RESET_PASSWORD: {
        TITLE: "Reset <1>{{name}}</1>'s Password",
        BUTTON_CANCEL: 'Cancel',
        BUTTON_RESET: 'Reset',
        BODY_1: "Are you sure you want to reset <1>{{name}}</1>'s password?",
        BODY_2: 'They will be logged out of any active sessions.',
        SNACKBAR_SUCCESS: "User {{name}}'s password successfully reset",
        EMAIL_USER: 'Email user new password',
        NO_EMAIL: 'Password cannot be sent because there is no email associated with the user.',
        NO_EMAIL_WARNING_TITLE: 'Warning',
        NO_EMAIL_WARNING_BODY:
          "Resetting a user's password without emailing them a " +
          'new password will effectively disable the account until a new password is sent via email.'
      }
    },
    GROUPS: {
      CREATE: {
        TITLE: 'Create new group',
        BUTTON_CANCEL: 'Cancel',
        BUTTON_CREATE: 'Create',
        GROUP_NAME: 'Group name',
        ACCESS_ALL_STREAMS_LABEL: 'Allow access to all streams',
        ACCESS_ALL_STREAMS_DESCRIPTION:
          'If unchecked, users in this group will only be able to see streams whose collection names start with the provided prefixes.',
        PREFIXES_LABEL: 'Prefixes',
        PREFIXES_PLACEHOLDER: 'Add prefix',
        CAPABILITIES_LABEL: 'Capabilities',
        CAPABILITIES_PLACEHOLDER: 'Add capability',
        SNACKBAR_SUCCESS: 'Group {{name}} created'
      },
      DELETE: {
        TITLE: 'Delete group <1>{{name}}</1>',
        BUTTON_CANCEL: 'Cancel',
        BUTTON_DELETE: 'Delete',
        BODY_1: 'This group will be permanently deleted.',
        BODY_2: 'Are you sure you wish to delete the group <1>{{name}}</1>?',
        SNACKBAR_SUCCESS: 'Group {{name}} deleted'
      },
      DETAIL: {
        TITLE: 'Group <1>{{name}}</1>',
        BUTTON_EDIT: 'Edit',
        BUTTON_CLOSE: 'Close',
        BUTTON_DELETE: 'Delete',
        PREFIXES_LABEL: 'Prefixes',
        PREFIXES_EMPTY_MESSAGE: 'No prefixes',
        PREFIXES_ALL_PREFIXES: 'All prefixes',
        PREFIXES_EMPTY_PREFIX: '(empty prefix)',
        CAPABILITIES_LABEL: 'Capabilities',
        CAPABILITIES_EMPTY_MESSAGE: 'No capabilities',
        USERS_IN_GROUP_LABEL: 'Users in group',
        USERS_IN_GROUP_EMPTY_MESSAGE: 'No users in group'
      },
      EDIT: {
        // Edit page reuses many strings from Create
        TITLE: 'Edit group: <1>{{name}}</1>',
        BUTTON_SAVE: 'Save',
        SNACKBAR_SUCCESS: 'Group {{name}} saved'
      },
      LIST: {
        // List page reuses many strings from detail
        TITLE: 'Groups',
        SEARCH_TOOLTIP_1: 'Search by group name, prefixes, or capabilities.',
        SEARCH_TOOLTIP_2:
          'You may use <1>name:</1>, <1>prefix:</1>, or <1>capability:</1> to search those fields specifically.',
        SORT_NAME: 'Sort by name',
        BUTTON_DETAILS: 'View details',
        BUTTON_EDIT: 'Edit group',
        BUTTON_DELETE: 'Delete group',
        BUTTON_CREATE: 'Create'
      }
    },
    INGRESSES: {},
    SETTINGS: {},
    LOGIN: {
      TITLE: 'PredictiveGrid Management System',
      USERNAME: 'Username',
      PASSWORD: 'Password',
      BUTTON_LOGIN: 'Login'
    },
    NOT_FOUND: {
      TITLE: 'Page not found',
      BUTTON_HOME: 'Go home',
      BUTTON_BACK: 'Back'
    },
    UNAUTHORIZED: {
      TITLE: 'Unauthorized',
      BODY: 'Your session may have expired. Please log back in or reload the page.',
      BUTTON_RELOAD: 'Reload'
    }
  }
} as const
