import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'

import { Delete as DeleteIcon } from '@mui/icons-material'
import { List, Typography } from '@mui/material'

import AutoUserAvatar from 'components/AutoUserAvatar'
import MiniColoredChipList from 'components/MiniColoredChipList'
import SubpageDialog from 'components/SubpageDialog'
import { useGroups } from 'lib/providers/GroupsProvider'
import { useUsers } from 'lib/providers/UsersProvider'
import DetailRow from 'components/DetailRow'

const GroupDetail = () => {
  const { t } = useTranslation()

  const { name } = useParams()

  const { getGroup, getGroupsLoading } = useGroups()
  const [isOpen, setIsOpen] = useState(true)

  const [nextPath, setNextPath] = useState('/admin/groups')

  const onClose = () => {
    setIsOpen(false)
  }

  const group = getGroup(name)
  const groupsLoading = getGroupsLoading()

  const navigateTo = (path: string) => {
    setNextPath(path)
    setIsOpen(false)
  }
  const editGroup = () => navigateTo(`/admin/groups/edit/${name}`)
  const deleteGroup = () => navigateTo(`/admin/groups/delete/${name}`)

  const { getUsersInGroup, getUsersLoading } = useUsers()
  const usersInGroup = getUsersInGroup(name)
  const usersLoading = getUsersLoading()

  const goToUser = (event: { preventDefault: () => void }, path: string) => {
    event.preventDefault()
    navigateTo(path)
  }

  if (!name) {
    navigateTo('/admin/groups')
  } else
    return (
      <SubpageDialog
        title={
          <Trans i18nKey="PAGES.GROUPS.DETAIL.TITLE" values={{ name }}>
            ''
            <Typography variant="h6" color="text.primaryColor" component="span">
              ''
            </Typography>
          </Trans>
        }
        loading={groupsLoading || usersLoading}
        open={isOpen}
        onClose={onClose}
        nextPath={nextPath}
        actions={[
          {
            onClick: editGroup,
            label: t('PAGES.GROUPS.DETAIL.BUTTON_EDIT'),
            color: 'inherit'
          },
          {
            onClick: onClose,
            label: t('PAGES.GROUPS.DETAIL.BUTTON_CLOSE'),
            variant: 'contained',
            autoFocus: true
          }
        ]}
        overflowActions={[
          {
            onClick: deleteGroup,
            icon: <DeleteIcon color="warning" />,
            label: t('PAGES.GROUPS.DETAIL.BUTTON_DELETE')
          }
        ]}>
        {!groupsLoading && !usersLoading && (
          <>
            <Typography variant="body1">{t('PAGES.GROUPS.DETAIL.PREFIXES_LABEL')}</Typography>
            {group.prefixes?.includes('') ? (
              <MiniColoredChipList
                items={[t('PAGES.GROUPS.DETAIL.PREFIXES_ALL_PREFIXES')]}
                variant="outlined"
                sx={{ my: '16px', ml: 2 }}
              />
            ) : (
              <MiniColoredChipList
                items={group.prefixes}
                labelIfEmpty={t('PAGES.GROUPS.DETAIL.PREFIXES_EMPTY_MESSAGE')}
                emptyValue={t('PAGES.GROUPS.DETAIL.PREFIXES_EMPTY_PREFIX')}
                sx={{ my: '16px', ml: 2 }}
              />
            )}
            <Typography variant="body1" sx={{ mt: 4 }}>
              {t('PAGES.GROUPS.DETAIL.CAPABILITIES_LABEL')}
            </Typography>
            <MiniColoredChipList
              items={group.capabilities}
              labelIfEmpty={t('PAGES.GROUPS.DETAIL.CAPABILITIES_EMPTY_MESSAGE')}
              sx={{ my: '16px', ml: 2 }}
            />
            <Typography variant="body1" sx={{ mt: 4 }}>
              {usersInGroup?.length > 0
                ? t('PAGES.GROUPS.DETAIL.USERS_IN_GROUP_LABEL')
                : t('PAGES.GROUPS.DETAIL.USERS_IN_GROUP_EMPTY_MESSAGE')}
            </Typography>
            <List sx={{ pt: 0 }}>
              {usersInGroup?.map((user) => (
                <DetailRow
                  primary={user.username}
                  secondary={user.fullname}
                  path={`/admin/users/view/${user.username}`}
                  action={(e) => goToUser(e, `/admin/users/view/${user.username}`)}
                  key={user.username}
                  avatar={<AutoUserAvatar name={user.username} />}
                />
              ))}
            </List>
          </>
        )}
      </SubpageDialog>
    )
}

export default GroupDetail
