import React, { useState } from 'react'

import { TextField } from '@mui/material'

import ErrorMessage from 'components/ErrorMessage'
import SubpageDialog from 'components/SubpageDialog'
import { useAuth } from 'lib/providers/AuthProvider'
import { useNavigate } from 'react-router-dom'

interface ReLoginDialogProps {
  onLogin: () => void
  open: boolean
}
const ReLoginDialog: React.FC<ReLoginDialogProps> = ({ onLogin, open }) => {
  const { login, logout } = useAuth()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const navigate = useNavigate()

  const onSubmit = async (_event: React.MouseEvent | React.FormEvent) => {
    setLoading(true)
    try {
      await login(username, password)
      onLogin?.()
    } catch (e) {
      console.log('error in login', e)
      setError(e)
      setLoading(false)
    }
  }

  const onCancel: React.MouseEventHandler<HTMLButtonElement> = (_event) => {
    logout()
    navigate('/')
  }

  return (
    <SubpageDialog
      title="Log back in"
      open={open}
      actions={[
        {
          label: 'Return Home',
          onClick: onCancel,
          disabled: loading,
          color: 'inherit'
        },
        {
          label: 'Login',
          onClick: onSubmit,
          variant: 'contained',
          disabled: loading || !username || !password,
          loading: loading
        }
      ]}
      onClose={() => {}}>
      <ErrorMessage error={error} canClose={true} />
      <form onSubmit={onSubmit} style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <TextField
          label="Username"
          variant="standard"
          value={username}
          onChange={($event) => setUsername($event.target.value)}
        />
        <TextField
          type="password"
          id="standard-basic"
          label="Password"
          variant="standard"
          value={password}
          onChange={($event) => setPassword($event.target.value)}
        />
      </form>
    </SubpageDialog>
  )
}

export default ReLoginDialog
