import * as React from 'react'
import {
  Icon,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TypographyTypeMap
} from '@mui/material'
import { ArrowForward as ArrowForwardIcon } from '@mui/icons-material'

interface DetailRowProps {
  primary: string
  secondary?: string | React.ReactNode
  secondaryDefault?: string
  secondaryVariant?: TypographyTypeMap['props']['variant']
  icon?: typeof Icon
  path?: string
  avatar?: React.ReactElement
  action?: React.MouseEventHandler<HTMLElement>
}

const DetailRow: React.FC<DetailRowProps> = ({
  primary,
  secondary,
  secondaryDefault,
  secondaryVariant,
  icon: Icon,
  path,
  avatar,
  action
}) => {
  let color: string
  // Let secondary variant set color, otherwise text.primary if set
  if (secondaryVariant === 'monospace') {
    color = 'text.main'
  } else if (secondary) {
    color = 'text.primary'
  } else if (!secondary) {
    color = 'text.neutral'
  }
  return (
    <ListItem
      secondaryAction={
        action && (
          <IconButton href={path} onClick={action}>
            {Icon ? <Icon /> : <ArrowForwardIcon />}
          </IconButton>
        )
      }>
      {avatar && <ListItemAvatar>{avatar}</ListItemAvatar>}
      <ListItemText
        primary={primary}
        secondary={secondary || secondaryDefault}
        secondaryTypographyProps={{
          variant: secondaryVariant,
          color,
          style: { display: 'inline' },
          component: 'span'
        }}
      />
    </ListItem>
  )
}

export default DetailRow
