import React, { useCallback, useMemo, useState } from 'react'

import { ButtonBase, Collapse, styled, Theme } from '@mui/material'
import { ChevronRight } from '@mui/icons-material'

const ExpandoButton = styled(ButtonBase)`
  padding-top: 3px;
  padding-bottom: 3px;
`

const ExpandoContent = styled('div')(
  ({ theme }: { theme: Theme }) => `
    margin-left: ${theme.spacing(1)};
    margin-right: ${theme.spacing(1)};
    padding: ${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(1)} ${theme.spacing(2)};
    border-left: 4px solid ${theme.palette.grey[300]};
  `
)

interface ExpandoProps {
  title: React.ReactNode
  content: React.ReactNode
}
/** Similar to an accordion, allows expanding text to show more information */
const Expando: React.FC<ExpandoProps> = ({ title, content }) => {
  const [expanded, setExpanded] = useState(false)
  const toggleExpanded = useCallback(() => setExpanded(!expanded), [expanded])

  const expandedStyle = useMemo(
    () => ({
      transform: expanded ? 'rotate(90deg)' : 'rotate(0)',
      transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
    }),
    [expanded]
  )

  return (
    <>
      <ExpandoButton
        // expandIcon={<ChevronRight />}
        onClick={toggleExpanded}>
        <>
          <ChevronRight sx={expandedStyle} />
          {title}
        </>
      </ExpandoButton>
      <Collapse in={expanded}>
        <ExpandoContent>{content}</ExpandoContent>
      </Collapse>
    </>
  )
}

export default Expando
