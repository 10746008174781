export const SUPPORT_EMAIL_ADDRESS = 'support@pingthings.io'
export const SUPPORT_NAME = 'Support Team'

// This is currently hardcoded to English as the Admin's language settings should
// not have an impact on the new user's email language.
export const newUserEmailBody = (
  username: string,
  fullname?: string,
  credentials?: { password?: string; apikey?: string }
) => {
  let body = `Hello${fullname ? ` ${fullname}` : ''},

An account has been created for you at PingThings.

Your account information is:

username: ${username}
${[
  ...[
    credentials.password &&
      `password: ${credentials.password}
`
  ],
  ...[
    credentials.apikey &&
      `API key: ${credentials.apikey}
`
  ]
].join('')}
Please let us know if you have any questions or are having any trouble accessing the data. Please contact us if you would like assistance setting this up.

${SUPPORT_NAME}
PingThings, Inc.
${SUPPORT_EMAIL_ADDRESS}
https://www.pingthings.io/`
  return body
}
