import React from 'react'

import { Typography } from '@mui/material'

import {
  getBackendGitCommit,
  getBackendVersion,
  getFrontendGitCommit,
  getFrontendVersion
} from 'lib/env'

import packageJson from '../../../../package.json'

const About = () => {
  const frontendVersion = getFrontendVersion()?.replace?.(/"/g, '') ?? packageJson?.version
  const frontendHash = getFrontendGitCommit()?.slice?.(0, 7)
  const backendVersion = getBackendVersion()
  const backendHash = getBackendGitCommit()?.slice?.(0, 7)

  return (
    <>
      <Typography fontWeight={500} gutterBottom color="text.primaryColor">
        PingThings Management System
      </Typography>
      {(frontendVersion || frontendHash) && (
        <Typography gutterBottom>
          Webapp {frontendVersion ? `version ${frontendVersion}` : ''}
          {frontendVersion && frontendHash ? ', ' : ''}
          {frontendHash && (
            <>
              build <Typography variant="monospace">{frontendHash}</Typography>
            </>
          )}
          .
        </Typography>
      )}
      {(backendVersion || backendHash) && (
        <Typography gutterBottom>
          Server {backendVersion ? `version ${backendVersion}` : ''}
          {backendVersion && backendHash ? ', ' : ''}
          {backendHash && (
            <>
              build <Typography variant="monospace">{backendHash}</Typography>
            </>
          )}
          .
        </Typography>
      )}
      <Typography gutterBottom>&copy; 2014-{new Date().getFullYear()} PingThings, Inc.</Typography>
    </>
  )
}

export default About
