import React from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'

import * as Sentry from '@sentry/react'

import ProtectedRoute from 'lib/ProtectedRoute'

import { UsersProvider } from 'lib/providers/UsersProvider'
import { GroupsProvider } from 'lib/providers/GroupsProvider'
import { IngressProvider } from 'lib/providers/IngressProvider'

import { GoogleAnalyticsPageTracker } from 'lib/analytics'

import Shell from 'components/Shell'
import Login from 'pages/Login/login'
import NotFound from 'pages/NotFound'

import Settings from 'pages/Admin/Settings/Settings'

import GroupsList from 'pages/Admin/Groups/ListGroups'
import GroupDetail from 'pages/Admin/Groups/GroupDetail'
import GroupCreate from 'pages/Admin/Groups/GroupCreate'
import GroupEdit from 'pages/Admin/Groups/GroupEdit'
import GroupDelete from 'pages/Admin/Groups/GroupDelete'

import UsersList from 'pages/Admin/Users/ListUsers'
import UserDetail from 'pages/Admin/Users/UserDetail'
import UserCreate from 'pages/Admin/Users/CreateUser'
import UserEdit from 'pages/Admin/Users/EditUser'
import UserDelete from 'pages/Admin/Users/DeleteUser'
import ResetUserApiKey from 'pages/Admin/Users/ResetUserApiKey'
import ResetUserPassword from 'pages/Admin/Users/ResetUserPassword'

import ListIngresses from 'pages/Admin/Ingresses/ListIngresses'
import IngressDetail from 'pages/Admin/Ingresses/IngressDetail'
import CreateIngress from 'pages/Admin/Ingresses/CreateIngress'
import EditIngress from 'pages/Admin/Ingresses/EditIngress'
import DeleteIngress from 'pages/Admin/Ingresses/DeleteIngress'
import EnableDisableIngress from 'pages/Admin/Ingresses/EnableDisableIngress'

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

const AppRoutes = () => (
  <BrowserRouter>
    <GoogleAnalyticsPageTracker>
      <SentryRoutes>
        {/* Login page (which should not be wrapped with Shell */}
        <Route path="/login" element={<Login />} />

        {/* All other public pages */}
        <Route element={<Shell />}>
          <Route path="/" element={<Navigate to="/admin/users" replace />} />

          {/* Pages for authenticated users */}
          <Route element={<ProtectedRoute />}>
            {/* Admin routes */}
            <Route path="admin">
              <Route element={<UsersProvider />}>
                <Route element={<GroupsProvider />}>
                  <Route path="users" element={<UsersList />}>
                    <Route path="create" element={<UserCreate />} />
                    <Route path="view/:name" element={<UserDetail />} />
                    <Route path="edit/:name" element={<UserEdit />} />
                    <Route path="delete/:name" element={<UserDelete />} />
                    <Route path="apikey/:name" element={<ResetUserApiKey />} />
                    <Route path="password/:name" element={<ResetUserPassword />} />
                  </Route>
                  <Route path="groups" element={<GroupsList />}>
                    <Route path="create" element={<GroupCreate />} />
                    <Route path="view/:name" element={<GroupDetail />} />
                    <Route path="edit/:name" element={<GroupEdit />} />
                    <Route path="delete/:name" element={<GroupDelete />} />
                  </Route>
                  <Route path="settings" element={<Settings />}>
                    <Route path=":section" element={<></>} />
                  </Route>
                </Route>
              </Route>
              <Route element={<IngressProvider />}>
                <Route path="ingress" element={<ListIngresses />}>
                  <Route path="create" element={<CreateIngress />} />
                  <Route path="view/:name" element={<IngressDetail />} />
                  <Route path="edit/:name" element={<EditIngress />} />
                  <Route path="delete/:name" element={<DeleteIngress />} />
                  <Route path="enable/:name" element={<EnableDisableIngress />} />
                  <Route path="disable/:name" element={<EnableDisableIngress />} />
                </Route>
              </Route>
            </Route>
          </Route>

          {/* No routes matched - 404 */}
          <Route path="*" element={<NotFound />} />
        </Route>
      </SentryRoutes>
    </GoogleAnalyticsPageTracker>
  </BrowserRouter>
)

export default AppRoutes
