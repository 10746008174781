import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Clear as ClearIcon,
  InfoOutlined as InfoOutlinedIcon,
  Search as SearchIcon
} from '@mui/icons-material'
import { IconButton, Input, InputAdornment, InputProps, Tooltip } from '@mui/material'

export interface ToolbarSearchInputProps extends Omit<InputProps, 'onChange'> {
  onChange: (text: string) => void
  tooltip?: string | React.ReactNode
}

const ToolbarSearchInput: React.FC<ToolbarSearchInputProps> = ({
  onChange,
  tooltip,
  ...otherProps
}) => {
  const { t } = useTranslation()

  const [filterText, setFilterText] = useState('')

  // Trigger external onChange each time filter text changes
  useEffect(() => onChange?.(filterText), [filterText, onChange])

  // Fired when filter's "clear" button is clicked
  const clearFilterClick = () => {
    setFilterText('')
  }

  // Clear filter on escape key press
  const handleKeypress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Escape') {
      setFilterText('')
    }
  }

  // Fired when the filter text changes
  const filterChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setFilterText(event.target.value)

  return (
    <Input
      value={filterText}
      onChange={filterChange}
      id="filter-groups"
      placeholder={t('COMPONENTS.TOOLBAR_SEARCH_INPUT.FILTER')}
      size="small"
      inputProps={{
        onKeyUp: handleKeypress
      }}
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      }
      endAdornment={
        filterText !== '' ? (
          <InputAdornment position="end">
            <IconButton
              aria-label={t('COMPONENTS.TOOLBAR_SEARCH_INPUT.CLEAR')}
              onClick={clearFilterClick}
              edge="end"
              size="small">
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ) : (
          tooltip && (
            <InputAdornment position="end">
              <Tooltip title={tooltip} placement="bottom-end" arrow>
                <InfoOutlinedIcon />
              </Tooltip>
            </InputAdornment>
          )
        )
      }
      {...otherProps}
    />
  )
}
export default ToolbarSearchInput
