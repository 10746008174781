import React, { useContext } from 'react'
import { Outlet } from 'react-router-dom'

import { ApolloError, ApolloQueryResult, NetworkStatus, useQuery } from '@apollo/client'
import { Maybe } from 'graphql/jsutils/Maybe'

import { GET_ALL_GROUP_OBJECTS, GetAllGroupObjectsResponse } from 'lib/graphQlQueries'
import { Group, GroupCapability } from 'lib/types/Group'

export interface GroupsProviderApi {
  getAllGroups: () => Maybe<Group[]>
  refetchAllGroups: () => Promise<ApolloQueryResult<GetAllGroupObjectsResponse>>
  getGroup: (name: string) => Maybe<Group>
  getGroupsLoading: () => boolean
  getGroupsRefetching: () => boolean
  getError: () => Maybe<ApolloError>
  getEffectivePermissions: (groups: Group[] | string[]) => GroupCapability[]
}

export const GroupsContext = React.createContext<GroupsProviderApi>({
  getAllGroups: () => null,
  refetchAllGroups: () => null,
  getGroup: () => null,
  /** If the group is doing its initial load. False during refresh. */
  getGroupsLoading: () => true,
  /** If the group is refetching data. False during initial load. */
  getGroupsRefetching: () => true,
  getError: () => null,
  /** Given a list of groups, check what capabilities they will give */
  getEffectivePermissions: () => null
})

interface GroupsProviderProps {
  children?: React.ReactElement
}

export const GroupsProvider: React.FC<GroupsProviderProps> = ({ children }) => {
  const { data, error, refetch, networkStatus } = useQuery<GetAllGroupObjectsResponse>(
    GET_ALL_GROUP_OBJECTS,
    {
      notifyOnNetworkStatusChange: true
    }
  )

  const getAllGroups = () => data?.GetAllGroupObjects?.groups
  const refetchAllGroups = () => refetch()
  const getGroup = (name: string) => getAllGroups()?.filter((group) => name === group.name)?.[0]
  const getGroupsLoading = () => networkStatus === NetworkStatus.loading
  const getGroupsRefetching = () => networkStatus === NetworkStatus.refetch
  const getError = () => error || data?.GetAllGroupObjects?.error
  const getEffectivePermissions = (groups: Group[] | string[]) =>
    groups
      ?.map((group) =>
        typeof group === 'string'
          ? getAllGroups()?.find((g: Group) => g.name === (group as string))
          : group
      )
      ?.map((group) => group?.capabilities)
      ?.flat()
      ?.filter((e, i, a) => a.indexOf(e) === i)
      ?.sort() ?? []

  return (
    <GroupsContext.Provider
      value={{
        getAllGroups,
        refetchAllGroups,
        getGroup,
        getGroupsLoading,
        getError,
        getGroupsRefetching,
        getEffectivePermissions
      }}>
      {children ?? <Outlet />}
    </GroupsContext.Provider>
  )
}

export const useGroups = () => useContext(GroupsContext)
