import React from 'react'
import { Avatar, type AvatarProps, type SxProps } from '@mui/material'
import { stringToMaterialColorSx } from '../utils/color'

/**
 * string.prototype.charAt() can't handle unicode pairs, so some characters
 * break while generating initials for avatars.
 *
 * See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/charAt#fixing_charat_to_support_non-basic-multilingual-plane_bmp_characters
 * for more information
 */
function fixedCharAt(str, idx) {
  let ret = ''
  str += ''
  let end = str.length

  let surrogatePairs = /[\uD800-\uDBFF][\uDC00-\uDFFF]/g
  while (surrogatePairs.exec(str) != null) {
    let lastIdx = surrogatePairs.lastIndex
    if (lastIdx - 2 < idx) {
      idx++
    } else {
      break
    }
  }

  if (idx >= end || idx < 0) {
    return ''
  }

  ret += str.charAt(idx)

  if (/[\uD800-\uDBFF]/.test(ret) && /[\uDC00-\uDFFF]/.test(str.charAt(idx + 1))) {
    // Go one further, since one of the "characters" is part of a surrogate pair
    ret += str.charAt(idx + 1)
  }
  return ret
}

/**
 * Given a string, such as a name, it will return the first initial and (if
 * it exists) the last initial
 * @returns Initials, or empty string if an invalid string is provided
 */
function stringToInitials(str: string) {
  if (typeof str !== 'string' || !str || str === '') return
  const initials = str
    // split by separator (space or underscore), lowercase->uppercase, number->letter, or letter->number
    .split(/[ _]|([A-Z][a-z]+)|(\d+)/)
    .filter((s) => s)
    .map((s) => fixedCharAt(s.toUpperCase(), 0))
  if (initials.length > 0) {
    return initials[0] + (initials.length > 1 ? initials[initials.length - 1] : '')
  } else {
    return null
  }
}

interface AutoUserAvatarProps extends Omit<AvatarProps, 'children'> {
  /** Display name to create initials from */
  name: string
  /** MUI sx props to pass onto the `<Avatar>` component */
  sx?: SxProps
}

/**
 * Generates an `Avatar` with automatic coloring and initials from a display name
 * @constructor
 */
const AutoUserAvatar: React.FC<AutoUserAvatarProps> = ({ name, sx, ...otherProps }) => (
  <Avatar
    {...otherProps}
    sx={{
      bgcolor: stringToMaterialColorSx(name),
      color: 'white',
      textShadow: '0 0 2px rgb(0 0 0 / 50%)',
      ...sx
    }}>
    {stringToInitials(name)}
  </Avatar>
)

export default AutoUserAvatar
