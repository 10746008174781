import React, { useState } from 'react'

import {
  Box,
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuItemProps,
  MenuList,
  useMediaQuery,
  useTheme
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { MoreVert as MoreVertIcon } from '@mui/icons-material'

import NavigateOnFinishTransition from './NavigateOnFinishTransition'

/**
 * Dialog actions are objects representing the buttons at the bottom
 * of a dialog
 */
interface DialogAction extends ButtonProps {
  label: string
  loading?: boolean
}

interface OverflowDialogAction extends MenuItemProps {
  label: string
  icon?: React.ReactElement
}

interface SubpageDialogProps {
  /**
   * Store the next route to go to because we have to delay going there
   * until the transition animation is done.
   */
  nextPath?: string
  title: string | React.ReactNode
  /**
   * Because actions need to be able to close the dialog, the state of
   * the dialog being open is tracked by the parent component
   */
  open: boolean
  /** Shows the horizontal spinner bar while loading */
  loading?: boolean
  onClose: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void
  actions: DialogAction[]
  overflowActions?: OverflowDialogAction[]
  /** Children is used as the dialog content */
  children: React.ReactNode
  growFrom?: string
}

const SubpageDialog: React.FC<SubpageDialogProps> = ({
  nextPath,
  title,
  open,
  loading,
  onClose,
  actions,
  overflowActions,
  children,
  growFrom
}) => {
  const theme = useTheme()
  const fullscreen = useMediaQuery(theme.breakpoints.down('sm'))

  const [menuAnchorElement, setMenuAnchorElement] = useState<null | HTMLElement>(null)

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorElement(event.currentTarget)
  }

  const handleMenuClose = (event, onClick) => {
    setMenuAnchorElement(null)
    onClick && onClick(event)
  }
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth={true}
      TransitionComponent={NavigateOnFinishTransition}
      TransitionProps={{ to: nextPath, in: open, growFrom } as unknown}
      closeAfterTransition={true}
      BackdropProps={{ transitionDuration: { enter: 300, exit: 300 } }}
      fullScreen={fullscreen}>
      <DialogTitle variant="h6">{title}</DialogTitle>
      <Box sx={{ height: '4px' }}>{loading && <LinearProgress color="secondary" />}</Box>
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions>
        {overflowActions && (
          <IconButton
            aria-label="group actions"
            onClick={handleMenu}
            id="group-actions"
            sx={{ p: '6px' }}>
            <MoreVertIcon color="inherit" />
          </IconButton>
        )}
        {actions.map(({ label, loading, ...otherProps }) =>
          loading !== undefined ? (
            <LoadingButton key={label} loading={loading} {...otherProps}>
              {label}
            </LoadingButton>
          ) : (
            <Button key={label} {...otherProps}>
              {label}
            </Button>
          )
        )}
      </DialogActions>

      {overflowActions && (
        <Menu
          open={!!menuAnchorElement}
          anchorEl={menuAnchorElement}
          onClose={(e) => handleMenuClose(e, null)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}>
          <MenuList>
            {overflowActions.map(({ label, icon, onClick, color, ...otherProps }) => (
              <MenuItem key={label} onClick={(e) => handleMenuClose(e, onClick)} {...otherProps}>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primaryTypographyProps={{ color }} primary={label} />
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      )}
    </Dialog>
  )
}

export default SubpageDialog
