const chars = '0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ'
const lowerCase = 'abcdefghijklmnopqrstuvwxyz'
const upperCase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
const number = '0123456789'

const passwordLength = 16

const randomNumber = (len) => Math.floor(Math.random() * len)
const randomChar = (charSet) => {
  const random = randomNumber(charSet.length)
  return charSet.substring(random, random + 1)
}

export const generatePassword = () => {
  const pw = new Array(passwordLength - 3)
    .fill('')
    .map((_) => randomChar(chars))
    .join('')

  // ensure one of each of 3 required character types are present
  return pw + randomChar(lowerCase) + randomChar(upperCase) + randomChar(number)
}

export default generatePassword
