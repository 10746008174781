import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useAuth } from 'lib/providers/AuthProvider'
import { isAuthedSso } from './sso'
import { isSsoEnabled } from './env'
import Unauthorized from 'pages/Unauthorized'

const ProtectedRoute = () => {
  const { getLoggedIn } = useAuth()

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page for regular
  // auth or an unauthorized message for SSO.
  if (isSsoEnabled() && !isAuthedSso()) {
    return <Navigate to="/login" />
  } else if (isSsoEnabled() && !getLoggedIn()) {
    return <Unauthorized />
  } else {
    return <Outlet />
  }
}

export default ProtectedRoute
