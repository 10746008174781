import React, { useState } from 'react'

import { ResetAPIKeyRequest, ResetAPIKeyResponse, RESET_API_KEY } from 'lib/graphQlQueries'
import { useMutation } from '@apollo/client'

import { Alert, Box, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useSnackbar } from 'notistack'

import { AutoCollapse } from 'components/AutoTransition'
import { useAuth } from 'lib/providers/AuthProvider'
import { findAndRethrowError } from 'utils/error'
import ErrorMessage from 'components/ErrorMessage'
import { isSsoEnabled } from 'lib/env'
import { clearCookie } from 'lib/cookies'

const ResetApiKey = () => {
  const [error, setError] = useState<object>()
  const [newApiKey, setNewApiKey] = useState<string>()

  const { getLoggedInUsername } = useAuth()
  const { enqueueSnackbar } = useSnackbar()

  const [resetApiKeyMutation, { loading: resetApiKeyLoading, error: resetApiKeyMutationError }] =
    useMutation<ResetAPIKeyResponse, ResetAPIKeyRequest>(RESET_API_KEY)

  const formDisabled = resetApiKeyLoading || !!newApiKey

  const handleResetApiKey = async (_) => {
    try {
      setNewApiKey(null)

      const { data: resetApiKeyData, errors } = await resetApiKeyMutation({
        variables: {
          username: getLoggedInUsername()
        }
      })

      findAndRethrowError(resetApiKeyData)
      findAndRethrowError(resetApiKeyMutationError)
      findAndRethrowError(error)
      if (errors?.[0]) {
        throw errors[0]
      }

      // Save new API key to show user
      setNewApiKey(resetApiKeyData?.ResetAPIKey?.apikey)

      // No errors, close window and show success snackbar
      enqueueSnackbar(`API key reset successfully`, {
        variant: 'success'
      })

      // When SSO is enabled, resetting your own API key is effectively the same
      // thing as logging out.
      //
      // Allow some time for the snackbar to be read before reloading the page
      // to force a re-login
      if (isSsoEnabled()) {
        setTimeout(() => {
          clearCookie('token')
          window.location.reload()
        }, 1000)
      }
    } catch (err) {
      setError(err)
    }
  }

  return (
    <>
      <Typography>
        Resetting your API key will remove access from all your current applications until their
        credentials are updated.
      </Typography>
      <ErrorMessage error={error} sx={{ m: 2 }} canClose />

      <Box display="block" width="fit-content" ml="auto" my={2}>
        <LoadingButton
          variant="contained"
          color="secondary"
          onClick={handleResetApiKey}
          loading={resetApiKeyLoading}
          disabled={formDisabled}>
          Reset API Key
        </LoadingButton>
      </Box>
      <AutoCollapse>
        {newApiKey && (
          <Alert color="success" onClose={() => setNewApiKey(null)}>
            <Typography>Your API key has been reset.</Typography>
            <Typography>
              {'Your new API key is '}
              <Typography variant="monospace">{newApiKey}</Typography>.
            </Typography>
          </Alert>
        )}
      </AutoCollapse>
    </>
  )
}

export default ResetApiKey
