import React, { useState } from 'react'

import {
  SetUserPasswordRequest,
  SetUserPasswordResponse,
  SET_USER_PASSWORD
} from 'lib/graphQlQueries'
import { useMutation } from '@apollo/client'

import { Box, FormHelperTextProps, Grid, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useSnackbar } from 'notistack'
import { Formik, Field } from 'formik'
import { TextField } from 'formik-mui'

import { AutoCollapse } from 'components/AutoTransition'
import { useAuth } from 'lib/providers/AuthProvider'
import { findAndRethrowError } from 'utils/error'
import ErrorMessage from 'components/ErrorMessage'
import { useNavigate } from 'react-router-dom'

import { resetPasswordValidation } from 'utils/validationSchemas'

interface FormValues {
  password: string
  verifyPassword: string
}

const ResetPassword = () => {
  const [error, setError] = useState<object>()

  const { getLoggedInUsername, logout } = useAuth()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  const [
    setUserPasswordMutation,
    { loading: setUserPasswordLoading, error: setUserPasswordMutationError }
  ] = useMutation<SetUserPasswordResponse, SetUserPasswordRequest>(SET_USER_PASSWORD)

  const formDisabled = setUserPasswordLoading

  const handleResetPassword = async (values?: FormValues) => {
    const { password, verifyPassword } = values

    if (password !== verifyPassword) {
      return
    }

    try {
      const { data: setUserPasswordData, errors } = await setUserPasswordMutation({
        variables: {
          username: getLoggedInUsername(),
          password
        }
      })

      findAndRethrowError(setUserPasswordData)
      findAndRethrowError(setUserPasswordMutationError)
      if (errors?.[0]) {
        throw errors[0]
      }

      // No errors, close window and show success snackbar
      enqueueSnackbar(`Password updated successfully`, {
        variant: 'success'
      })

      logout()
      navigate('/login', { replace: true })
    } catch (err) {
      setError(err)
    }
  }

  return (
    <Formik<FormValues>
      initialValues={{
        password: '',
        verifyPassword: ''
      }}
      validationSchema={resetPasswordValidation}
      onSubmit={async (values: FormValues) => await handleResetPassword(values)}>
      {({ values, errors, isSubmitting, handleSubmit }) => {
        const { password, verifyPassword } = values

        return (
          <>
            <Typography>You will need to log in again when you change your password.</Typography>
            <ErrorMessage error={error} sx={{ m: 2 }} canClose />
            <Grid container columnSpacing={2} component="form">
              <input
                type="text"
                readOnly
                style={{ display: 'none' }}
                name="username"
                autoComplete="username"
                value={getLoggedInUsername()}
              />
              <Grid item xs={12} sm={6}>
                <Field
                  component={TextField}
                  required
                  label="Password"
                  name="password"
                  type="password"
                  role="textbox"
                  autoComplete="new-password"
                  margin="dense"
                  error={!!errors?.password && password.length >= 12}
                  disabled={formDisabled}
                  helperText={<AutoCollapse>{errors.password}</AutoCollapse>}
                  FormHelperTextProps={{ component: 'div' } as FormHelperTextProps}
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  component={TextField}
                  required
                  label="Verify password"
                  name="verifyPassword"
                  type="password"
                  role="textbox"
                  autoComplete="new-password"
                  margin="dense"
                  disabled={formDisabled}
                  helperText={<AutoCollapse>{errors.verifyPassword}</AutoCollapse>}
                  FormHelperTextProps={{ error: true, component: 'div' } as FormHelperTextProps}
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={12}>
                <Box display="block" width="fit-content" ml="auto">
                  <LoadingButton
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      handleSubmit()
                    }}
                    loading={isSubmitting}
                    disabled={
                      isSubmitting || !password || !verifyPassword || !!Object.keys(errors).length
                    }>
                    Submit
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </>
        )
      }}
    </Formik>
  )
}

export default ResetPassword
