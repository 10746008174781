import React, { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Collapse, Grid, Typography } from '@mui/material'
import { Formik, Field } from 'formik'
import { TextField } from 'formik-mui'

import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { UPDATE_INGRESS, UpdateIngressRequest, UpdateIngressResponse } from 'lib/graphQlQueries'

import { useIngresses } from 'lib/providers/IngressProvider'
import LgIngressParams from './IngressClasses/lg'
import { Ingress, IngressClass, IngressClassParameters } from 'lib/types/Ingress'
import { findAndRethrowError } from 'utils/error'

import SubpageDialog from 'components/SubpageDialog'
import ErrorMessage from 'components/ErrorMessage'
import C37IngressParams from './IngressClasses/c37'
import SttpIngressParams from './IngressClasses/sttp'
import GepIngressParams from './IngressClasses/gep'

import { editIngressValidation } from 'utils/validationSchemas'

interface FormValues {
  classType: string // not editable in the edit form, but used for classType validation
  prefix: string
  comment: string
  parameters: IngressClassParameters
}

const EditIngress = () => {
  const {
    getIngress,
    refetchAllIngresses,
    getAllIngressesLoading,
    getAllIngressesRefetching,
    getAllIngressesError
  } = useIngresses()
  const params = useParams()
  const { name } = params

  const ingressesLoading = getAllIngressesLoading()
  const ingressesRefetching = getAllIngressesRefetching()
  const ingress = getIngress(name) as Ingress

  // TODO these don't have the right types - presumably from the backend
  /** Everything comes back as a string by default - fix that here before updating Parameters */
  const transformParameters = (parameters) => {
    const _parameters = { ...parameters }
    if (typeof _parameters?.randomized === 'string') {
      _parameters.randomized = _parameters.randomized === 'true'
    }
    if (typeof _parameters?.frequency === 'string') {
      _parameters.frequency = +_parameters.frequency
    }
    if (typeof _parameters?.nstreams === 'string') {
      _parameters.nstreams = +_parameters.nstreams
    }
    if (typeof _parameters?.debug === 'string') {
      _parameters.debug = _parameters.debug === 'true'
    }
    if (typeof _parameters?.configtype === 'string') {
      _parameters.configtype = +_parameters.configtype
    }
    return _parameters
  }

  const { enqueueSnackbar } = useSnackbar()

  const [isOpen, setIsOpen] = useState(true)

  const [
    updateIngressMutation,
    { loading: updateIngressMutationLoading, error: updateIngressMutationError }
  ] = useMutation<UpdateIngressResponse, UpdateIngressRequest>(UPDATE_INGRESS, {
    notifyOnNetworkStatusChange: true
  })

  const [saveError, setSaveError] = useState<Error>()

  const propsChanged = useCallback(
    (values: FormValues) => {
      const { prefix, comment, parameters } = values
      return (
        ingress?.collectionPrefix !== prefix ||
        ingress?.comment !== comment ||
        Object.entries(ingress?.parameters)?.reduce?.(
          (p, [k, parameter]) =>
            p ||
            // Special handling for right now where parameters can be returned as strings
            (typeof parameter === 'string' && typeof parameters?.[k] === 'boolean'
              ? parameters?.[k] !== (parameter === 'true')
              : typeof parameter === 'string' && typeof parameters?.[k] === 'number'
              ? parameters?.[k] !== +parameter
              : parameters?.[k] !== parameter),
          false
        )
      )
    },
    [ingress?.collectionPrefix, ingress?.comment, ingress?.parameters]
  )

  // Disable the form elements while a request is happening
  const formDisabled = updateIngressMutationLoading || ingressesRefetching
  const isFormValid = useCallback(
    (values: FormValues, errors: object | null) => {
      const { prefix, parameters } = values || {}
      return (
        !formDisabled &&
        // Only allow saving if form state changed
        propsChanged(values) &&
        prefix &&
        !Object.keys(errors).length &&
        // lg-specific validation
        ((ingress?.class === IngressClass.lg &&
          (parameters as IngressClassParameters<IngressClass.lg>)?.nstreams > 0 &&
          (parameters as IngressClassParameters<IngressClass.lg>)?.frequency > 0) ||
          // c37-specific validation
          (ingress?.class === IngressClass.c37 &&
            (parameters as IngressClassParameters<IngressClass.c37>)?.target &&
            (parameters as IngressClassParameters<IngressClass.c37>)?.id) ||
          // sttp-specific validation
          (ingress?.class === IngressClass.sttp &&
            (parameters as IngressClassParameters<IngressClass.sttp>)?.target &&
            (parameters as IngressClassParameters<IngressClass.sttp>)?.query) ||
          // gep-specific validation
          (ingress?.class === IngressClass.gep &&
            (parameters as IngressClassParameters<IngressClass.gep>)?.target &&
            (parameters as IngressClassParameters<IngressClass.gep>)?.query))
      )
    },
    [formDisabled, ingress?.class, propsChanged]
  )

  const onClose = useCallback(
    (_event: React.SyntheticEvent, reason?: string, values?: FormValues, errors?: object) => {
      // Don't accidentally close form by clicking background when data has been added
      if (!(reason === 'backdropClick' && isFormValid(values, errors))) {
        // also don't close while waiting for submit response
        if (!formDisabled) {
          setIsOpen(false)
        }
      }
    },
    [formDisabled, isFormValid]
  )

  const editIngress = async (values: FormValues) => {
    // reset displayed error, if any
    setSaveError(null)

    const { prefix, comment, parameters } = values

    try {
      // Create the new ingress
      const { data: updateIngressMutationResponse, errors } = await updateIngressMutation({
        variables: {
          name,
          class: ingress?.class,
          enabled: true,
          collectionPrefix: prefix,
          comment,
          parameters
        } as Ingress
      })
      findAndRethrowError(updateIngressMutationResponse)
      findAndRethrowError(updateIngressMutationError)

      if (errors?.[0]) {
        throw errors[0]
      }

      // update data from server
      await refetchAllIngresses()

      // No errors, close window and show success snackbar
      enqueueSnackbar(`Ingress ${name} saved`, {
        variant: 'success'
      })
      setIsOpen(false)
    } catch (e) {
      setSaveError(e)
    }
  }

  const error = saveError || updateIngressMutationError || getAllIngressesError()

  return (
    <Formik<FormValues>
      enableReinitialize
      initialValues={{
        classType: ingress?.class,
        prefix: ingress?.collectionPrefix ?? '',
        comment: ingress?.comment ?? '',
        parameters: ingress?.parameters
          ? transformParameters(ingress?.parameters)
          : ({} as IngressClassParameters)
      }}
      validationSchema={editIngressValidation}
      onSubmit={async (values) => await editIngress(values)}>
      {({ values, setFieldValue, errors, handleSubmit }) => {
        const { parameters } = values

        return (
          <SubpageDialog
            title={
              <>
                {'Edit ingress: '}
                <Typography component="span" color="text.primaryColor" variant="h6">
                  {name}
                </Typography>
              </>
            }
            nextPath="/admin/ingress"
            loading={ingressesLoading}
            open={isOpen}
            onClose={onClose}
            actions={[
              {
                label: 'Cancel',
                onClick: (event: React.SyntheticEvent, reason?: string) => {
                  onClose(event, reason, values, errors)
                },
                color: 'inherit',
                disabled: formDisabled
              },
              {
                label: 'Save',
                onClick: () => {
                  handleSubmit()
                },
                variant: 'contained',
                loading: formDisabled || ingressesRefetching,
                disabled: !isFormValid(values, errors)
              }
            ]}>
            <Grid container columnSpacing={2}>
              <Grid item xs={12}>
                <ErrorMessage canClose error={error} />
              </Grid>

              <Grid item xs={12}>
                <Field
                  component={TextField}
                  required
                  label="Collection prefix"
                  name="prefix"
                  role="textbox"
                  margin="dense"
                  disabled={formDisabled}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} marginBottom={2}>
                <Field
                  component={TextField}
                  label="Comment"
                  name="comment"
                  role="textbox"
                  margin="dense"
                  disabled={formDisabled}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Collapse in={ingress?.class === IngressClass.lg} unmountOnExit>
                  <LgIngressParams formDisabled={formDisabled} errors={errors} />
                </Collapse>
                <Collapse in={ingress?.class === IngressClass.c37} unmountOnExit>
                  <C37IngressParams
                    formDisabled={formDisabled}
                    params={parameters as IngressClassParameters<IngressClass.c37>}
                    onParamsChange={(params) => {
                      setFieldValue('parameters', params)
                    }}
                  />
                </Collapse>
                <Collapse in={ingress?.class === IngressClass.sttp} unmountOnExit>
                  <SttpIngressParams formDisabled={formDisabled} />
                </Collapse>
                <Collapse in={ingress?.class === IngressClass.gep} unmountOnExit>
                  <GepIngressParams
                    formDisabled={formDisabled}
                    params={parameters as IngressClassParameters<IngressClass.gep>}
                    onParamsChange={(params) => {
                      setFieldValue('parameters', params)
                    }}
                    errors={errors}
                  />
                </Collapse>
              </Grid>
            </Grid>
          </SubpageDialog>
        )
      }}
    </Formik>
  )
}

export default EditIngress
