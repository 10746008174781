import React, { useMemo, useState } from 'react'
import { useParams, useLocation } from 'react-router-dom'

import { Typography, Collapse, List } from '@mui/material'
import { Delete as DeleteIcon } from '@mui/icons-material'

import { useIngresses } from 'lib/providers/IngressProvider'

import SubpageDialog from 'components/SubpageDialog'
import ErrorMessage from 'components/ErrorMessage'
import { Ingress, IngressClass } from 'lib/types/Ingress'
import DetailRow from 'components/DetailRow'

const IngressDetail = () => {
  const { name } = useParams()

  const [isOpen, setIsOpen] = useState(true)

  const [nextPath, setNextPath] = useState('/admin/ingress')

  const { state: locationState } = useLocation()

  const onClose = () => {
    setIsOpen(false)
  }

  const { getIngress, getAllIngressesLoading, getAllIngressesError } = useIngresses()
  const ingressesLoading = getAllIngressesLoading()
  const ingress = getIngress(name) as Ingress

  const ingressAsLg = ingress as Ingress<IngressClass.lg>
  const ingressAsC37 = ingress as Ingress<IngressClass.c37>
  const ingressAsSttp = ingress as Ingress<IngressClass.sttp>
  const ingressAsGep = ingress as Ingress<IngressClass.gep>

  // Show not found message as error if ingress was not found
  const error =
    getAllIngressesError() ??
    (ingress || ingressesLoading ? null : new Error('the ingress was not found.'))

  const navigateTo = (path: string) => {
    setNextPath(path)
    setIsOpen(false)
  }
  const edit = () => navigateTo(`/admin/ingress/edit/${name}`)
  const deleteIngress = () => navigateTo(`/admin/ingress/delete/${name}`)
  const enableIngress = () => navigateTo(`/admin/ingress/enable/${name}`)
  const disableIngress = () => navigateTo(`/admin/ingress/disable/${name}`)

  // Format frequency with commas
  const frequency = useMemo(() => {
    if (ingressAsLg?.parameters?.frequency) {
      return new Intl.NumberFormat().format(ingressAsLg?.parameters?.frequency)
    }
  }, [ingressAsLg?.parameters?.frequency])

  if (!name) {
    navigateTo('/admin/ingress')
  } else
    return (
      <SubpageDialog
        title={
          <>
            {'Ingress '}
            <Typography component="span" color="text.primaryColor" variant="inherit">
              {name}
            </Typography>
          </>
        }
        loading={ingressesLoading}
        open={isOpen}
        onClose={onClose}
        nextPath={nextPath}
        actions={[
          {
            onClick: edit,
            label: 'Edit',
            color: 'inherit'
          },
          {
            onClick: onClose,
            label: 'Close',
            variant: 'contained',
            autoFocus: true
          }
        ]}
        overflowActions={[
          {
            onClick: deleteIngress,
            icon: <DeleteIcon color="warning" />,
            label: 'Delete'
          },
          ingress?.enabled
            ? {
                onClick: disableIngress,
                label: 'Disable ingress'
              }
            : {
                onClick: enableIngress,
                label: 'Enable ingress'
              }
        ]}
        growFrom={(locationState as any)?.growFrom}>
        <Collapse in={!!error}>
          <ErrorMessage error={error} />
        </Collapse>
        {ingress && (
          <>
            <Typography variant="body1">Details</Typography>
            <List>
              <DetailRow primary="Class" secondary={ingress.class} secondaryDefault="(unset)" />
              <DetailRow primary="Enabled" secondary={`${ingress?.enabled ?? ''}`} />
              <DetailRow primary="Comment" secondary={ingress.comment} secondaryDefault="(unset)" />
              <DetailRow
                primary="Collection Prefix"
                secondary={ingress.collectionPrefix}
                secondaryDefault="(unset)"
                secondaryVariant="monospace"
              />

              <Typography variant="body1" sx={{ mt: 4 }}>
                {ingress.class.toUpperCase()} Parameters
              </Typography>
              {/* lg ingress class properties */}
              {ingress.class === IngressClass.lg && (
                <>
                  <DetailRow
                    primary="Number of streams"
                    secondary={ingressAsLg.parameters.nstreams}
                    secondaryDefault="(unset)"
                  />
                  <DetailRow
                    primary="Stream data point frequency"
                    secondary={`${frequency}Hz`}
                    secondaryDefault="(unset)"
                  />
                </>
              )}
              {/* c37 ingress class properties */}
              {ingress.class === IngressClass.c37 && (
                <>
                  <DetailRow
                    primary="Target"
                    secondary={ingressAsC37.parameters.target}
                    secondaryDefault="(unset)"
                    secondaryVariant="monospace"
                  />
                  <DetailRow
                    primary="ID"
                    secondary={ingressAsC37.parameters.id}
                    secondaryDefault="(unset)"
                    secondaryVariant="monospace"
                  />
                  <DetailRow
                    primary="Format"
                    secondary={ingressAsC37.parameters.fmt || 'Default'}
                    secondaryDefault="(unset)"
                    secondaryVariant="monospace"
                  />
                  <DetailRow
                    primary="Revision"
                    secondary={ingressAsC37.parameters.revision}
                    secondaryDefault="(unset)"
                    secondaryVariant="monospace"
                  />
                  <DetailRow
                    primary="Configuration Type"
                    secondary={ingressAsC37.parameters.configtype}
                    secondaryDefault="(unset)"
                  />
                  <DetailRow
                    primary="Mode"
                    secondary={ingressAsC37.parameters.mode}
                    secondaryDefault="(unset)"
                    secondaryVariant="monospace"
                  />
                  <DetailRow
                    primary="Debug"
                    secondary={ingressAsC37.parameters.debug}
                    secondaryDefault="(unset)"
                  />
                </>
              )}
              {/* sttp ingress class properties */}
              {ingress.class === IngressClass.sttp && (
                <>
                  <DetailRow
                    primary="Target"
                    secondary={ingressAsSttp.parameters.target}
                    secondaryDefault="(unset)"
                    secondaryVariant="monospace"
                  />
                  <DetailRow
                    primary="Query"
                    secondary={ingressAsSttp.parameters.query}
                    secondaryDefault="(unset)"
                    secondaryVariant="monospace"
                  />
                </>
              )}
              {/* gep ingress class properties */}
              {ingress.class === IngressClass.gep && (
                <>
                  <DetailRow
                    primary="Target"
                    secondary={ingressAsGep.parameters.target}
                    secondaryDefault="(unset)"
                    secondaryVariant="monospace"
                  />
                  <DetailRow
                    primary="Query"
                    secondary={ingressAsGep.parameters.query}
                    secondaryDefault="(unset)"
                    secondaryVariant="monospace"
                  />
                  <DetailRow
                    primary="Revision"
                    secondary={ingressAsGep.parameters.revision}
                    secondaryDefault="(unset)"
                    secondaryVariant="monospace"
                  />
                  <DetailRow
                    primary="Timestamp rounding"
                    secondary={ingressAsGep.parameters.tsround}
                    secondaryDefault="(unset)"
                    secondaryVariant="monospace"
                  />
                  <DetailRow
                    primary="Per-measurement flags"
                    secondary={ingressAsGep.parameters.flags === 'yes' ? 'Enabled' : 'Disabled'}
                    secondaryDefault="(unset)"
                  />
                </>
              )}
            </List>
          </>
        )}
      </SubpageDialog>
    )
}

export default IngressDetail
