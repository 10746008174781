import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'

import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'

import { Box, Collapse, IconButton, LinearProgress, Tooltip, Typography } from '@mui/material'
import { Formik, Field } from 'formik'
import { CheckboxWithLabel } from 'formik-mui'

import AutocompleteMultiSelectChipInput from 'components/AutocompleteMultiSelectChipInput'
import SubpageDialog from 'components/SubpageDialog'

import ErrorMessage from 'components/ErrorMessage'
import { containSameContents } from 'utils/array'
import { useGroups } from 'lib/providers/GroupsProvider'
import {
  SET_GROUP_CAPABILITIES,
  SetGroupCapabilitiesRequest,
  SetGroupCapabilitiesResponse,
  SetGroupPrefixesRequest,
  SetGroupPrefixesResponse,
  SET_GROUP_PREFIXES
} from 'lib/graphQlQueries'
import { GroupCapability, Group } from 'lib/types/Group'
import { HelpOutline as HelpOutlineIcon } from '@mui/icons-material'

import { groupEditValidation } from 'utils/validationSchemas'

const getAllAvailablePrefixes = (groups: Group[]): string[] => {
  const allPrefixes = new Set<string>()
  for (const group of groups)
    for (const prefix of group.prefixes) {
      allPrefixes.add(prefix)
    }
  return Array.from(allPrefixes)
}

interface FormValues {
  prefixes: string[]
  allPrefixes: boolean
  capabilities: string[]
}

const GroupEdit = () => {
  const { t } = useTranslation()

  const params = useParams()
  const { name } = params

  const { enqueueSnackbar } = useSnackbar()

  // Displayed error message
  const [error, setError] = useState<Error>()

  const {
    getGroup,
    getAllGroups,
    refetchAllGroups,
    getGroupsLoading,
    getGroupsRefetching,
    getError: getGroupsError
  } = useGroups()
  const groupsLoading = getGroupsLoading()

  const groups = getAllGroups() ?? []
  const group = getGroup(name) ?? ({} as Group)

  const groupsError = getGroupsError()
  useEffect(() => setError(groupsError), [groupsError])

  const prefixOptions = getAllAvailablePrefixes(groups)

  const [isOpen, setIsOpen] = useState(true)

  // Check if the prefix and capabilities lists have been changed from the
  // original state of the group
  const prefixesHaveChanged = (prefixes: string[]): boolean =>
    !containSameContents(prefixes, group.prefixes)
  const capabilitiesHaveChanged = (capabilities: string[]): boolean =>
    !containSameContents(capabilities, group.capabilities)

  // setGroupPrefixes mutation function and states
  const [
    setGroupPrefixes,
    { loading: setGroupPrefixesMutationLoading, error: setGroupPrefixesMutationError }
  ] = useMutation<SetGroupPrefixesResponse, SetGroupPrefixesRequest>(SET_GROUP_PREFIXES)

  // setGroupCapabilities mutation function and states
  const [
    setGroupCapabilities,
    { loading: setGroupCapabilitiesMutationLoading, error: setGroupCapabilitiesMutationError }
  ] = useMutation<SetGroupCapabilitiesResponse, SetGroupCapabilitiesRequest>(SET_GROUP_CAPABILITIES)

  // Disable the form elements while a request is happening
  const formDisabled =
    setGroupPrefixesMutationLoading || setGroupCapabilitiesMutationLoading || getGroupsRefetching()

  const onClose = (_event: React.SyntheticEvent, reason?: string, values?: FormValues) => {
    const { prefixes, capabilities } = values

    if (
      reason !== 'backdropClick' ||
      // Don't accidentally close form by clicking background when the user
      // has made changes
      !(
        prefixesHaveChanged(prefixes) ||
        capabilitiesHaveChanged(capabilities) ||
        // also don't close while waiting for submit response
        formDisabled
      )
    ) {
      setIsOpen(false)
    }
  }

  /**
   * Save the group.
   *
   * Sets the group prefixes and capabilities and then
   * re-fetches the list of groups once those are finished.
   *
   * The form elements will be disabled while any graphQL
   * queries mutations are running.
   */
  const save = async (values: FormValues) => {
    // reset displayed error, if any
    setError(null)

    const { prefixes, capabilities } = values

    try {
      // first compare tags to see if any changes need to happen
      if (prefixesHaveChanged(prefixes)) {
        await setGroupPrefixes({
          variables: {
            group: group.name,
            prefixes: prefixes
          }
        })
      }
      if (capabilitiesHaveChanged(capabilities)) {
        await setGroupCapabilities({
          variables: {
            group: group.name,
            capabilities: capabilities as GroupCapability[]
          }
        })
      }

      // update data from server
      if (prefixesHaveChanged(prefixes) || capabilitiesHaveChanged(capabilities)) {
        await refetchAllGroups()
      }

      if (!setGroupPrefixesMutationError && !setGroupCapabilitiesMutationError) {
        // No errors, close window and show success snackbar
        enqueueSnackbar(t('PAGES.GROUPS.EDIT.SNACKBAR_SUCCESS', { name }), {
          variant: 'success'
        })
        setIsOpen(false)
      }
    } catch (e) {
      setError(e)
    }
  }

  return (
    <Formik<FormValues>
      enableReinitialize
      initialValues={{
        // "All prefixes" is the inclusion of '' (empty string), which every stream's
        // collection technically starts with
        allPrefixes: group.prefixes?.includes('') ?? false,
        prefixes: group.prefixes ?? [],
        capabilities: group.capabilities ?? []
      }}
      validationSchema={groupEditValidation}
      onSubmit={async (values) => await save(values)}>
      {({ values, setFieldValue, handleSubmit }) => {
        const { prefixes, capabilities, allPrefixes } = values
        return (
          <SubpageDialog
            title={
              <Trans i18nKey="PAGES.GROUPS.EDIT.TITLE" values={{ name }}>
                ''
                <Typography variant="h6" color="text.primaryColor" component="span">
                  ''
                </Typography>
              </Trans>
            }
            nextPath="/admin/groups"
            open={isOpen}
            onClose={(event: React.SyntheticEvent, reason?: string) =>
              onClose(event, reason, values)
            }
            actions={[
              {
                label: t('PAGES.GROUPS.CREATE.BUTTON_CANCEL'),
                onClick: (event: React.SyntheticEvent, reason?: string) =>
                  onClose(event, reason, values),
                disabled: formDisabled,
                color: 'inherit'
              },
              {
                label: t('PAGES.GROUPS.EDIT.BUTTON_SAVE'),
                onClick: () => handleSubmit(),
                variant: 'contained',
                disabled:
                  groupsLoading ||
                  formDisabled ||
                  !(prefixesHaveChanged || capabilitiesHaveChanged),
                loading: formDisabled
              }
            ]}>
            {groupsLoading ? (
              <LinearProgress color="secondary" />
            ) : (
              <>
                <Collapse in={!!error}>
                  <ErrorMessage error={error} />
                </Collapse>
                <Box display="flex">
                  <Field
                    component={CheckboxWithLabel}
                    name="allPrefixes"
                    type="checkbox"
                    onClick={(event) => {
                      const checked = event.target.checked
                      setFieldValue('allPrefixes', checked)
                      if (checked) {
                        if (!prefixes.includes('')) {
                          setFieldValue('prefixes', [...prefixes, ''])
                        }
                      } else {
                        setFieldValue(
                          'prefixes',
                          prefixes.filter((prefix) => prefix !== '')
                        )
                      }
                    }}
                    Label={{ label: t('PAGES.GROUPS.CREATE.ACCESS_ALL_STREAMS_LABEL') }}
                    sx={{ flex: 1 }}
                  />
                  <Tooltip
                    title={t('PAGES.GROUPS.CREATE.ACCESS_ALL_STREAMS_DESCRIPTION')}
                    placement="left">
                    <IconButton>
                      <HelpOutlineIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Collapse in={!allPrefixes}>
                  <AutocompleteMultiSelectChipInput
                    value={prefixes}
                    onChange={(selectedPrefixes) => setFieldValue('prefixes', selectedPrefixes)}
                    entries={prefixOptions}
                    hideValues={['']}
                    allowCustom={true}
                    label={t('PAGES.GROUPS.CREATE.PREFIXES_LABEL')}
                    placeholder={t('PAGES.GROUPS.CREATE.PREFIXES_PLACEHOLDER')}
                    autoFocus={true}
                    disabled={formDisabled}
                  />
                </Collapse>
                <AutocompleteMultiSelectChipInput
                  value={capabilities}
                  onChange={(selectedCapabilities) =>
                    setFieldValue('capabilities', selectedCapabilities)
                  }
                  entries={Object.keys(GroupCapability)}
                  allowCustom={false}
                  label={t('PAGES.GROUPS.CREATE.CAPABILITIES_LABEL')}
                  placeholder={t('PAGES.GROUPS.CREATE.CAPABILITIES_PLACEHOLDER')}
                  disabled={formDisabled}
                />
              </>
            )}
          </SubpageDialog>
        )
      }}
    </Formik>
  )
}

export default GroupEdit
