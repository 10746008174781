import React, { useMemo, useState } from 'react'

import {
  Box,
  IconButton,
  LinearProgress,
  ListItemIcon,
  ListItemProps,
  ListProps,
  Menu,
  MenuItem,
  Toolbar,
  Typography
} from '@mui/material'
import {
  Sort as SortIcon,
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon
} from '@mui/icons-material'

import { FixedSizeList as List, ListChildComponentProps } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'

import ErrorMessage from 'components/ErrorMessage'
import ToolbarSearchInput from './ToolbarSearchInput'

export type SortMenuEntry<T> = {
  label: string
  sort: (a: T, b: T) => number
}

export interface FilterableListItemProps<T> extends ListItemProps {
  item: T
}

interface FilterableListProps<T> extends ListProps {
  title: string
  loading: boolean
  refetching: boolean
  error: Error
  /** Entries to sort and render */
  data: T[]
  /**
   * A list of sort functions and associated labels for the sort menu to offer
   */
  sorts: SortMenuEntry<T>[]
  /**
   * A list of callbacks to check if each respective field matches a
   * search string.
   */
  filter: Record<string, (item: T, filter: string) => boolean>
  /**
   * Tooltip to show in filter textfield
   */
  filterTooltip?: string | React.ReactNode
  itemHeight: number
  /** Element with which to render each item in the data */
  renderItem?: (item: T, index?: number) => React.ReactNode
}

/**
 * Button with dropdown menu to sort ascending or descending based
 * on the selected sort type
 */
const FilterableList = <T,>({
  title,
  loading,
  refetching,
  error,
  data,
  sorts,
  filter,
  filterTooltip,
  itemHeight,
  renderItem
}: FilterableListProps<T>): React.ReactElement<T> => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [asc, setAsc] = useState(true)
  const [sort, setSort] = useState(sorts?.[0])
  const [filterText, setFilterText] = useState('')

  const sortedFilteredItems = useMemo(() => {
    const keywords = filterText.split(' ').filter((s) => s.trim() !== '')

    let filteredData: Array<T>

    if (filterText === '') {
      filteredData = [...data]
    } else {
      filteredData = data.filter((item) => {
        // split search by each word
        for (const keyword of keywords) {
          const splitData = keyword.split(':')
          // check for existence of named search queries
          if (splitData.length > 1) {
            const [filterKey, filterValue] = splitData
            return filter[filterKey]?.(item, filterValue)
          } else {
            // else search all queries
            for (const _filter of Object.values(filter)) {
              if (_filter?.(item, keyword)) {
                return true
              }
            }
          }
        }
        return false
      })
    }

    const sortedData = filteredData?.sort?.(sort.sort)
    if (!asc) {
      sortedData.reverse()
    }
    return sortedData
  }, [data, sort, asc, filter, filterText])

  // Set sort method, setting ascending to 'true' if the sort type is new or
  // flipping the ascending value otherwise
  const handleChange = (newSort: SortMenuEntry<T>) => {
    const ascending = sort.label === newSort.label ? !asc : true
    setAsc(ascending)
    setSort(newSort)
    // onChange(newSort, ascending)
    handleClose()
  }

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  // Dani: done as described here: https://mui.com/material-ui/react-list/
  const renderRow = (props: ListChildComponentProps) => {
    const { index, style } = props
    return <Box style={{ ...style, textOverflow: 'ellipsis' }}>{props.data[index]}</Box>
  }

  return (
    <>
      <Toolbar>
        <Typography variant="h5" flex="1">
          {title}
        </Typography>
        <ToolbarSearchInput
          onChange={setFilterText}
          tooltip={filterTooltip}
          sx={{ width: '20ch' }}
        />
        <IconButton aria-label="sort groups" onClick={handleMenu} id="sort-groups-button">
          <SortIcon />
        </IconButton>
        <Menu
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}>
          {sorts.map((_sort) => (
            <MenuItem onClick={() => handleChange(_sort)} key={_sort.label}>
              <ListItemIcon>
                {sort.label === _sort.label && (asc ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />)}
              </ListItemIcon>
              {_sort.label}
            </MenuItem>
          ))}
        </Menu>
      </Toolbar>
      {loading || refetching ? (
        <LinearProgress color="secondary" />
      ) : (
        <Box sx={{ height: '4px' }} />
      )}
      <ErrorMessage error={error} sx={{ mx: 2 }} />
      <AutoSizer>
        {({ height, width }) => (
          <List
            height={height - 70}
            width={width}
            itemSize={itemHeight}
            itemCount={sortedFilteredItems.length}
            overscanCount={5}
            itemData={sortedFilteredItems.map(renderItem)}>
            {renderRow}
          </List>
        )}
      </AutoSizer>
    </>
  )
}

export default FilterableList
