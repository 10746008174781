import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import {
  Box,
  Icon,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from '@mui/material'
import { Launch as LaunchIcon } from '@mui/icons-material'

type YarnLicense = {
  Name: string
  Version: string
  License: string
  URL?: string
  VendorUrl?: string
  VendorName?: string
}

/**
 * Yarn repo URLs come in a few different formats, notably the direct git:// url and the regular
 * HTTP Github page. We always want to return the regular Github page, or return nothing if the
 * URL passed in was empty.
 */
function getGitUrl(): void
function getGitUrl(url: string): string

function getGitUrl(url?: string): string | void {
  // check against both null/undefined and empty string:
  if (!url) return

  const location = `https://${url.split('//')[1] ?? url}`
  const path = location.split('.git')[0] ?? location

  return path
}

// Single row in the licenses list
interface LicenseRowProps {
  name: string
  license: string
  url?: string
  vendorUrl?: string
  vendorName?: string
}
const LicenseRow: React.FC<LicenseRowProps> = ({ name, license, url, vendorUrl, vendorName }) => (
  <ListItem key={name} sx={{ px: 6 }}>
    <ListItemText
      primary={
        <Typography component="span" variant="monospace">
          {name}
        </Typography>
      }
      secondary={
        <>
          {!!vendorName && vendorName !== 'Unknown' ? (
            !!vendorUrl && vendorUrl !== 'Unknown' ? (
              <Trans i18nKey="SHARED.COMPONENTS.LICENSES.AUTHOR_WITH_LINK" values={{ vendorName }}>
                ''
                <Link href={vendorUrl}>''</Link>
              </Trans>
            ) : (
              <Trans
                i18nKey="SHARED.COMPONENTS.LICENSES.AUTHOR_WITHOUT_LINK"
                values={{ vendorName }}>
                ''
              </Trans>
            )
          ) : null}{' '}
          <Trans i18nKey="SHARED.COMPONENTS.LICENSES.LICENSE" values={{ license }} />
        </>
      }
    />
    {!!url && url !== 'Unknown' && (
      <ListItemSecondaryAction>
        <IconButton href={getGitUrl(url)} target="_blank">
          <Icon>
            <LaunchIcon />
          </Icon>
        </IconButton>
      </ListItemSecondaryAction>
    )}
  </ListItem>
)

interface LicensesProps {
  licenses: YarnLicense[]
}
const Licenses: React.FC<LicensesProps> = ({ licenses }) => {
  const { t } = useTranslation()

  return (
    // Overflow to allow scrolling in parent container
    <Box overflow="auto">
      {/* 
      To allow the scrollbar in the list to align itself to the right edge, we manually
      add in padding to the other elements:
    */}
      <Box px={4}>
        <Typography>{t('SHARED.COMPONENTS.LICENSES.INTRO_LINE')}</Typography>
      </Box>
      <List>
        {licenses.map(({ Name, VendorName, License, URL, VendorUrl }) => (
          <LicenseRow
            key={Name}
            name={Name}
            license={License}
            url={URL}
            vendorName={VendorName}
            vendorUrl={VendorUrl}
          />
        ))}
      </List>
      <Box px={4}>
        <Typography variant="body2">
          <Trans i18nKey="SHARED.COMPONENTS.LICENSES.FULL_DISCLAIMER_LINK">
            ''
            <Link href="/licenses.txt">''</Link>
          </Trans>
        </Typography>
      </Box>
    </Box>
  )
}

export default React.memo(Licenses)
