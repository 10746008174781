import React from 'react'

import { FormHelperTextProps, Grid, InputAdornment, Tooltip } from '@mui/material'
import { Field } from 'formik'
import { TextField, CheckboxWithLabel } from 'formik-mui'

import { IngressClass, IngressClassParameters } from 'lib/types/Ingress'
import { InfoOutlined as InfoOutlinedIcon } from '@mui/icons-material'
import { AutoCollapse } from 'components/AutoTransition'

const defaultParams = {
  target: '',
  query: '',
  revision: '',
  flags: 'no',
  tsround: ''
}

interface GepIngressParamsProps {
  formDisabled: boolean
  params?: IngressClassParameters<IngressClass.gep>
  onParamsChange: (newParams: IngressClassParameters<IngressClass.gep>) => void
  errors: any
}
/**
 * This page is the extra parameters specific to gep ingresses.
 * They include:
 *  - target: The target IP address
 *  - query: The GEP query to perform. Defaults to all if not provided.
 *  - revision: Revision ID
 *  - flags: Enable per-measurement flags
 *  - tsround: Round timestamps to a multiple of this many milliseconds
 */
const GepIngressParams = ({
  formDisabled,
  params,
  onParamsChange,
  errors
}: GepIngressParamsProps) => {
  const onFlagsEdit = (event) =>
    onParamsChange({ ...params, flags: event.target.checked ? 'yes' : 'no' })

  return (
    <Grid container columnSpacing={2}>
      <Grid item xs={12} md={6}>
        <Field
          required
          component={TextField}
          label="Target"
          name="parameters.target"
          role="textbox"
          margin="dense"
          disabled={formDisabled}
          type="text"
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title="The target IP address" placement="left">
                  <InfoOutlinedIcon />
                </Tooltip>
              </InputAdornment>
            )
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field
          required
          component={TextField}
          label="Query"
          name="parameters.query"
          role="textbox"
          margin="dense"
          disabled={formDisabled}
          type="text"
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip
                  title='The GEP query to perform. Defaults to "all" if not provided.'
                  placement="left">
                  <InfoOutlinedIcon />
                </Tooltip>
              </InputAdornment>
            )
          }}
        />
      </Grid>

      <Grid item xs={12} md={6} marginTop={2}>
        <Field
          component={TextField}
          label="Timestamp rounding"
          name="parameters.tsround"
          role="textbox"
          margin="dense"
          disabled={formDisabled}
          type="number"
          fullWidth
          error={!!errors.tsround}
          helperText={<AutoCollapse>{errors?.tsround}</AutoCollapse>}
          FormHelperTextProps={{ error: true, component: 'div' } as FormHelperTextProps}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip
                  title="Round timestamps to a multiple of this many milliseconds"
                  placement="left">
                  <InfoOutlinedIcon />
                </Tooltip>
              </InputAdornment>
            )
          }}
        />
      </Grid>

      <Grid item xs={12} md={6} marginTop={2}>
        <Field
          component={TextField}
          label="Revision"
          name="parameters.revision"
          role="textbox"
          margin="dense"
          disabled={formDisabled}
          type="text"
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title="The stream identification revision" placement="left">
                  <InfoOutlinedIcon />
                </Tooltip>
              </InputAdornment>
            )
          }}
        />
      </Grid>

      <Grid item xs={12} marginTop={2}>
        <Field
          component={CheckboxWithLabel}
          type={'checkbox'}
          name="parameters.flags"
          checked={params?.flags === 'yes'}
          onChange={onFlagsEdit}
          Label={{ label: 'Enable per-measurement flags' }}
        />
      </Grid>
    </Grid>
  )
}

export default GepIngressParams
export { defaultParams }
