export const SUPPORT_EMAIL_ADDRESS = 'support@pingthings.io'
export const SUPPORT_NAME = 'Support Team'

// This is currently hardcoded to English as the Admin's language settings should
// not have an impact on the new user's email language.
export const resetCredentialEmailBody = (
  username: string,
  credentials: { password?: string; apikey?: string }
) => {
  if (!credentials?.apikey && !credentials?.password) {
    throw new Error('At least one credential must be provided to the credential reset email')
  }
  return `Hello,

At your administrator's request, we have reset the following credentials for ${username}.

The new credentials are:

username: ${username}
${[
  ...[
    credentials.password &&
      `password: ${credentials.password}
`
  ],
  ...[
    credentials.apikey &&
      `API key: ${credentials.apikey}
`
  ]
].join('')}

Please let us know if you have any questions or are having any trouble accessing the data. Please contact us if you would like assistance setting this up.

${SUPPORT_NAME}
PingThings, Inc.
${SUPPORT_EMAIL_ADDRESS}
https://www.pingthings.io/`
}
