// prettier-ignore
const colors = [
  '#6a3446', '#6c3531', '#69391c', '#604008', '#524806',
  '#3e4f1a', '#235430', '#005545', '#005459', '#005068',
  '#224a71', '#3b4372', '#4e3d6c', '#5d385f', '#67344e',
  '#884f61', '#8b4f54', '#8b5146', '#885539', '#83592f',
  '#7b5e29', '#716328', '#64682e', '#566c38', '#466f45',
  '#357153', '#237261', '#16716f', '#16707b', '#236d85',
  '#34698c', '#446590', '#536091', '#615c8e', '#6d5788',
  '#785480', '#805175', '#864f68', '#a86c7e', '#aa6c74',
  '#ab6d6a', '#ab6e61', '#a97158', '#a57350', '#a1774b',
  '#9b7a47', '#947e45', '#8b8147', '#82854b', '#788851',
  '#6d8b59', '#628d62', '#578f6c', '#4d9076', '#449080',
  '#3e908a', '#3b8f93', '#3d8d9b', '#438ba3', '#4b88a9',
  '#5585ad', '#6082b0', '#6a7fb1', '#757cb0', '#7e78ad',
  '#8775a9', '#9073a4', '#97709d', '#9d6e95', '#a36d8c',
  '#a76c82'
]

/**
 * Generates a color hex code based on a given string
 *
 * @param {string} s - input string
 * @returns {string} hex color
 */
export const stringToMaterialColor = (s: string) => {
  if (!s || s === '') return '#000000'

  let hash = 0

  for (let i = 0; i < s.length; i += 1) {
    hash = (hash + s.charCodeAt(i) * 2937) % 4927
  }

  return colors[hash % colors.length]
}

const colorOverrideMap = {
  API: '#2d8219',
  INSERT: '#19649c',
  PLOTTER: '#5d20b2',
  ADMIN: '#aa15a4',
  OBLITERATE: '#ac530d',
  DELETE: '#a11313',
  READ: '#158677'
}

/**
 * Creates a background color and contrast text property from a string
 * that can be passed into the `sx` property of an MUI component.
 *
 * @param {string} s - input string
 *
 * @example ```jsx
 *   <Chip
 *   (...)
 *   sx={{
 *     ...stringToMaterialColorSx(caption),
 *     marginX: '2px'
 *   }} />
 * ```
 */
export const stringToMaterialColorSx = (s: string): string => {
  if (!s) return ''
  if (Object.keys(colorOverrideMap).includes(s)) {
    return colorOverrideMap[s]
  }

  return stringToMaterialColor(s)
}
