import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Outlet } from 'react-router-dom'

import { Box, useMediaQuery, useTheme, Paper } from '@mui/material'

import Navbar from './Navbar'
import SideNav from './SideNav'

interface ShellProps {
  children?: React.ReactNode
}
const Shell: React.FC<ShellProps> = ({ children }) => {
  const theme = useTheme()

  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'))
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('sm'))

  const screenSize = isLargeScreen ? 'lg' : isMediumScreen ? 'md' : 'sm'

  const [sidenavManuallyExpanded, setSidenavManuallyExpanded] = useState(false)

  let navbarBaseWidth: number
  if (isLargeScreen) {
    navbarBaseWidth = 200
  } else if (isMediumScreen) {
    navbarBaseWidth = 62
  } else {
    navbarBaseWidth = 0
  }
  const navbarWidth = sidenavManuallyExpanded ? 240 : navbarBaseWidth

  const handleSidenavExpandButtonClick = useCallback(() => {
    setSidenavManuallyExpanded(!sidenavManuallyExpanded)
  }, [sidenavManuallyExpanded])
  const handleSidenavClose = useCallback(() => {
    setSidenavManuallyExpanded(false)
  }, [])

  useEffect(() => setSidenavManuallyExpanded(false), [isLargeScreen])

  const drawerMarginTransitionOpen = useMemo(
    () =>
      theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
    [theme.transitions]
  )
  const drawerMarginTransitionClose = useMemo(
    () =>
      theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
    [theme.transitions]
  )

  return (
    <>
      <Navbar showToggle={!isLargeScreen} onToggle={handleSidenavExpandButtonClick} />
      <Paper
        square
        sx={{
          overflow: 'auto',
          display: 'flex',
          width: '100%',
          height: '100%',
          position: 'relative',
          backgroundColor: theme.palette.background.default
        }}>
        <Box sx={{ display: 'flex', width: '100%' }}>
          <SideNav
            expanded={isLargeScreen}
            screenSize={screenSize}
            manuallyExpanded={sidenavManuallyExpanded}
            width={navbarWidth}
            onClose={handleSidenavClose}
          />
          <Box
            component="main"
            sx={{
              ml: `${navbarBaseWidth}px`,
              flexGrow: 1,
              overflowY: 'auto',
              transition: isLargeScreen ? drawerMarginTransitionOpen : drawerMarginTransitionClose
            }}>
            {children ?? <Outlet />}
          </Box>
        </Box>
      </Paper>
    </>
  )
}

export default Shell
