import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Collapse, IconButton, Tooltip } from '@mui/material'
import { HelpOutline as HelpOutlineIcon } from '@mui/icons-material'

import { Formik, Field } from 'formik'
import { TextField, CheckboxWithLabel } from 'formik-mui'
import { useSnackbar } from 'notistack'

import { useMutation } from '@apollo/client'
import {
  ADD_GROUP,
  AddGroupRequest,
  AddGroupResponse,
  SET_GROUP_CAPABILITIES,
  SetGroupCapabilitiesRequest,
  SetGroupCapabilitiesResponse,
  SetGroupPrefixesRequest,
  SetGroupPrefixesResponse,
  SET_GROUP_PREFIXES
} from 'lib/graphQlQueries'

import AutocompleteMultiSelectChipInput from 'components/AutocompleteMultiSelectChipInput'
import SubpageDialog from 'components/SubpageDialog'
import { AutoCollapse } from 'components/AutoTransition'
import { useGroups } from 'lib/providers/GroupsProvider'
import { Group, GroupCapability } from 'lib/types/Group'

import { findAndRethrowError } from 'utils/error'
import ErrorMessage from 'components/ErrorMessage'

import { groupCreateValidation } from 'utils/validationSchemas'

const getAllAvailablePrefixes = (groups: Group[]): string[] => {
  const allPrefixes = new Set<string>()
  for (const group of groups)
    for (const prefix of group.prefixes) {
      allPrefixes.add(prefix)
    }
  return Array.from(allPrefixes)
}

interface FormValues {
  name: string
  prefixes: string[]
  allPrefixes: boolean
  capabilities: string[]
}

const GroupCreate = () => {
  const { t } = useTranslation()

  const { enqueueSnackbar } = useSnackbar()

  const { getAllGroups, refetchAllGroups, getGroupsLoading, getGroupsRefetching } = useGroups()
  const groupsLoading = getGroupsLoading()

  const groups = getAllGroups() ?? []

  const prefixOptions = getAllAvailablePrefixes(groups)

  const [isOpen, setIsOpen] = useState(true)

  const [addGroupMutation, { loading: addGroupMutationLoading, error: addGroupMutationError }] =
    useMutation<AddGroupResponse, AddGroupRequest>(ADD_GROUP, {
      notifyOnNetworkStatusChange: true
    })
  const [
    setGroupPrefixesMutation,
    { loading: setGroupPrefixesMutationLoading, error: setGroupPrefixesMutationError }
  ] = useMutation<SetGroupPrefixesResponse, SetGroupPrefixesRequest>(SET_GROUP_PREFIXES)
  const [
    setGroupCapabilitiesMutation,
    { loading: setGroupCapabilitiesMutationLoading, error: setGroupCapabilitiesMutationError }
  ] = useMutation<SetGroupCapabilitiesResponse, SetGroupCapabilitiesRequest>(SET_GROUP_CAPABILITIES)

  const [saveError, setSaveError] = useState<Error>()

  // Disable the form elements while a request is happening
  const formDisabled =
    addGroupMutationLoading ||
    setGroupPrefixesMutationLoading ||
    setGroupCapabilitiesMutationLoading ||
    getGroupsRefetching()

  const onClose = (_event: React.SyntheticEvent, reason?: string) => {
    // Don't accidentaly close form by clicking background
    if (reason !== 'backdropClick') {
      // also don't close while waiting for submit response
      if (!formDisabled) {
        setIsOpen(false)
      }
    }
  }

  const create = async (values: FormValues) => {
    // reset displayed error, if any
    setSaveError(null)

    const { name, prefixes, capabilities } = values

    try {
      // Create the new group
      const { data: addGroupMutationResponse } = await addGroupMutation({
        variables: {
          name
        }
      })
      findAndRethrowError(addGroupMutationResponse)

      // Set any prefixes or capabilities
      if (prefixes) {
        const { data: setGroupPrefixesMutationResponse } = await setGroupPrefixesMutation({
          variables: {
            group: name,
            prefixes: prefixes
          }
        })
        findAndRethrowError(setGroupPrefixesMutationResponse)
      }
      if (capabilities) {
        const { data: setGroupCapabilitiesMutationResponse } = await setGroupCapabilitiesMutation({
          variables: {
            group: name,
            capabilities: capabilities as GroupCapability[]
          }
        })
        findAndRethrowError(setGroupCapabilitiesMutationResponse)
      }

      // update data from server
      await refetchAllGroups()

      // No errors, close window and show success snackbar
      enqueueSnackbar(t('PAGES.GROUPS.CREATE.SNACKBAR_SUCCESS', { name }), {
        variant: 'success'
      })
      setIsOpen(false)
    } catch (e) {
      setSaveError(e)
    }
  }

  const error =
    saveError ||
    addGroupMutationError ||
    setGroupCapabilitiesMutationError ||
    setGroupPrefixesMutationError

  return (
    <Formik<FormValues>
      initialValues={{
        name: '',
        allPrefixes: false,
        prefixes: [],
        capabilities: []
      }}
      validationSchema={groupCreateValidation}
      onSubmit={async (values) => await create(values)}>
      {({ values, setFieldValue, errors, handleSubmit }) => {
        const { name, prefixes, capabilities } = values

        return (
          <SubpageDialog
            title={t('PAGES.GROUPS.CREATE.TITLE')}
            nextPath="/admin/groups"
            loading={groupsLoading}
            open={isOpen}
            onClose={onClose}
            actions={[
              {
                label: t('PAGES.GROUPS.CREATE.BUTTON_CANCEL'),
                onClick: onClose,
                color: 'inherit',
                disabled: formDisabled
              },
              {
                label: t('PAGES.GROUPS.CREATE.BUTTON_CREATE'),
                onClick: () => handleSubmit(),
                variant: 'contained',
                loading: formDisabled,
                disabled: formDisabled || name === '' || !!errors.name
              }
            ]}>
            <Collapse in={!!error}>
              <ErrorMessage error={error} sx={{ mb: '16px' }} />
            </Collapse>
            <Field
              component={TextField}
              required
              autoFocus
              label={t('PAGES.GROUPS.CREATE.GROUP_NAME')}
              name="name"
              role="textbox"
              margin="dense"
              disabled={formDisabled}
              helperText={<AutoCollapse>{errors.name}</AutoCollapse>}
            />
            <Box display="flex" mt={2}>
              <Field
                component={CheckboxWithLabel}
                name="allPrefixes"
                type="checkbox"
                onClick={(event) => {
                  const checked = event.target.checked
                  setFieldValue('allPrefixes', checked)
                  if (checked) {
                    if (!prefixes.includes('')) {
                      setFieldValue('prefixes', [...prefixes, ''])
                    }
                  } else {
                    setFieldValue(
                      'prefixes',
                      prefixes.filter((prefix) => prefix !== '')
                    )
                  }
                }}
                Label={{ label: t('PAGES.GROUPS.CREATE.ACCESS_ALL_STREAMS_LABEL') }}
                sx={{ flex: 1 }}
              />
              <Tooltip
                title={t('PAGES.GROUPS.CREATE.ACCESS_ALL_STREAMS_DESCRIPTION')}
                placement="left">
                <IconButton>
                  <HelpOutlineIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Collapse in={!values.allPrefixes}>
              <AutocompleteMultiSelectChipInput
                value={prefixes}
                onChange={(selectedPrefixes) => setFieldValue('prefixes', selectedPrefixes)}
                entries={prefixOptions}
                hideValues={['']}
                allowCustom={true}
                label={t('PAGES.GROUPS.CREATE.PREFIXES_LABEL')}
                placeholder={t('PAGES.GROUPS.CREATE.PREFIXES_PLACEHOLDER')}
                disabled={formDisabled}
              />
            </Collapse>
            <AutocompleteMultiSelectChipInput
              value={capabilities}
              onChange={(selectedCapabilities) =>
                setFieldValue('capabilities', selectedCapabilities)
              }
              entries={Object.keys(GroupCapability)}
              allowCustom={false}
              label={t('PAGES.GROUPS.CREATE.CAPABILITIES_LABEL')}
              placeholder={t('PAGES.GROUPS.CREATE.CAPABILITIES_PLACEHOLDER')}
              disabled={formDisabled}
            />
          </SubpageDialog>
        )
      }}
    </Formik>
  )
}

export default GroupCreate
