import React, { useEffect, useState } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'

import { Typography, Collapse } from '@mui/material'
import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'

import { findAndRethrowError } from 'utils/error'
import ErrorMessage from 'components/ErrorMessage'
import { DELETE_USER, DeleteUserRequest, DeleteUserResponse } from 'lib/graphQlQueries'
import { useUsers } from 'lib/providers/UsersProvider'
import SubpageDialog from 'components/SubpageDialog'

const UserDelete = () => {
  const { t } = useTranslation()

  const { name } = useParams()

  const { enqueueSnackbar } = useSnackbar()

  const [error, setError] = useState<Error>()

  const [isOpen, setIsOpen] = useState(true)

  const {
    refetchAllUsers,
    getUsersLoading,
    getUsersRefetching,
    getError: getUsersError
  } = useUsers()

  const usersLoading = getUsersLoading()
  const usersError = getUsersError()
  useEffect(() => setError(usersError), [usersError])

  const [deleteUser, { loading: deleteUserLoading, error: deleteUserError }] = useMutation<
    DeleteUserResponse,
    DeleteUserRequest
  >(DELETE_USER, {
    variables: {
      username: name
    }
  })

  // Disable the form elements while a request is happening
  const formDisabled = usersLoading || deleteUserLoading || getUsersRefetching()

  const onClose = () => {
    setIsOpen(false)
  }

  const confirmDelete = async (_event) => {
    // reset displayed error, if any
    setError(null)

    try {
      const { data } = await deleteUser()
      findAndRethrowError(data)
      findAndRethrowError(deleteUserError)

      await refetchAllUsers()

      // No errors, close window and show success snackbar
      enqueueSnackbar(t('PAGES.USERS.DELETE.SNACKBAR_SUCCESS', { name }), {
        variant: 'success'
      })
      setIsOpen(false)
    } catch (err) {
      setError(err)
    }
  }

  if (!name) {
    return <Navigate to="/admin/users" />
  } else
    return (
      <SubpageDialog
        title={
          <Trans i18nKey="PAGES.USERS.DELETE.TITLE" values={{ name }}>
            ''
            <Typography variant="h6" color="text.primaryColor" component="span">
              ''
            </Typography>
          </Trans>
        }
        loading={usersLoading}
        open={isOpen}
        onClose={onClose}
        nextPath={'/admin/users'}
        actions={[
          {
            label: t('PAGES.USERS.DELETE.BUTTON_CANCEL'),
            onClick: onClose,
            color: 'inherit',
            disabled: formDisabled,
            autoFocus: true
          },
          {
            label: t('PAGES.USERS.DELETE.BUTTON_DELETE'),
            onClick: confirmDelete,
            variant: 'contained',
            color: 'error',
            disabled: formDisabled,
            loading: formDisabled
          }
        ]}>
        <Collapse in={!!error}>
          <ErrorMessage error={error} />
        </Collapse>
        <Typography gutterBottom>{t('PAGES.USERS.DELETE.BODY_1')}</Typography>
        <Typography gutterBottom>
          <Trans i18nKey="PAGES.USERS.DELETE.BODY_2" values={{ name }}>
            ''
            <Typography variant="body1" color="text.primaryColor" component="span" key="type">
              ''
            </Typography>
          </Trans>
        </Typography>
      </SubpageDialog>
    )
}

export default UserDelete
