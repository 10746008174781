import { retrieveCookie } from './cookies'
import { isSsoEnabled } from './env'

export function getSsoToken() {
  return retrieveCookie('token')
}

export function isAuthedSso() {
  return isSsoEnabled() && !!getSsoToken()
}
