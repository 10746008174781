import React, { useState } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'

import { Typography, List, Collapse, Button, Box, Skeleton } from '@mui/material'
import { Delete as DeleteIcon } from '@mui/icons-material'

import { formatDistance } from 'date-fns'

import MiniColoredChipList from 'components/MiniColoredChipList'
import { useUsers } from 'lib/providers/UsersProvider'
import SubpageDialog from 'components/SubpageDialog'
import ErrorMessage from 'components/ErrorMessage'
import { GetAPIKeyRequest, GetAPIKeyResponse, GET_API_KEY } from 'lib/graphQlQueries'
import { useLazyQuery } from '@apollo/client'
import DetailRow from 'components/DetailRow'
import { isSsoEnabled } from 'lib/env'

interface ViewUserApiKeyProps {
  name: string
}
const ViewUserApiKey: React.FC<ViewUserApiKeyProps> = ({ name }) => {
  const { t } = useTranslation()

  const [visible, setVisible] = useState(false)

  const [getAPIKey, { data, loading, error }] = useLazyQuery<GetAPIKeyResponse, GetAPIKeyRequest>(
    GET_API_KEY,
    {
      variables: {
        username: name
      }
    }
  )

  const showKey = () => {
    getAPIKey()
    setVisible(true)
  }

  if (loading) {
    return <Skeleton variant="text" width={200} />
  } else if (error) {
    return <ErrorMessage error={error} sx={{ mb: '16px' }} />
  } else if (visible) {
    return (
      <Box sx={{ p: 1 }}>
        <Typography variant="monospace">{data?.GetAPIKey?.apikey}</Typography>
      </Box>
    )
  } else {
    return (
      <Button color="primaryText" onClick={showKey}>
        {t('PAGES.USERS.DETAIL.BUTTON_SHOW_API_KEY')}
      </Button>
    )
  }
}

const UserDetail = () => {
  const { t } = useTranslation()

  const { name } = useParams()

  const [isOpen, setIsOpen] = useState(true)

  const [nextPath, setNextPath] = useState('/admin/users')

  const { state: locationState } = useLocation()

  const onClose = () => {
    setIsOpen(false)
  }

  const { getUser, getUsersLoading, getError } = useUsers()
  const usersLoading = getUsersLoading()
  const user = getUser(name)
  // Show not found message as error if user was not found
  const error =
    getError() ?? (user || usersLoading ? null : new Error(t('PAGES.USERS.DETAIL.NOT_FOUND')))

  const navigateTo = (path: string) => {
    setNextPath(path)
    setIsOpen(false)
  }
  const edit = () => navigateTo(`/admin/users/edit/${name}`)
  const deleteUser = () => navigateTo(`/admin/users/delete/${name}`)
  const resetApiKey = () => navigateTo(`/admin/users/apikey/${name}`)
  const resetPassword = () => navigateTo(`/admin/users/password/${name}`)

  const goToGroup = (event: { preventDefault: () => void }, path: string) => {
    event.preventDefault()
    navigateTo(path)
  }

  const formatDate = (timeNanos?: number) => {
    if (!timeNanos) return
    const date = new Date(timeNanos / 1e6).toLocaleDateString()
    const time = new Date(timeNanos / 1e6).toLocaleTimeString()
    const relativeTime = formatDistance(timeNanos / 1e6, Date.now(), { addSuffix: true })
    return `${date} ${time} (${relativeTime})`
  }

  const userCapabilities =
    user?.groups
      ?.map((group) => group?.capabilities)
      ?.flat()
      ?.filter((e, i, a) => a.indexOf(e) === i)
      ?.sort() ?? []

  if (!name) {
    navigateTo('/admin/users')
  } else
    return (
      <SubpageDialog
        title={
          <Trans i18nKey="PAGES.USERS.DETAIL.TITLE" values={{ name }}>
            ''
            <Typography variant="h6" color="text.primaryColor" component="span">
              ''
            </Typography>
          </Trans>
        }
        loading={usersLoading}
        open={isOpen}
        onClose={onClose}
        nextPath={nextPath}
        actions={[
          {
            onClick: edit,
            label: t('PAGES.USERS.DETAIL.BUTTON_EDIT'),
            color: 'inherit'
          },
          {
            onClick: onClose,
            label: t('PAGES.USERS.DETAIL.BUTTON_CLOSE'),
            variant: 'contained',
            autoFocus: true
          }
        ]}
        overflowActions={[
          {
            onClick: deleteUser,
            icon: <DeleteIcon color="warning" />,
            label: t('PAGES.USERS.DETAIL.BUTTON_DELETE')
          },
          // Users don't have passwords if SSO is enabled, so don't offer to reset them:
          ...(isSsoEnabled
            ? []
            : [
                {
                  onClick: resetPassword,
                  label: t('PAGES.USERS.DETAIL.BUTTON_RESET_PASSWORD')
                }
              ]),
          {
            onClick: resetApiKey,
            label: t('PAGES.USERS.DETAIL.BUTTON_RESET_APIKEY')
          }
        ]}
        growFrom={(locationState as any)?.growFrom}>
        <Collapse in={!!error}>
          <ErrorMessage error={error} />
        </Collapse>
        {user && (
          <>
            <Typography variant="body1">{t('PAGES.USERS.DETAIL.HEADER_DETAILS')}</Typography>
            <List>
              <DetailRow
                primary={t('PAGES.USERS.DETAIL.LABEL_FULLNAME')}
                secondary={user.fullname}
                secondaryDefault={t('PAGES.USERS.DETAIL.NO_VALUE')}
              />
              <DetailRow
                primary={t('PAGES.USERS.DETAIL.LABEL_EMAIL')}
                secondary={user.email}
                secondaryDefault={t('PAGES.USERS.DETAIL.NO_VALUE')}
              />
              <DetailRow
                primary={t('PAGES.USERS.DETAIL.LABEL_COMMENT')}
                secondary={user.comment}
                secondaryDefault={t('PAGES.USERS.DETAIL.NO_VALUE')}
              />
              <DetailRow
                primary={t('PAGES.USERS.DETAIL.LABEL_APIKEY')}
                secondary={<ViewUserApiKey name={name} />}
                secondaryDefault={t('PAGES.USERS.DETAIL.NO_VALUE')}
              />
              <DetailRow
                primary={t('PAGES.USERS.DETAIL.LABEL_CREATION_DATE')}
                secondary={formatDate(user.created)}
                secondaryDefault={t('PAGES.USERS.DETAIL.NO_VALUE')}
              />
              <DetailRow
                primary={t('PAGES.USERS.DETAIL.LABEL_ACCESS_ADTE')}
                secondary={formatDate(user.accessed)}
                secondaryDefault={t('PAGES.USERS.DETAIL.NO_VALUE')}
              />
            </List>
            <Typography variant="body1" sx={{ mt: 4 }}>
              {t('PAGES.USERS.DETAIL.HEADER_EFFECTIVE_PERMISSIONS')}
            </Typography>
            <MiniColoredChipList
              items={userCapabilities}
              labelIfEmpty={t('PAGES.USERS.DETAIL.CAPABILITIES_EMPTY_MESSAGE')}
              sx={{ my: '16px', ml: 2 }}
            />
            <Typography variant="body1" sx={{ mt: 4 }}>
              {t('PAGES.USERS.DETAIL.HEADER_GROUPS')}
            </Typography>
            <List>
              {user?.groups?.map((group) => (
                <DetailRow
                  primary={group.name}
                  secondary={
                    <MiniColoredChipList
                      items={group.capabilities}
                      labelIfEmpty={t('PAGES.USERS.DETAIL.CAPABILITIES_EMPTY_MESSAGE')}
                    />
                  }
                  path={`/admin/groups/view/${group.name}`}
                  action={(e) => goToGroup(e, `/admin/groups/view/${group.name}`)}
                  key={group.name}
                />
              ))}
            </List>
          </>
        )}
      </SubpageDialog>
    )
}

export default UserDetail
