import React, { useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import {
  Divider,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Typography,
  TypographyProps
} from '@mui/material'
import { MoreVert as MoreVertIcon } from '@mui/icons-material'

import AutoUserAvatar from './AutoUserAvatar'

interface AvatarListItemEntryProps {
  primary: React.ReactNode
  primaryHint?: string
  primaryTypographyProps?: TypographyProps
  secondary: React.ReactNode
  avatarText: string
  href: string
  menuEntries: {
    icon?: React.ReactNode
    label: string
    href?: string
    onClick?: (event: React.MouseEvent) => void
  }[][]
}

/**
 * A semi-generic ListItem which contains an optional Avatar, and primary
 * and secondary fields along with an optional link.
 *
 * Used in listing users, groups, ingresses, etc.
 */
const AvatarListItemEntry: React.FC<AvatarListItemEntryProps> = ({
  primary,
  primaryHint,
  primaryTypographyProps,
  secondary,
  avatarText,
  href,
  menuEntries
}) => {
  /** Anchor element for menu with Edit User, etc. */
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const navigate = useNavigate()

  const handleClick = (event: React.MouseEvent, href: string) => {
    event.preventDefault()
    const { x, y, width, height } = (event.target as HTMLElement).getBoundingClientRect()
    handleClose()
    navigate(href, {
      state: {
        growFrom: `${x + width / 2}px ${y + height / 2}px`
      }
    })
  }

  const primaryLine = useMemo(
    () => (
      <>
        {primary}
        {primaryHint && (
          <Typography variant="inherit" component="span" color="text.primaryColor">
            {` (${primaryHint})`}
          </Typography>
        )}
      </>
    ),
    [primary, primaryHint]
  )

  return (
    <ListItem
      sx={{ px: 0 }}
      secondaryAction={
        <IconButton aria-label="user actions" onClick={handleMenu} id="user-actions">
          <MoreVertIcon />
        </IconButton>
      }>
      <ListItemButton component={Link} to={href} onClick={(e) => handleClick(e, href)}>
        <ListItemAvatar sx={{ px: '16px' }}>
          <AutoUserAvatar name={avatarText} />
        </ListItemAvatar>
        <ListItemText
          primary={primaryLine}
          primaryTypographyProps={primaryTypographyProps}
          secondaryTypographyProps={{
            component: 'div'
          }}
          secondary={secondary}
        />
      </ListItemButton>
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}>
        <MenuList>
          {menuEntries.map((group, index, { length }) => [
            ...group?.map?.((entry, index2) => (
              <MenuItem
                key={`${index}${index2}`}
                component={Link}
                to={entry?.href}
                onClick={(e) => handleClick(e, entry?.href)}
                autoFocus={index === 0 && index2 === 0}>
                <ListItemIcon>{entry?.icon}</ListItemIcon>
                {entry?.label}
              </MenuItem>
            )),
            ...[index !== length - 1 && <Divider />]
          ])}
        </MenuList>
      </Menu>
    </ListItem>
  )
}

export default AvatarListItemEntry
