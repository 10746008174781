import React, { PropsWithChildren } from 'react'

import { Collapse } from '@mui/material'
import { TransitionGroup } from 'react-transition-group'

/**
 * A wrapper that allows the Collapse transition based on the existence
 * of any children for the transition.
 *
 * Without a wrapper, the children will immediately vanish when unmounted.
 * Using the wrapper allows them to delay unmounting until the animation
 * is finished.
 */
export const AutoCollapse: React.FC<PropsWithChildren<{}>> = ({ children }) => (
  <TransitionGroup>
    {children && <Collapse key={children as string}>{children}</Collapse>}
  </TransitionGroup>
)
