import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'

/**
 * Unauthorized page to display in case the user somehow makes it to the webapp
 * without the SSO middleware kicking in.
 */

const Unauthorized: React.FC = () => {
  const { t } = useTranslation()

  const theme = useTheme()
  const fullscreen = useMediaQuery(theme.breakpoints.down('sm'))
  const handleReload = useCallback(() => window.location.reload(), [])

  return (
    <Grid container direction="column" alignItems="stretch" mx="auto" maxWidth="500px">
      <Card square={fullscreen} sx={{ mt: 2 }}>
        <CardHeader title={t('PAGES.UNAUTHORIZED.TITLE')} />
        <CardContent>
          <Typography>{t('PAGES.UNAUTHORIZED.BODY')}</Typography>
        </CardContent>
        <CardActions sx={{ justifyContent: 'end' }}>
          <Button onClick={handleReload}>{t('PAGES.UNAUTHORIZED.BUTTON_RELOAD')}</Button>
        </CardActions>
      </Card>
    </Grid>
  )
}

export default Unauthorized
