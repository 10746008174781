import React from 'react'

import { Box, BoxProps, Chip, SxProps, Typography, useMediaQuery, useTheme } from '@mui/material'

import { stringToMaterialColorSx } from '../utils/color'

interface MiniColoredChipListProps extends BoxProps {
  /** List of items to create chips for */
  items: string[]
  /** Label for the chip list */
  label?: string
  /** Label if there are no items (the list is empty) */
  labelIfEmpty?: string
  /** Optional label for empty strings in the list */
  emptyValue?: string
  variant?: 'filled' | 'outlined'
  sx?: SxProps
  short?: boolean
}

/**
 * A list of automatically-color mini chips, e.g. for listing user or group
 * capabilities or prefixes.
 *
 * @constructor
 */
const MiniColoredChipList = ({
  items,
  label,
  labelIfEmpty,
  emptyValue,
  variant = 'filled',
  sx,
  short,
  ...otherProps
}: MiniColoredChipListProps) => {
  const theme = useTheme()
  const fullscreen = useMediaQuery(theme.breakpoints.down('md'))

  // adding in some logic to change the # of chips based on
  // breakpoints: 3 for a screen <= md and 5 for the rest
  const listSizeByBreakpoint = () => {
    if (fullscreen) {
      return 3
    } else {
      return 5
    }
  }

  if (!items || !items.length || items.length < 1) {
    return labelIfEmpty ? (
      <Box flex="row" alignItems="baseline" sx={{ py: '2px', ...sx }} {...otherProps}>
        <Typography variant="body2" color="text.neutral" component="span" sx={{ mr: 0.5 }}>
          {labelIfEmpty}
        </Typography>
      </Box>
    ) : (
      <></>
    )
  }

  return (
    <>
      <Box flex="row" alignItems="baseline" sx={{ py: '2px', ...sx }} {...otherProps}>
        {label && (
          <Typography variant="body2" component="span" sx={{ mr: 0.5 }}>
            {`${label}: `}
          </Typography>
        )}
        {items.length <= listSizeByBreakpoint() || !short ? (
          items.map((item, index) => (
            <Chip
              key={index}
              label={item || emptyValue || ''}
              size="small"
              variant={variant}
              sx={(theme) => ({
                bgcolor:
                  variant === 'filled'
                    ? stringToMaterialColorSx(item)
                    : theme.palette.neutral.contrastText,
                color: variant === 'filled' ? 'white' : theme.palette.neutral.main,
                borderColor: variant === 'outlined' && theme.palette.neutral.main,
                borderWidth: variant === 'outlined' && '1px',
                marginX: '2px',
                marginY: '1px',
                pointerEvents: 'none',
                minWidth: '40px',
                boxShadow: theme.shadows[1],
                '& .MuiChip-label': {
                  fontSize: '0.7rem',
                  fontWeight: '500',
                  lineHeight: 'normal',
                  textShadow: variant === 'filled' && '0 0 2px rgb(0 0 0 / 50%)'
                }
              })}
            />
          ))
        ) : (
          <>
            {items.slice(0, listSizeByBreakpoint()).map((item, index) => (
              <Chip
                key={index}
                label={item || emptyValue || ''}
                size="small"
                variant={variant}
                sx={(theme) => ({
                  bgcolor:
                    variant === 'filled'
                      ? stringToMaterialColorSx(item)
                      : theme.palette.neutral.contrastText,
                  color: variant === 'filled' ? 'white' : theme.palette.neutral.main,
                  borderColor: variant === 'outlined' && theme.palette.neutral.main,
                  borderWidth: variant === 'outlined' && '1px',
                  marginX: '2px',
                  marginY: '1px',
                  pointerEvents: 'none',
                  minWidth: '40px',
                  boxShadow: theme.shadows[1],
                  '& .MuiChip-label': {
                    fontSize: '0.7rem',
                    fontWeight: '500',
                    lineHeight: 'normal',
                    textShadow: variant === 'filled' && '0 0 2px rgb(0 0 0 / 50%)'
                  }
                })}
              />
            ))}
            <Box sx={{ display: 'inline' }}> + {items.length - listSizeByBreakpoint()} </Box>
          </>
        )}
      </Box>
    </>
  )
}

export default React.memo(MiniColoredChipList)
