export const MESSAGES = {
  VALIDATION: {
    EMAIL: {
      REQUIRED: 'Email is required',
      INVALID: 'Please enter a valid email',
      NOT_UNIQUE: 'A user with that email already exists'
    },
    GROUP: {
      REQUIRED: 'Group name is required',
      INVALID: 'Group name can only contain characters A-Z, a-z, and 0-9'
    },
    INGRESS: {
      REQUIRED: 'Ingress name is required',
      INVALID: 'Ingress names may only contain the characters a-z, A-Z, 0-9 and _',
      NOT_UNIQUE: 'A ingress with that name already exists',
      CLASS_REQUIRED: 'Ingress class is required',
      PREFIX_REQUIRED: 'Collection prefix is required',
      STREAMS_REQUIRED: 'At least one stream is required',
      FREQUENCY_REQUIRED: 'Streams require at least 1Hz of generated data',
      TARGET_REQUIRED: 'Target is required',
      ID_REQUIRED: 'ID is required',
      QUERY_REQUIRED: 'Query is required',
      TIMESTAMP_INVALID: 'Timestamp rounding, if provided, must be a positive integer'
    },
    PASSWORD: {
      REQUIRED: 'Password is required',
      INVALID_LENGTH: 'Password must be at least 12 characters',
      INVALID_REQUIRED_CHARS:
        'Password must contain a lowercase letter, an uppercase letter, and a number',
      INVALID_CHARS:
        'Password may only contain characters a-z, A-Z, 0-9, and the symbols !@#$%^&*()'
    },
    USER: {
      REQUIRED: 'Username is required',
      INVALID: 'Username may only contain the characters a-z, 0-9, and _',
      NOT_UNIQUE: 'A user with that name already exists'
    },
    VERIFY_PASSWORD: {
      REQUIRED: 'Verify password is required',
      PASSWORDS_DONT_MATCH: 'Passwords do not match'
    }
  }
}
