import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Formik, Field } from 'formik'
import { TextField, CheckboxWithLabel } from 'formik-mui'

import {
  Alert,
  AlertTitle,
  Collapse,
  FormGroup,
  FormHelperTextProps,
  Grid,
  Typography
} from '@mui/material'
import { useSnackbar } from 'notistack'

import { useLazyQuery, useMutation } from '@apollo/client'
import {
  CREATE_USER,
  CreateUserRequest,
  CreateUserResponse,
  ADD_USER_TO_GROUP,
  AddUserToGroupRequest,
  AddUserToGroupResponse,
  SEND_EMAIL,
  SendEmailRequest,
  SendEmailResponse,
  GET_API_KEY,
  GetAPIKeyRequest,
  GetAPIKeyResponse
} from 'lib/graphQlQueries'

import AutocompleteMultiSelectChipInput from 'components/AutocompleteMultiSelectChipInput'
import { AutoCollapse } from 'components/AutoTransition'
import MiniColoredChipList from 'components/MiniColoredChipList'
import SubpageDialog from 'components/SubpageDialog'

import { useUsers } from 'lib/providers/UsersProvider'
import { useGroups } from 'lib/providers/GroupsProvider'
import { newUserEmailBody } from 'lib/emails/newUserEmail'
import { findAndRethrowError } from 'utils/error'
import ErrorMessage from 'components/ErrorMessage'
import generatePassword from 'utils/generatePassword'
import { isSsoEnabled } from 'lib/env'

import { createUserValidation } from 'utils/validationSchemas'

interface FormValues {
  username: string
  email: string
  autoGeneratePassword: boolean
  password: string
  verifyPassword: string
  sendNewUserWelcomeEmail: boolean
  emailPasswordToUser: boolean
  emailApiKeyToUser: boolean
  fullname: string
  comment: string
  userGroups: string[]
}

const UserCreate = () => {
  const { t } = useTranslation()

  // Users
  const {
    getAllUsers,
    refetchAllUsers,
    getUsersLoading,
    getUsersRefetching,
    getError: getUsersError
  } = useUsers()
  const users = getAllUsers()
  const usersLoading = getUsersLoading()
  const usersRefetching = getUsersRefetching()

  // Groups
  const {
    getAllGroups,
    getGroupsLoading,
    getError: getGroupsError,
    getEffectivePermissions
  } = useGroups()
  const groups = getAllGroups() ?? []
  const groupsLoading = getGroupsLoading()

  const allGroupNames = groups
    ?.map((group) => group.name)
    ?.sort((a, b) => a.localeCompare(b))
    .filter((g) => g !== 'public')

  // Username state
  const [username, setUsername] = useState('')

  // Groups state
  const [userGroups, setUserGroups] = useState<string[]>([])

  const { enqueueSnackbar } = useSnackbar()

  const effectivePermissions = getEffectivePermissions(['public', ...userGroups])

  const [isOpen, setIsOpen] = useState(true)

  const [
    createUserMutation,
    { loading: createUserMutationLoading, error: createUserMutationError }
  ] = useMutation<CreateUserResponse, CreateUserRequest>(CREATE_USER, {
    notifyOnNetworkStatusChange: true
  })
  const [
    addUserToGroupMutation,
    { loading: addUserToGroupMutationLoading, error: addUserToGroupMutationError }
  ] = useMutation<AddUserToGroupResponse, AddUserToGroupRequest>(ADD_USER_TO_GROUP, {
    notifyOnNetworkStatusChange: true
  })

  const [sendEmail, { loading: sendEmailLoading, error: sendEmailError }] = useMutation<
    SendEmailResponse,
    SendEmailRequest
  >(SEND_EMAIL)

  const [getAPIKey, { loading: getApiKeyLoading, error: getApiKeyError }] = useLazyQuery<
    GetAPIKeyResponse,
    GetAPIKeyRequest
  >(GET_API_KEY, {
    variables: {
      username
    }
  })

  const [saveError, setSaveError] = useState<Error>()

  // Disable the form elements while a request is happening
  const formDisabled =
    createUserMutationLoading ||
    addUserToGroupMutationLoading ||
    sendEmailLoading ||
    getApiKeyLoading ||
    usersRefetching

  const onClose = (_event: React.SyntheticEvent, reason?: string, values?: FormValues) => {
    const { username, password, comment, fullname } = values
    // Don't accidentally close form by clicking background when data has been added
    if (
      reason !== 'backdropClick' ||
      !(username || password || userGroups?.length || fullname || comment)
    ) {
      // also don't close while waiting for submit response
      if (!formDisabled) {
        setIsOpen(false)
      }
    }
  }

  const createUser = async (values: FormValues) => {
    // reset displayed error, if any
    setSaveError(null)

    const {
      username,
      email,
      autoGeneratePassword,
      password: userPassword,
      sendNewUserWelcomeEmail,
      emailPasswordToUser,
      emailApiKeyToUser,
      fullname,
      comment,
      userGroups
    } = values

    try {
      const password = autoGeneratePassword ? generatePassword() : userPassword

      // Create the new user
      const { data: createUserMutationResponse } = await createUserMutation({
        variables: {
          username,
          email,
          comment,
          fullname,
          password
        }
      })
      findAndRethrowError(createUserMutationResponse)
      findAndRethrowError(createUserMutationError)

      if (userGroups) {
        for (const group of userGroups) {
          const { data: addUserToGroupMutationResponse } = await addUserToGroupMutation({
            variables: {
              username,
              group
            }
          })
          findAndRethrowError(addUserToGroupMutationResponse)
          findAndRethrowError(addUserToGroupMutationError)
        }
      }

      if (email && sendNewUserWelcomeEmail) {
        let apikey: string | null
        if (emailApiKeyToUser) {
          const { data: getApiKeyResponse } = await getAPIKey()
          apikey = getApiKeyResponse?.GetAPIKey?.apikey
          findAndRethrowError(getApiKeyResponse)
          findAndRethrowError(getApiKeyError)
        }
        const { data: sendEmailData, errors } = await sendEmail({
          variables: {
            username: username,
            sender: 'support@pingthings.io',
            recipient: email, // TODO: email is not a required field on User
            subject: 'Account created',
            emailBody: newUserEmailBody(username, fullname, {
              ...(emailPasswordToUser && { password }),
              ...(emailApiKeyToUser && { apikey })
            })
          }
        })
        findAndRethrowError(sendEmailData)
        findAndRethrowError(sendEmailError)
        if (errors?.[0]) {
          throw errors[0]
        }
      }

      // update data from server
      await refetchAllUsers()

      // No errors, close window and show success snackbar
      enqueueSnackbar(t('PAGES.GROUPS.CREATE.SNACKBAR_SUCCESS', { name: username }), {
        variant: 'success'
      })
      setIsOpen(false)
    } catch (e) {
      setSaveError(e)
    }
  }

  const error =
    saveError ||
    createUserMutationError ||
    addUserToGroupMutationError ||
    getUsersError() ||
    getGroupsError()

  return (
    <Formik<FormValues>
      initialValues={{
        username: '',
        email: '',
        autoGeneratePassword: true,
        password: '',
        verifyPassword: '',
        sendNewUserWelcomeEmail: true,
        emailPasswordToUser: true,
        emailApiKeyToUser: true,
        fullname: '',
        comment: '',
        userGroups: []
      }}
      validationSchema={createUserValidation(isSsoEnabled(), users)}
      onSubmit={async (values) => await createUser(values)}>
      {({ values, setFieldValue, errors, handleSubmit }) => {
        const {
          username,
          email,
          autoGeneratePassword,
          password,
          verifyPassword,
          sendNewUserWelcomeEmail,
          emailPasswordToUser,
          userGroups
        } = values

        return (
          <SubpageDialog
            title={t('PAGES.USERS.CREATE.TITLE')}
            nextPath="/admin/users"
            loading={usersLoading || groupsLoading}
            open={isOpen}
            onClose={(event: React.SyntheticEvent, reason?: string) =>
              onClose(event, reason, values)
            }
            actions={[
              {
                label: t('PAGES.USERS.CREATE.BUTTON_CANCEL'),
                onClick: (event: React.SyntheticEvent, reason?: string) =>
                  onClose(event, reason, values),
                color: 'inherit',
                disabled: formDisabled
              },
              {
                label: t('PAGES.USERS.CREATE.BUTTON_CREATE'),
                onClick: () => {
                  handleSubmit()
                },
                variant: 'contained',
                loading: formDisabled || usersRefetching,
                disabled:
                  formDisabled ||
                  username === '' ||
                  email === '' ||
                  !!Object.keys(errors).length ||
                  (!autoGeneratePassword && (!password || !verifyPassword))
              }
            ]}>
            <Grid container columnSpacing={2}>
              <Grid item xs={12}>
                <ErrorMessage canClose error={error} />
              </Grid>
              {/* <Box display="flex" flexDirection="row" justifyContent="stretch"> */}
              <Grid item xs={12} md={6}>
                <Field
                  component={TextField}
                  autoFocus
                  required
                  label={t('PAGES.USERS.CREATE.FIELD_USERNAME_LABEL')}
                  name="username"
                  role="textbox"
                  autoComplete="username"
                  onChange={(e) => {
                    setFieldValue('username', e.target.value)
                    setUsername(e.target.value)
                  }}
                  margin="dense"
                  disabled={formDisabled}
                  helperText={<AutoCollapse>{errors.username}</AutoCollapse>}
                  FormHelperTextProps={{ error: true, component: 'div' } as FormHelperTextProps}
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  component={TextField}
                  required
                  label={t('PAGES.USERS.CREATE.FIELD_EMAIL_LABEL')}
                  name="email"
                  role="textbox"
                  autoComplete="email"
                  margin="dense"
                  disabled={formDisabled}
                  helperText={<AutoCollapse>{errors.email}</AutoCollapse>}
                  FormHelperTextProps={{ error: true, component: 'div' } as FormHelperTextProps}
                  sx={{ width: '100%' }}
                />
              </Grid>
              {/* </Box> */}
              <Grid item xs={12}>
                <Field
                  component={CheckboxWithLabel}
                  name="autoGeneratePassword"
                  type="checkbox"
                  disabled={formDisabled}
                  Label={{ label: t('PAGES.USERS.CREATE.FIELD_AUTOGENERATE_PASSWORD_LABEL') }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Collapse in={!autoGeneratePassword}>
                  <Field
                    component={TextField}
                    required={!autoGeneratePassword}
                    label={t('PAGES.USERS.CREATE.FIELD_PASSWORD')}
                    name="password"
                    type="password"
                    role="textbox"
                    autoComplete="new-password"
                    margin="dense"
                    error={!!errors?.password && password.length >= 12}
                    disabled={formDisabled}
                    helperText={<AutoCollapse>{errors.password}</AutoCollapse>}
                    FormHelperTextProps={{ component: 'div' } as FormHelperTextProps}
                    sx={{ width: '100%' }}
                  />
                </Collapse>
              </Grid>
              <Grid item xs={12} md={6}>
                <Collapse in={!autoGeneratePassword}>
                  <Field
                    component={TextField}
                    required={!autoGeneratePassword}
                    label={t('PAGES.USERS.CREATE.FIELD_VERIFY_PASSWORD')}
                    name="verifyPassword"
                    type="password"
                    role="textbox"
                    autoComplete="new-password"
                    margin="dense"
                    disabled={formDisabled}
                    helperText={<AutoCollapse>{errors.verifyPassword}</AutoCollapse>}
                    FormHelperTextProps={{ error: true, component: 'div' } as FormHelperTextProps}
                    sx={{ width: '100%' }}
                  />
                </Collapse>
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  component={TextField}
                  label={t('PAGES.USERS.CREATE.FIELD_FULLNAME_LABEL')}
                  name="fullname"
                  role="textbox"
                  margin="dense"
                  disabled={formDisabled}
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  component={TextField}
                  label={t('PAGES.USERS.CREATE.FIELD_COMMENT_LABEL')}
                  name="comment"
                  role="textbox"
                  autoComplete="comment"
                  margin="dense"
                  disabled={formDisabled}
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={12}>
                <AutocompleteMultiSelectChipInput
                  value={userGroups}
                  onChange={(groups) => {
                    setFieldValue('userGroups', groups)
                    setUserGroups(groups)
                  }}
                  entries={allGroupNames}
                  allowCustom={false}
                  label={t('PAGES.USERS.CREATE.FIELD_GROUPS_LABEL')}
                  placeholder={t('PAGES.USERS.CREATE.FIELD_GROUPS_PLACEHOLDER')}
                  disabled={formDisabled}
                  sx={{ my: 2 }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption">
                  {t('PAGES.USERS.CREATE.EFFECTIVE_PERMISSIONS_LABEL')}
                </Typography>
                <MiniColoredChipList items={effectivePermissions} sx={{ mx: 1, mb: 1 }} />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={CheckboxWithLabel}
                  name="sendNewUserWelcomeEmail"
                  type="checkbox"
                  disabled={!email || formDisabled}
                  Label={{ label: t('PAGES.USERS.CREATE.FIELD_SEND_EMAIL') }}
                />
              </Grid>
              <Grid item xs={12}>
                <Collapse in={sendNewUserWelcomeEmail} mountOnEnter unmountOnExit>
                  <FormGroup>
                    <Field
                      component={CheckboxWithLabel}
                      name="emailPasswordToUser"
                      type="checkbox"
                      disabled={!email || formDisabled}
                      Label={{ label: t('PAGES.USERS.CREATE.FIELD_EMAIL_PASSWORD') }}
                      sx={{ ml: 3 }}
                    />
                    <Field
                      component={CheckboxWithLabel}
                      name="emailApiKeyToUser"
                      type="checkbox"
                      disabled={!email || formDisabled}
                      Label={{ label: t('PAGES.USERS.CREATE.FIELD_EMAIL_APIKEY') }}
                      sx={{ ml: 3 }}
                    />
                  </FormGroup>
                </Collapse>
              </Grid>
              <Grid item xs={12}>
                <Collapse
                  in={
                    autoGeneratePassword &&
                    (!email || !sendNewUserWelcomeEmail || !emailPasswordToUser)
                  }
                  mountOnEnter
                  unmountOnExit>
                  <Alert severity="warning" sx={{ mt: 2 }}>
                    <AlertTitle> {t('PAGES.USERS.CREATE.RESET_PASSWORD_WARNING_TITLE')}</AlertTitle>
                    {t('PAGES.USERS.CREATE.RESET_PASSWORD_WARNING_BODY')}
                  </Alert>
                </Collapse>
              </Grid>
            </Grid>
          </SubpageDialog>
        )
      }}
    </Formik>
  )
}

export default UserCreate
