import { Grow } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

interface NavigateOnFinishTransitionProps {
  to?: string
  growFrom?: string
  onExited: ((e?: HTMLElement) => void) | undefined
  children: React.ReactElement
}

/**
 * Wraps components that need to suspend router navigation until
 * after a transition has finished
 */
const NavigateOnFinishTransition = React.forwardRef<
  HTMLDivElement,
  NavigateOnFinishTransitionProps
>(({ children, onExited, growFrom, to, ...otherProps }, ref) => {
  const navigate = useNavigate()
  return (
    <Grow
      {...otherProps}
      ref={ref}
      timeout={{ enter: 300, exit: 300 }}
      onExited={(e) => {
        onExited?.(e)
        if (to) {
          navigate(to)
        }
      }}
      style={growFrom && { transformOrigin: growFrom }}
      mountOnEnter
      unmountOnExit>
      {children}
    </Grow>
  )
})

export default NavigateOnFinishTransition
