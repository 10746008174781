import { ApolloError, gql } from '@apollo/client'
import { Group, GroupCapability } from './types/Group'
import { Ingress } from './types/Ingress'
import { User } from './types/User'

/*****************************
 * AddGroup
 ****************************/

export interface AddGroupRequest {
  name: string
}

export interface AddGroupResponse {
  error?: ApolloError
}

export const ADD_GROUP = gql`
  mutation AddGroup($name: ID!) {
    AddGroup(body: { name: $name }) {
      error {
        code
        msg
      }
    }
  }
`

/*****************************
 * AddIngress
 ****************************/

export type AddIngressRequest = Ingress

export interface AddIngressResponse {
  error?: ApolloError
}

export const ADD_INGRESS = gql`
  mutation AddIngress(
    $name: ID!
    $class: String!
    $enabled: Boolean!
    $collectionPrefix: String!
    $comment: String!
    $parameters: MapStringString!
  ) {
    AddIngress(
      body: {
        ingress: {
          name: $name
          class: $class
          enabled: $enabled
          collectionPrefix: $collectionPrefix
          comment: $comment
          parameters: $parameters
        }
      }
    ) {
      error {
        code
        msg
      }
    }
  }
`

/*****************************
 * AddUserToGroup
 ****************************/

export interface AddUserToGroupRequest {
  username: string
  group: string
}

export interface AddUserToGroupResponse {
  error?: ApolloError
}

export const ADD_USER_TO_GROUP = gql`
  mutation AddUserToGroup($username: ID!, $group: ID!) {
    AddUserToGroup(body: { username: $username, group: $group }) {
      error {
        code
        msg
      }
    }
  }
`

/*****************************
 * CreateUser
 ****************************/

export interface CreateUserRequest {
  username: string
  comment: string
  email: string
  fullname: string
  password: string
}

export interface CreateUserResponse {
  error?: ApolloError
}

export const CREATE_USER = gql`
  mutation CreateUser(
    $comment: String
    $email: String
    $fullname: String
    $password: String!
    $username: ID!
  ) {
    CreateUser(
      body: {
        comment: $comment
        email: $email
        fullname: $fullname
        password: $password
        username: $username
      }
    ) {
      error {
        code
        msg
      }
    }
  }
`

/*****************************
 * DeleteGroup
 ****************************/

export interface DeleteGroupRequest {
  name: string
}

export interface DeleteGroupResponse {
  error?: ApolloError
}

export const DELETE_GROUP = gql`
  mutation DeleteGroup($name: ID!) {
    DeleteGroup(body: { name: $name }) {
      error {
        code
        msg
      }
    }
  }
`

/*****************************
 * DeleteUser
 ****************************/

export interface DeleteUserRequest {
  username: string
}

export interface DeleteUserResponse {
  error?: ApolloError
}

export const DELETE_USER = gql`
  mutation DeleteUser($username: ID!) {
    DeleteUser(body: { username: $username }) {
      error {
        code
        msg
      }
    }
  }
`

/*****************************
 * GetAllGroupObjects
 ****************************/

export interface GetAllGroupObjectsResponse {
  GetAllGroupObjects: {
    groups?: Group[]
    error?: ApolloError
  }
}

export const GET_ALL_GROUP_OBJECTS = gql`
  query GetAllGroupObjects {
    GetAllGroupObjects {
      groups {
        name
        prefixes
        capabilities
      }
    }
  }
`

/*****************************
 * GetAllIngresses
 ****************************/

export interface GetAllIngressesResponse {
  GetAllIngresses: {
    ingresses?: Ingress[]
    error?: ApolloError
  }
}

export const GET_ALL_INGRESSES = gql`
  query GetAllIngresses {
    GetAllIngresses {
      error {
        code
        msg
      }
      ingresses {
        name
        class
        enabled
        collectionPrefix
        comment
        parameters
      }
    }
  }
`

/*****************************
 * GetAllUserObjects
 ****************************/

export interface GetAllUserObjectsResponse {
  GetAllUserObjects: {
    users?: User[]
    error?: ApolloError
  }
}

export const GET_ALL_USER_OBJECTS = gql`
  query GetAllUserObjects {
    GetAllUserObjects {
      users {
        comment
        email
        fullname
        groups {
          name
          prefixes
          capabilities
        }
        username
        created
        accessed
      }
    }
  }
`

/*****************************
 * GetBuiltinUser
 ****************************/

export interface GetBuiltinUserRequest {
  username: string
}

export interface GetBuiltinUserResponse {
  GetBuiltinUser: {
    user: User
    error?: ApolloError
  }
}

export const GET_BUILTIN_USER = gql`
  query GetBuiltinUser($username: ID!) {
    GetBuiltinUser(body: { username: $username }) {
      user {
        username
        fullname
        email
        comment
        created
        accessed
        groups {
          name
          prefixes
          capabilities
        }
      }
    }
  }
`

/*****************************
 * Login
 ****************************/

export interface LoginRequest {
  username: string
  password: string
}

export interface LoginResponse {
  error?: ApolloError
  username: string
  token: string
}

export const LOGIN = gql`
  mutation Login($username: String!, $password: String!) {
    Login(body: { username: $username, password: $password }) {
      username
      token
    }
  }
`

/*****************************
 * RemoveUserFromGroup
 ****************************/

export interface RemoveUserFromGroupRequest {
  username: string
  group: string
}

export interface RemoveUserFromGroupResponse {
  error?: ApolloError
}

export const REMOVE_USER_FROM_GROUP = gql`
  mutation RemoveUserFromGroup($username: ID!, $group: ID!) {
    RemoveUserFromGroup(body: { username: $username, group: $group }) {
      error {
        code
        msg
      }
    }
  }
`

/*****************************
 * SetGroupCapabilities
 ****************************/

export interface SetGroupCapabilitiesRequest {
  group: string
  capabilities: GroupCapability[]
}

export interface SetGroupCapabilitiesResponse {
  error?: ApolloError
}

export const SET_GROUP_CAPABILITIES = gql`
  mutation SetGroupCapabilities($group: ID!, $capabilities: [Capability]!) {
    SetGroupCapabilities(body: { group: $group, capabilities: $capabilities }) {
      error {
        code
        msg
      }
    }
  }
`

/*****************************
 * SetGroupPrefixes
 ****************************/

export interface SetGroupPrefixesRequest {
  /** Group name */
  group: string
  prefixes: string[]
}

export interface SetGroupPrefixesResponse {
  error?: ApolloError
}

export const SET_GROUP_PREFIXES = gql`
  mutation SetGroupPrefixes($group: ID!, $prefixes: [String]!) {
    SetGroupPrefixes(body: { group: $group, prefixes: $prefixes }) {
      error {
        code
        msg
      }
    }
  }
`

/*****************************
 * SendEmail
 ****************************/

export interface SendEmailRequest {
  username: string
  sender: string
  recipient: string
  subject: string
  emailBody: string
}

export interface SendEmailResponse {
  error?: ApolloError
  recipient: string
  response?: object
  success: boolean
  username: string
}

export const SEND_EMAIL = gql`
  mutation SendEmail(
    $username: String!
    $sender: String!
    $recipient: String!
    $subject: String!
    $emailBody: String!
  ) {
    SendEmail(
      body: {
        username: $username
        sender: $sender
        recipient: $recipient
        subject: $subject
        emailBody: $emailBody
      }
    ) {
      success
      error {
        code
        msg
      }
      response
      username
      recipient
    }
  }
`

/*****************************
 * RemoveIngress
 ****************************/

export interface RemoveIngressRequest {
  name: string
}

export interface RemoveIngressResponse {
  RemoveIngress: {
    error?: ApolloError
  }
}

export const REMOVE_INGRESS = gql`
  mutation RemoveIngress($name: ID!) {
    RemoveIngress(body: { name: $name }) {
      error {
        code
        msg
      }
    }
  }
`

/*****************************
 * ResetAPIKey
 ****************************/

export interface ResetAPIKeyRequest {
  username: string
}

export interface ResetAPIKeyResponse {
  ResetAPIKey: {
    apikey?: string
    error?: ApolloError
  }
}

export const RESET_API_KEY = gql`
  mutation ResetAPIKey($username: ID!) {
    ResetAPIKey(body: { username: $username }) {
      error {
        code
        msg
      }
      apikey
    }
  }
`

/*****************************
 * GetAPIKey
 ****************************/

export interface GetAPIKeyRequest {
  username: string
}

export interface GetAPIKeyResponse {
  GetAPIKey: {
    apikey: string
    error?: ApolloError
  }
}

export const GET_API_KEY = gql`
  query GetAPIKey($username: ID!) {
    GetAPIKey(body: { username: $username }) {
      error {
        code
        msg
      }
      apikey
    }
  }
`

/*****************************
 * SetUserPassword
 ****************************/

export interface SetUserPasswordRequest {
  username: string
  password: string
}

export interface SetUserPasswordResponse {
  error?: ApolloError
}

export const SET_USER_PASSWORD = gql`
  mutation SetUserPassword($username: ID!, $password: String!) {
    SetUserPassword(body: { username: $username, password: $password }) {
      error {
        code
        msg
      }
    }
  }
`

/*****************************
 * UpdateIngress
 ****************************/

export type UpdateIngressRequest = Ingress

export interface UpdateIngressResponse {
  UpdateIngress: {
    error?: ApolloError
  }
}

export const UPDATE_INGRESS = gql`
  mutation UpdateIngress(
    $name: ID!
    $class: String!
    $enabled: Boolean!
    $collectionPrefix: String!
    $comment: String!
    $parameters: MapStringString!
  ) {
    UpdateIngress(
      body: {
        ingress: {
          name: $name
          class: $class
          enabled: $enabled
          collectionPrefix: $collectionPrefix
          comment: $comment
          parameters: $parameters
        }
      }
    ) {
      error {
        code
        msg
      }
    }
  }
`

/*****************************
 * UpdateUser
 ****************************/

export interface UpdateUserRequest {
  username: string
  fullname?: string
  email?: string
  comment?: string
}

export interface UpdateUserResponse {
  error?: ApolloError
}

export const UPDATE_USER = gql`
  mutation UpdateUser($username: ID!, $fullname: String, $email: String, $comment: String) {
    UpdateUser(
      body: { username: $username, fullname: $fullname, email: $email, comment: $comment }
    ) {
      error {
        code
        msg
      }
    }
  }
`
