import React, { useEffect, useState } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'

import { Typography, Collapse } from '@mui/material'
import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'

import { findAndRethrowError } from 'utils/error'
import ErrorMessage from 'components/ErrorMessage'
import { DELETE_GROUP, DeleteGroupRequest, DeleteGroupResponse } from 'lib/graphQlQueries'
import { useGroups } from 'lib/providers/GroupsProvider'
import SubpageDialog from 'components/SubpageDialog'

const GroupDelete = () => {
  const { t } = useTranslation()

  const { name } = useParams()

  const { enqueueSnackbar } = useSnackbar()

  const [error, setError] = useState<Error>()

  const [isOpen, setIsOpen] = useState(true)

  const {
    refetchAllGroups,
    getGroupsLoading,
    getGroupsRefetching,
    getError: getGroupsError
  } = useGroups()

  const groupsLoading = getGroupsLoading()
  const groupsError = getGroupsError()
  useEffect(() => setError(groupsError), [groupsError])

  const [deleteGroup, { loading: deleteGroupLoading, error: deleteGroupError }] = useMutation<
    DeleteGroupResponse,
    DeleteGroupRequest
  >(DELETE_GROUP, {
    variables: {
      name
    }
  })

  // Disable the form elements while a request is happening
  const formDisabled = groupsLoading || deleteGroupLoading || getGroupsRefetching()

  const onClose = () => {
    setIsOpen(false)
  }

  const confirmDelete = async (_event) => {
    // reset displayed error, if any
    setError(null)

    try {
      const { data } = await deleteGroup()
      findAndRethrowError(data)
      findAndRethrowError(deleteGroupError)

      await refetchAllGroups()

      // No errors, close window and show success snackbar
      enqueueSnackbar(t('PAGES.GROUPS.DELETE.SNACKBAR_SUCCESS', { name }), {
        variant: 'success'
      })
      setIsOpen(false)
    } catch (err) {
      setError(err)
    }
  }

  if (!name) {
    return <Navigate to="/admin/groups" />
  } else
    return (
      <SubpageDialog
        title={
          <Trans i18nKey="PAGES.GROUPS.DELETE.TITLE" values={{ name }}>
            ''
            <Typography variant="h6" color="text.primaryColor" component="span">
              ''
            </Typography>
          </Trans>
        }
        loading={groupsLoading}
        open={isOpen}
        onClose={onClose}
        nextPath={'/admin/groups'}
        actions={[
          {
            label: t('PAGES.GROUPS.DELETE.BUTTON_CANCEL'),
            onClick: onClose,
            color: 'inherit',
            disabled: formDisabled,
            autoFocus: true
          },
          {
            label: t('PAGES.GROUPS.DELETE.BUTTON_DELETE'),
            onClick: confirmDelete,
            variant: 'contained',
            color: 'error',
            disabled: formDisabled,
            loading: formDisabled
          }
        ]}>
        <Collapse in={!!error}>
          <ErrorMessage error={error} />
        </Collapse>
        <Typography gutterBottom>{t('PAGES.GROUPS.DELETE.BODY_1')}</Typography>
        <Typography gutterBottom>
          <Trans i18nKey="PAGES.GROUPS.DELETE.BODY_2" values={{ name }}>
            ''
            <Typography variant="body1" color="text.primaryColor" component="span" key="type">
              ''
            </Typography>
          </Trans>
        </Typography>
      </SubpageDialog>
    )
}

export default GroupDelete
