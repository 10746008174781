import React, { useContext } from 'react'
import { Outlet } from 'react-router-dom'

import { ApolloError, ApolloQueryResult, NetworkStatus, useQuery } from '@apollo/client'
import { Maybe } from 'graphql/jsutils/Maybe'

import { GET_ALL_USER_OBJECTS, GetAllUserObjectsResponse } from 'lib/graphQlQueries'
import { User } from 'lib/types/User'

export interface UsersProviderApi {
  getAllUsers: () => User[]
  refetchAllUsers: () => Promise<ApolloQueryResult<GetAllUserObjectsResponse>>
  getUsersInGroup: (group: string) => User[]
  getUser: (name: string) => Maybe<User>
  getUsersLoading: () => boolean
  getUsersRefetching: () => boolean
  getError: () => Maybe<ApolloError>
}

export const UsersContext = React.createContext<UsersProviderApi>({
  getAllUsers: () => [],
  refetchAllUsers: () => null,
  getUser: () => null,
  getUsersInGroup: () => [],
  /** If the User is doing its initial load. False during refresh. */
  getUsersLoading: () => true,
  /** If the User is refetching data. False during initial load. */
  getUsersRefetching: () => true,
  getError: () => null
})

interface UsersProviderProps {
  children?: React.ReactElement
}

export const UsersProvider: React.FC<UsersProviderProps> = ({ children }) => {
  const { data, error, refetch, networkStatus } = useQuery<GetAllUserObjectsResponse>(
    GET_ALL_USER_OBJECTS,
    {
      notifyOnNetworkStatusChange: true
    }
  )

  const users = (data?.GetAllUserObjects?.users ?? []).map(({ groups, ...other }) => {
    return {
      groups: groups.map((group) => ({
        name: group.name,
        capabilities: Array.from(group.capabilities)?.sort(),
        prefixes: Array.from(group.prefixes)?.sort()
      })),
      ...other
    }
  })

  const getAllUsers = () => users
  const refetchAllUsers = () => refetch()
  const getUser = (name: string) => getAllUsers()?.find(({ username }) => name === username)
  const getUsersInGroup = (group: string) =>
    getAllUsers()?.filter((user: User) => user.groups.find(({ name }) => group === name)) ?? []
  const getUsersLoading = () => networkStatus === NetworkStatus.loading
  const getUsersRefetching = () => networkStatus === NetworkStatus.refetch
  const getError = () => error || data?.GetAllUserObjects?.error

  return (
    <UsersContext.Provider
      value={{
        getAllUsers,
        refetchAllUsers,
        getUser,
        getUsersInGroup,
        getUsersLoading,
        getError,
        getUsersRefetching
      }}>
      {children ?? <Outlet />}
    </UsersContext.Provider>
  )
}

export const useUsers = () => useContext(UsersContext)
