import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Box, Button, Divider, Paper, Typography, useTheme } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { Formik, Field, Form } from 'formik'
import { TextField } from 'formik-mui'

import { AutoCollapse } from 'components/AutoTransition'
import ErrorMessage from 'components/ErrorMessage'
import Navbar from 'components/Navbar'

import Unauthorized from 'pages/Unauthorized'
import { ReactComponent as Logo } from 'images/logo.svg'

import { isSsoEnabled } from 'lib/env'
import { useAuth } from 'lib/providers/AuthProvider'
import { loginValidation } from 'utils/validationSchemas'

interface FormValues {
  username: string
  password: string
}

const Login: React.FC = () => {
  const { t } = useTranslation()

  const { login, getLoggedIn } = useAuth()
  const [error, setError] = useState()
  const navigate = useNavigate()

  useEffect(() => {
    if (getLoggedIn()) {
      navigate('/')
    }
  })

  const theme = useTheme()

  const onSubmit = async ({ username, password }) => {
    try {
      if (await login(username, password)) {
        navigate('/')
      }
    } catch (e) {
      console.error('Login error', e)
      setError(e)
    }
  }

  return (
    <Formik<FormValues>
      initialValues={{
        username: '',
        password: ''
      }}
      validationSchema={loginValidation}
      onSubmit={onSubmit}>
      {({ isSubmitting }) => {
        return (
          <>
            <Navbar />
            {isSsoEnabled() ? (
              <Unauthorized />
            ) : (
              <Box
                sx={{
                  backgroundColor: theme.palette.background.default,
                  width: '100%',
                  overflowY: 'auto',
                  position: 'fixed',
                  top: '48px',
                  bottom: '0',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                <Paper
                  style={{
                    margin: 'auto',
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '40px',
                    flexDirection: 'column',
                    alignItems: 'center',
                    maxWidth: '330px',
                    width: '100%'
                  }}>
                  <Logo style={{ width: '250px' }} />
                  <Typography variant="caption">{t('PAGES.LOGIN.TITLE')}</Typography>
                  <Divider style={{ margin: '20px' }} />
                  <AutoCollapse>
                    <ErrorMessage error={error} />
                  </AutoCollapse>
                  {isSubmitting && <CircularProgress />}
                  <Form style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <Field
                      component={TextField}
                      required
                      autoFocus
                      name="username"
                      label={t('PAGES.LOGIN.USERNAME')}
                      variant="standard"
                      autoComplete="username"
                    />
                    <Field
                      component={TextField}
                      required
                      name="password"
                      type="password"
                      id="standard-basic"
                      label={t('PAGES.LOGIN.PASSWORD')}
                      variant="standard"
                      autoComplete="current-password"
                    />

                    <Button
                      style={{ marginTop: '60px' }}
                      variant="contained"
                      type="submit"
                      name="login">
                      {t('PAGES.LOGIN.BUTTON_LOGIN')}
                    </Button>
                  </Form>
                </Paper>
              </Box>
            )}
          </>
        )
      }}
    </Formik>
  )
}

export default Login
