export enum IngressClass {
  lg = 'lg',
  c37 = 'c37',
  gep = 'gep',
  sttp = 'sttp'
}

export type IngressClassParameters<T extends IngressClass = IngressClass> =
  T extends IngressClass.lg
    ? {
        nstreams: number // required
        frequency: number // required
        revision?: string
        randomized?: boolean
      }
    : T extends IngressClass.gep
    ? {
        target: string // required
        query: string // required
        revision?: string
        flags?: 'yes' | 'no'
        tsround?: number
      }
    : T extends IngressClass.c37
    ? {
        target: string // required
        id: string // required
        debug?: boolean
        fmt?: string
        revision?: string
        configtype?: 1 | 2
        mode?: 'dial' | 'dial,tls'
      }
    : T extends IngressClass.sttp
    ? {
        target: string // required
        query: string // required
      }
    : never

/**
 * Allows specifying a specific type of ingress, or the lowest
 * common demoninator if no type is given
 */
export type Ingress<T extends IngressClass = IngressClass> = {
  name: string
  collectionPrefix: string
  comment: string
  enabled: boolean
  class: T
  parameters: IngressClassParameters<T>
}

export type AllIngressTypes = Ingress<IngressClass.lg> &
  Ingress<IngressClass.c37> &
  Ingress<IngressClass.gep> &
  Ingress<IngressClass.sttp>
