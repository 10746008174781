import React from 'react'

import { Grid, InputAdornment, Tooltip, Typography } from '@mui/material'

import { InfoOutlined as InfoOutlinedIcon } from '@mui/icons-material'
import Expando from 'components/Expando'
import { Field } from 'formik'
import { TextField } from 'formik-mui'

const defaultParams = {
  target: '',
  query: ''
}

interface SttpIngressParamsProps {
  formDisabled: boolean
}
/**
 * This page is the extra parameters specific to sttp ingresses.
 * They include:
 *  - target: Target IP address
 *  - query
 */
const SttpIngressParams = ({ formDisabled }: SttpIngressParamsProps) => {
  return (
    <Grid container columnSpacing={2}>
      <Grid item xs={12}>
        <Field
          required
          component={TextField}
          label="Target"
          name="parameters.target"
          role="textbox"
          margin="dense"
          disabled={formDisabled}
          type="text"
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title="The target IP address" placement="left">
                  <InfoOutlinedIcon />
                </Tooltip>
              </InputAdornment>
            )
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          required
          component={TextField}
          label="Query"
          name="parameters.query"
          role="textbox"
          margin="dense"
          disabled={formDisabled}
          type="text"
          fullWidth
        />
        <Expando
          title="About STTP queries"
          content={
            <>
              <Typography variant="body2" gutterBottom>
                A query is filter expression that defines the desired measurements for a
                subscription.
              </Typography>
              <Typography variant="body2">Examples include:</Typography>
              <ul>
                <li>
                  <Typography variant="body2">
                    Directly specified signal IDs (UUID values in string format):{' '}
                    <Typography variant="monospace">
                      38A47B0-F10B-4143-9A0A-0DBC4FFEF1E8; E4BBFE6A-35BD-4E5B-92C9-11FF913E7877
                    </Typography>
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2">
                    Directly specified tag names:{' '}
                    <Typography variant="monospace">
                      DOM_GPLAINS-BUS1:VH; TVA_SHELBY-BUS1:VH
                    </Typography>
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2">
                    Directly specified identifiers in "measurement key" format:{' '}
                    <Typography variant="monospace">PPA:15; STAT:20</Typography>
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2">
                    A filter expression against a selection view:{' '}
                    <Typography variant="monospace">
                      FILTER ActiveMeasurements WHERE Company='GPA' AND SignalType='FREQ'
                    </Typography>
                  </Typography>
                </li>
              </ul>
            </>
          }
        />
      </Grid>
    </Grid>
  )
}

export default SttpIngressParams
export { defaultParams }
